<template>
  <onehelp-menu-simple
    v-bind="$props"
    :back-route="backRoute"
    :container-style="{height: '265px'}"
    v-on="$listeners"
  >
    <div class="sContent">
      <div class="sContentTitle">
        <h2>{{ item.title }}</h2>
      </div>
      <p class="sItem">
        {{ item.text }}
      </p>
    </div>
  </onehelp-menu-simple>
</template>

<script>
import OnehelpMenuSimple from "@/components/Onehelp/Menu/Simple";

export default {
  name: "OnehelpMenuSubmissionSuccess",
  components: {OnehelpMenuSimple},
  props: {
    helpMenu: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      backRoute: {
        text: 'Help Home',
        route: 'home'
      },
    }
  },
}
</script>

<style scoped lang="scss">

.sContent{
  height: 153px;
  padding: 12px 30px 22px;

  .sContentTitle{
    padding-bottom: 8px;
    padding-top: 24px;
    color: #343434;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
  }

  .sItem{
    padding-top: 8px;
    color: #5B5B5B;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 21px;
  }
}
</style>