<template>
  <loader-fullpage v-if="loadingUser" />
  <v-app
    v-else
    class="app"
  > 
    <page-header
      v-if="!isAuthSection"
      :breadcrumb="breadcrumb"
      @navOpen="navOpen = $event"
    />
    <loader-fullpage v-if="restrictedPage === null" />
    <v-card
      v-else-if="restrictedPage"
      max-width="400"
      class="ma-auto my-10"
    >
      <v-card-title>
        Restricted Area
      </v-card-title>
      <v-card-text>
        You are not allowed to enter this area at present. Please contact your company administrator to be granted additional access.
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        Code: {{ restrictedPage }}
      </v-card-actions>
    </v-card>
    <v-main v-else>
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <router-view
          :key="$route.fullPath"
          @breadcrumb="breadcrumb = $event"
        />
      </transition>
    </v-main>
    <notifications />
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "@/router";

export default {
  data() {
    return {
      breadcrumb: null,
      restrictedPage: null
    };
  },
  computed: {
    ...mapGetters("di", ["getAllowed", "getUser"]),
    isAuthSection() {
      return this.$route.path.substring(0, 6) === '/auth/' && this.$route.name !== 'terms';
    },
    loadingUser() {
      return !this.isAuthSection && (!this.getAllowed || !this.getUser)
    },
  },
  watch: {
    $route() {
      this.breadcrumb = null;
      if (this.isAuthSection) {
        this.restrictedPage = false
      } else {
        this.restrictedPage = true;
        this.checkRestricted();
        if (!this.getUser) {
          this.lookupUser();
        } else if (!this.getUser.hasAcceptedTerms && this.$router.currentRoute.name !== 'terms') {
          this.$router.push({name: 'terms'});
        }
        if (!this.getAllowed) {
          this.lookupAllowed();
        }
      }
    },
    loadingUser: "checkRestricted"
  },
  methods: {
    ...mapActions("di", ["lookupUser", "lookupAllowed", "lookUpSettings"]),
    checkRestricted() {
      if (this.getAllowed) {
        if (!this.$route?.meta?.allowed) {
          this.restrictedPage = false;
        } else {
          let access = this.$route?.meta?.allowed;
          if (!Array.isArray(access)) {
            access = [access];
          }
          for (const a of access) {
            if (!this.fAllowed(a)) {
              this.restrictedPage = a;
              return;
            }
          }
          this.restrictedPage = false;
        }
      }
    }
  }
};
</script>

<style type="text/css" lang="scss">
@import "scss/fundamental-variables.scss";
body,
html {
  margin: 0;
  padding: 0;
  font-family: $fontFamily;
  background-color: $colorLightest;
}
.app {
  min-height: 100vh;
  background-color: $colorLightest !important;
}
</style>

<style type="text/css" lang="sass">
@import "scss/fundamental-variables.scss"

/** TRANSITIONS **/

.slide-fade-enter-active, .slide-fade-leave-active
  transition: 0.5s
.slide-fade-enter
  opacity: 0
  transform: translateX(-10px)
.slide-fade-leave-to
  opacity: 0
  transform: translateX(10px)

.slide-down-enter-active,
.slide-down-leave-active
  transition: 0.5s
.slide-down-enter
  opacity: 0
  transform: translateY(-10px)
.slide-down-leave-to
  opacity: 0
  transform: translateY(-10px)

.slide-left-enter-active,
.slide-left-leave-active
  transition: 0.1s ease-in
.slide-left-enter,
.slide-left-leave-to
  z-index: 999
  opacity: 0
  transform: translateX(-10px)

/** END TRANSITIONS **/


.ui-alert__body
  min-height: auto
.ui-textbox.is-active:not(.is-disabled)
  .ui-textbox__label-text
    color: $colorImpactDark
.ui-textbox.is-active:not(.is-disabled)
  .ui-textbox__input, .ui-textbox__textarea
    border-bottom: solid 1px $colorImpactDark
.ui-select.is-active:not(.is-disabled)
  .ui-select__label-text
    color: $colorImpactDark
.ui-select.is-active:not(.is-disabled)
  .ui-select__icon-wrapper .ui-icon
    color: $colorImpactDark
.ui-select.is-active:not(.is-disabled)
  .ui-select__display
    border-bottom: solid 1px $colorImpactDark
.ui-button--type-primary.ui-button--color-red
  background-color: $colorImpactDark
.ui-button--type-primary.ui-button--color-red:hover
  background-color: $colorImpact !important
.ui-button--type-primary.ui-button--color-accent
  background-color: $colorDark
.ui-button--type-primary.ui-button--color-accent:hover
  background-color: $colorMediumDark !important
.ui-button--type-primary.ui-button--color-default
  background-color: $colorMedium
  color: white
.ui-button--type-primary.ui-button--color-default:hover
  background-color: $colorMediumLight !important

.el-table
  tr
    th
      background-color: $colorLight
      color: white
      &.is-sortable
        span.caret-wrapper
          padding-left: 5px
          background: url("assets/icons/asc-sort.svg") no-repeat center center
          i
            display: none
        &.ascending
          span.caret-wrapper
            background: url("assets/icons/asc-sort.svg") no-repeat center center
            filter: brightness(0.5) sepia(1) hue-rotate(190deg) saturate(10)
        &.descending
          span.caret-wrapper
            background: url("assets/icons/dsc-sort.svg") no-repeat center center
            filter: brightness(0.5) sepia(1) hue-rotate(190deg) saturate(10)
    td
      color: $colorMediumLight
      .hoverOnly
        display: none
    &:hover
      td
        background-color: $colorWhite
        color: $colorDark
        .hoverOnly
          display: block
.elf-table-bar
  display: grid
  grid-template-columns: auto auto
  margin-top: 10px
  input
    font-size: 16px
    margin: 0
    padding: 18px 18px 18px 53px
    border-radius: 4px
    border: 0
    width: 377px
    background: #FFFFFF url(/images/zoom.svg) no-repeat 25px 20px
    background-size: 16px 16px
    color: #8595AD
    outline: none
  .el-pagination
    display: flex
    align-items: center
    justify-content: flex-end
    button
      background-color: white !important
      &:not([disabled]):hover
        color: $colorBlue !important
    li
      background-color: white !important
      &.active, &:hover
        color: $colorBlue !important

.el-card
  .el-card__header
    padding: 54px 81px 32px 81px
    font-size: 1.1em
    border-bottom: none
  .el-card__body
    padding: 0 81px 54px 81px

.el-button--primary
  background-color: $colorBlue
  border-color: $colorBlue

.el-button--default
  border-color: $colorBlue
  color: $colorBlue
  &:hover
    border-color: $colorBlue
    color: white
    background-color: $colorBlue

// Date Picker larger side menu
.el-picker-panel__sidebar
  width: 150px !important
.el-picker-panel__body
  margin-left: 150px !important
</style>
