<template>
  <div class="sRow">
    <div class="slideAdd">
      <h2>Select New Slide</h2>
      <v-text-field
        v-model="slideFilter"
        class="slideFilter"
        label="Filter Slides"
      />
      <div class="slideList">
        <report-display-builder-new-category
          v-for="(category, categoryIndex) in slideTypeList"
          :key="categoryIndex"
          :mode="getMode"
          :category="category"
          :selected="selected"
          @selectSlide="selectSlide($event)"
        />
      </div>
    </div>
    <div>
      <div class="slideAddOption">
        <h3>Slide Options</h3>
        <p v-if="!selected">
          First select the new slide type you would like from the list on the
          left and then the options available will be shown here.
        </p>
        <div v-else>
          <report-display-builder-option
            :slide="{ component: selected, title: slideName }"
            @options="setOptions($event)"
          />
          <v-btn
            color="primary"
            elevation="8"
            large
            :disabled="options === null"
            title="Select options above to allow slide to be added"
            @click="addSlide()"  
          >
            Add Slide
          </v-btn>
        </div>
        <img
          :src="getThumbnail()"
          class="thumbnail"
        >
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ReportDisplayBuilderNewCategory from "./New/Category";
import { mapGetters } from "vuex";
import ReportDisplayBuilderOption from "./Option";
import { slideList } from "../../../../data/report/slides-v1";

export default {
  components: { ReportDisplayBuilderOption, ReportDisplayBuilderNewCategory },
  data: function() {
    return {
      options: null,
      selected: null,
      slideFilter: "",
      slideName: "",
      slideType: slideList
    };
  },
  computed: {
    ...mapGetters("report", ["getMode"]),
    slideTypeList() {
      let filter = this.slideFilter.trim().toUpperCase();
      let selected = this.selected;
      let list = [];
      _.forEach(this.slideType, function(category) {
        let slides = _.filter(category.slides, function(o) {
          return (
            o.component === selected ||
            o.title.toUpperCase().includes(filter) ||
            (o.description && o.description.toUpperCase().includes(filter))
          );
        });
        if (slides.length > 0) {
          list.push({
            category: category.category,
            mode: category.mode,
            slides: _.sortBy(slides, "title")
          });
        }
      });
      return list;
    }
  },
  methods: {
    addSlide: function() {
      this.$emit("newSlide", {
        component: this.selected,
        title: this.slideName,
        options: this.options
      });
      this.options = null;
      this.selected = null;
    },
    getThumbnail() {
      let images = require.context("@/assets/slides/", false, /\.png$/);
      try {
        return images("./" + this.selected + ".png");
      } catch (e) {
        return images("./missing.png");
      }
    },
    selectSlide: function(slide) {
      this.options = null;
      this.selected =
        this.selected === slide.component ? null : slide.component;
      this.slideName = slide.title;
    },
    setOptions(options) {
      this.slideName = options.title;
      this.options = options.options;
    }
  }
};
</script>

<style scoped lang="sass">
@import "../../../../scss/fundamental-variables.scss"

.sRow
  display: grid
  grid-template-columns: 50% 50%

@media only screen and (max-width: 700px)
  .sRow
    grid-template-columns: 100%
    &.rowEdit
      grid-template-columns: 100%
    .slideAdd, .slideAddOption, .slideList
      min-height: auto
      height: auto !important
    .scroll
      margin-bottom: 20px

div.slideAdd
    padding: 10px 0 0 0
    background-color: $colorLight
    box-shadow: inset -7px 0 5px -7px rgba(0,0,0,0.2)
    height: calc(100vh - 100px)
    h2
        margin: 0 0 10px
        padding-left: 10px
        font-size: 1.3em
    .slideFilter
      padding: 0 10px
      margin: 0
    .slideList
      overflow-x: scroll
      height: calc(100vh - 151px)

div.slideAddOption
    height: calc(100vh - 100px)
    overflow-y: scroll
    padding: 10px
    background-color: $colorMediumLight
    h3
        margin: 0 0 10px
        font-size: 1.5em
    p
        margin-top: 0
    img.thumbnail
      width: 250px
      margin: 50px auto
      display: block
      border: solid 3px white
      box-shadow: 1px 1px 2px 0 rgba(0,0,0,0.4)
      img.thumbnail[src*="data:"]
      display: none
</style>
