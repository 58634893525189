<template>
  <div class="slideEdit">
    <i
      class="fas fa-redo-alt vRefresh"
      @click="$emit('refresh')"
    />
    <h3>Slide Options</h3>
    <br>

    <report-display-builder-option
      :slide="slide"
      :load-options="getSlideOptions()"
      @options="setOptions($event)"
    />

    <div
      class="excludeSlide"
      @click="excludeToggle()"
    >
      <i :class="excludeClass()" /> Include slide in PDF
    </div>

    <div class="editButtons">
      <v-btn
        color="accent"
        elevation="8"
        large
        :disabled="options === null"
        @click="editSlide()"  
      >
        <i class="far fa-edit" />&nbsp;Update Slide
      </v-btn>
      <v-btn
        color="red"
        elevation="8"
        large
        @click="deleteSlide()"  
      >
        <i class="far fa-trash-alt" />&nbsp;Delete Slide
      </v-btn>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ReportDisplayBuilderOption from "./Option";

export default {
  components: { ReportDisplayBuilderOption },
  /* eslint-disable-next-line vue/require-prop-types */
  props: ["slide", "excluded"],
  data: function() {
    return {
      excludeOverride: null,
      options: null,
      slideName: ""
    };
  },
  watch: {
    slide() {
      this.slideName = this.slide.title;
    }
  },
  mounted() {
    this.slideName = this.slide.title;
  },
  methods: {
    deleteSlide: function() {
      this.$emit("deleteSlide", {
        id: this.slide.id
      });
    },
    editSlide: function() {
      this.$emit("editSlide", {
        id: this.slide.id,
        title: this.slideName,
        options: this.options
      });

      if (this.excludeOverride && this.excludeOverride !== this.excluded)
        this.$emit("toggleSlide", this.slide.id);
    },
    excludeClass() {
      let current =
        this.excludeOverride === null ? this.excluded : this.excludeOverride;
      return { far: true, "fa-check-square": !current, "fa-square": current };
    },
    excludeToggle() {
      let current =
        this.excludeOverride === null ? this.exclude : this.excludeOverride;
      this.excludeOverride = !current;
    },
    getSlideOptions() {
      return _.cloneDeep(this.slide.options);
    },
    setOptions(options) {
      this.slideName = options.title;
      this.options = options.options;
    }
  }
};
</script>

<style scoped lang="sass">
@import "../../../../scss/fundamental-variables.scss"

div.slideEdit
  position: relative
  background-color: $colorMediumLight
  height: calc(100vh - 121px)
  overflow-y: auto
  padding: 10px
  i.vRefresh
    position: absolute
    right: 10px
    color: white
  h3
    margin: 0
    color: white
  div.excludeSlide
    margin: 25px 0
    cursor: pointer
  div.scroll
    padding: 10px
    overflow-y: scroll
    h3
      margin: 0 0 10px
      font-size: 1.5em
    div.editButtons
      text-align: center
      button
        margin-right: 10px
</style>
