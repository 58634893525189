import Vue from "vue";
import Vuex from "vuex";

import aggregator from "./store/aggregator";
import blog from "./store/blog";
import di from "./store/di";
import doubleclick from "./store/matching";
import oa from "./store/oa";
import report from "./store/report";
import subroute from "./store/subroute";
import status from "./store/status";

Vue.use(Vuex);

const state = {
  socket: {
    isConnected: false,
    message: "",
    reconnectError: false,
    errors: [],
    reconnectCount: 0
  }
};

const getters = {};

const actions = {
  subscribe: function(context, subscription) {
    const { queue, data } = subscription;
    Vue.prototype.$socket.sendObj({
      type: "subscribe",
      queue,
      data
    });
  }
};

const mutations = {
  SOCKET_ONOPEN(state, event) {
    Vue.prototype.$socket = event.currentTarget;
    authenticate();
    state.socket.isConnected = true;
  },
  SOCKET_ONCLOSE(state) {
    state.socket.isConnected = false;
  },
  SOCKET_ONERROR(state, event) {
    state.socket.errors.push(event);
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE(state, message) {
    const { type } = message;
    if (type === "download") {
      const { file, url, error } = message;
      if (error) {
        Vue.notify({
          type: "error",
          title: "Download Fail",
          text: "There was a problem generating your file"
        });
      } else {
        const cleanName = file.substring(file.lastIndexOf("/") + 1);
        const link = document.createElement("a");
        link.setAttribute(
          "href",
          url +
            "&response-content-disposition=attachment;%20filename=" +
            encodeURI(cleanName) +
            ".pdf"
        );
        link.setAttribute("download", cleanName);
        link.dispatchEvent(new MouseEvent("click"));
      }
    }
    state.socket.message = message;
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT(state, count) {
    authenticate();
    state.socket.reconnectCount = count;
  },
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  }
};

export const store = new Vuex.Store({
  modules: {
    di,
    doubleclick,
    oa,
    report,
    aggregator,
    blog,
    status,
    subroute
  },
  state,
  getters,
  actions,
  mutations
});
