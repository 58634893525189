<template>
  <div>
    <onehelp-menu-homepage
      v-if="getOnehelp.name === 'home'"
      :article-list="formattedArticleList"
      :project-id="projectId"
      :title="title"
      :header-icons="headerIcons"
      :help-menu="helpMenu"
      v-on="$listeners"
      @routeChange="changeRoute"
    />
    <onehelp-menu-list
      v-if="getOnehelp.name === 'list'"
      :project-id="projectId"
      :article-list="formattedArticleList"
      :category="getOnehelp.type === 'category' ? getOnehelp : null"
      :title="title"
      :header-icons="headerIcons"
      :help-menu="helpMenu"
      v-on="$listeners"
      @routeChange="changeRoute"
    />
    <onehelp-menu-detail
      v-if="getOnehelp.name === 'article' && getOnehelp.id"
      :key="getOnehelp.id"
      :article-id="`${getOnehelp.id}`"
      :article-list="formattedArticleList"
      :category="getOnehelp.category"
      :title="title"
      :header-icons="headerIcons"
      :help-menu="helpMenu"
      v-on="$listeners"
      @routeChange="changeRoute"
    />
    <onehelp-menu-form
      v-if="getOnehelp.name === 'form'"
      :type="getOnehelp.type"
      :project-id="projectId"
      :form-products="formProducts"
      :help-menu="helpMenu"
      :title="title"
      :header-icons="headerIcons"
      v-on="$listeners"
      @routeChange="changeRoute"
    />
    <onehelp-menu-submission-success
      v-if="getOnehelp.name === 'submissionSuccess'"
      :item="getOnehelp.successItem"
      :title="title"
      :help-menu="helpMenu"
      v-on="$listeners"
      @routeChange="changeRoute"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import OnehelpMenuHomepage from "@/components/Onehelp/Menu/Homepage";
import OnehelpMenuList from "@/components/Onehelp/Menu/List";
import OnehelpMenuDetail from "@/components/Onehelp/Menu/Detail";
import OnehelpMenuSubmissionSuccess from "@/components/Onehelp/Menu/SubmissionSuccess";
import OnehelpMenuForm from "@/components/Onehelp/Menu/Form";
import Api from "@/connect/api";

export default {
  name: "OnehelpMenuRoute",
  components: {OnehelpMenuForm, OnehelpMenuSubmissionSuccess, OnehelpMenuDetail, OnehelpMenuList, OnehelpMenuHomepage},
  props: {
    helpMenu: {
      type: Boolean,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      articleList: null,
      formProducts: [
        {
          name: 'Dashboard',
          id: '1',
          paths: ['/dashboard/'],
          areas: ['Create Dashboard', 'Dashboard List', 'Dashboard Main Page']
        },
        {
          name: 'Outcome Analytics',
          id: '2',
          paths: ['/client/aggregator', '/outcome/analytics'],
          areas: ['OA Configuration', 'OA List', 'OA Main Page', 'OA Drilldown']
        },
        {
          name: 'Reporting',
          id: '3',
          paths: ['/reports', '/report'],
          areas: ['Old Report Setup', 'Old Report Builder', 'New Report Setup', 'New Report Builder']
        },
        {
          name: 'Troubleshooter',
          id: '4',
          paths: ['/troubleshooter'],
          areas: ['Troubleshooter Filters', 'Troubleshooter Dials', 'Troubleshooter Error Table']
        },
      ],
      headerIcons: [
        {
          icon: 'fal fa-comment',
          title: 'Give feedback',
          subtitle: 'or suggest new feature',
          actionText: 'Get started',
          action: () => this.changeRoute({name: 'form', type: 'feedback'})
        },
        {
          icon: 'fal fa-envelope',
          title: 'Ask for help',
          subtitle: 'Something not working?',
          actionText: 'Get in touch',
          action: () => this.changeRoute({name: 'form', type: 'help'})
        },
      ],
      open: true
    }
  },
  computed: {
    ...mapGetters("subroute", ["getOnehelp"]),
    formattedArticleList() {
      if(this.articleList && this.articleList.length) {
        return this.articleList.filter(a => a.status === "live")
      }
      return []
    }
  },
  created(){
    Api.get(
      `onehelp/article?project_code=${this.projectId}`,
      (articleList) => {
        this.articleList = articleList;
      },
      () => {
        this.articleList = []
      }
    );
  },
  methods: {
    ...mapActions("subroute", ["routeOnehelp"]),
    changeRoute(path) {
      this.routeOnehelp(path)
    }
  }
}
</script>
