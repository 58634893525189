import _ from "lodash";
import Api from "../../connect/api";

let slideCover = {
  component: "cover",
  id: "AAA-111",
  options: null,
  title: "Cover Page",
  template: false,
  show: "yes"
};

let slideThank = {
  component: "thankyou",
  id: "ZZZ-999",
  options: null,
  title: "Thank You",
  template: true,
  show: "yes"
};

let download = function(name, html, chartList, document) {
  let totalPages = (html().match(/PAGENUMBER_REPLACE_ON_PDF/g) || []).length
  return new Promise((resolve, reject) => {
    let go = function() {
      let content = html().replace(
        /PAGENUMBER_REPLACE_ON_PDF/g,
        (() => {
          let number = 1;
          return () => {
            return `${number++} of ${totalPages}`;
          };
        })()
      );
      content += highChartsRedraw(content, chartList);
      content += scopedCss(document);
      let cleanName = name.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "");
      cleanName = cleanName.replace(/\s{2,}/g, " ");
      Api.post(
        "report/download",
        {
          content,
          name: cleanName
        },
        fileName => {
          resolve(fileName);
        },
        () => {
          reject();
        },
        () => {
          reject();
        }
      );
    };
    let check = function(count) {
      if (window.ajaxComplete >= window.ajaxAll) {
        if (count > 3) {
          go();
        } else {
          setTimeout(function() {
            check(count + 1);
          }, 100);
        }
      } else {
        setTimeout(function() {
          check(count);
        }, 100);
      }
    };
    setTimeout(function() {
      check(1);
    }, 500);
  });
};

let generate = function(name, html, chartList, document) {
  return new Promise((resolve, reject) => {
    let go = function() {
      let totalPages = (html().match(/PAGENUMBER_REPLACE_ON_PDF/g) || []).length
      let content = html().replace(
        /PAGENUMBER_REPLACE_ON_PDF/g,
        (() => {
          let number = 1;
          return () => {
            return `${number++} of ${totalPages}`;
          };
        })()
      );
      content += highChartsRedraw(content, chartList);
      content += scopedCss(document);
      Api.post(
        "report/generate",
        {
          content,
          name
        },
        result => {
          let link = document.createElement("a");
          let cleanName = name.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "");
          cleanName = cleanName.replace(/\s{2,}/g, " ");
          link.setAttribute(
            "href",
            result.url +
              "&response-content-disposition=attachment;%20filename=" +
              encodeURI(cleanName) +
              ".pdf"
          );
          link.setAttribute("download", cleanName);
          link.dispatchEvent(new MouseEvent("click"));
          resolve();
        },
        () => {
          reject();
        },
        () => {
          reject();
        }
      );
    };
    let check = function(count) {
      if (window.ajaxComplete >= window.ajaxAll) {
        if (count > 3) {
          go();
        } else {
          setTimeout(function() {
            check(count + 1);
          }, 100);
        }
      } else {
        setTimeout(function() {
          check(count);
        }, 100);
      }
    };
    setTimeout(function() {
      check(1);
    }, 500);
  });
};

let generateSlideId = function() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

let highChartsRedraw = function(html, chartList) {
  let convertToText = function(obj) {
    let string = [];

    if (obj === null) {
      string.push("null");
    } else if (obj === true) {
      string.push("true");
    } else if (obj === false) {
      string.push("false");
    } else if (typeof obj === "object" && obj.join === undefined) {
      string.push("{");
      for (let prop in obj) {
        string.push(prop, ": ", convertToText(obj[prop]), ",");
      }
      string.push("}");
    } else if (typeof obj === "object" && !(obj.join === undefined)) {
      string.push("[");
      for (let prop in obj) {
        string.push(convertToText(obj[prop]), ",");
      }
      string.push("]");
    } else if (typeof obj === "function") {
      string.push(obj.toString());
    } else {
      string.push(JSON.stringify(obj));
    }

    return string.join("");
  };

  let chartHtml = "";
  let chartRedrawHtml = "";
  let i = 1;
  _.forEach(chartList, function(chart) {
    if (html.includes('id="' + chart.id + '"')) {
      chartHtml +=
        "let chart" +
        i +
        ' = Highcharts.chart("' +
        chart.id +
        '", ' +
        convertToText(chart.data) +
        ");\n";
      chartRedrawHtml += "chart" + i + ".redraw();\n";
      i++;
    }
  });
  return (
    "\n<" +
    'script type="application/javascript">window.onload = function () {' +
    chartHtml +
    "\n" +
    chartRedrawHtml +
    "};</" +
    "script>"
  );
};

let scopedCss = function(document) {
  let style = "";
  _.forEach(document.getElementsByTagName("style"), function(styleTag) {
    if (
      styleTag.innerHTML.indexOf(
        "SLIDE SCOPED IMPORTANT DO NOT REMOVE THIS"
      ) !== -1
    )
      style += styleTag.innerHTML;
  });
  let css = "";
  _.forEach(document.getElementsByTagName("link"), function(linkTag) {
    if (/.*\/css\/slide\.[a-z0-9]*\.css/gm.exec(linkTag.href) !== null)
      css += '<link href="' + linkTag.href + '" rel=stylesheet>';
  });

  return css + '<style type="text/css">' + style + "</style>";
};

export default { download, generate, generateSlideId, slideCover, slideThank };
