import { CoreTroubleshooterAggregatorFilter } from "../core/troubleshooter/aggregator/filter";

const resetState = () => {
  return {
    filters: state.filters,
    group: null,
    loading: false,
    issues: {},
    subgroup: null,
    error: null,
    linkedBookingList: [],
    clientDescription: null
  };
};

// initial state
const state = {
  filters: null,
  group: null,
  loading: false,
  issues: {},
  subgroup: null,
  error: null,
  // temporarily store bookings that have just been linked in order to filter them out from the unlinked bookings issues list
  linkedBookingList: [],
  clientDescription: null
};

const getters = {
  getFilters() {
    return state.filters;
  },
  getLoading() {
    return state.loading;
  },
  getGroup() {
    return state.group;
  },
  getSubgroup() {
    return state.subgroup;
  },
  getError() {
    return state.error;
  },
  getIssues() {
    return state.issues;
  },
  getClientDescription() {
    return state.clientDescription;
  },
  getLinkedBookings() {
    return state.linkedBookingList;
  }
};

const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  setFilters({ commit }, {filters, access}) {
    commit("setFilters", filters);
    if (filters) {
      commit("setLoading", true);

      const core = new CoreTroubleshooterAggregatorFilter(filters, access);
      core.fetchData().then(() => {
        const retrievedData = core.rules();
        commit("setIssues", {
          groups: retrievedData.groups(),
          groupAa: retrievedData.groupAa(),
          groupAmx: retrievedData.groupAmx(),
          groupAmxBudget: retrievedData.groupAmxBudget(),
          groupAmxPacing: retrievedData.groupAmxPacing(),
          groupAmxTargeting: retrievedData.groupAmxTargeting(),
          groupCmIntell: retrievedData.groupCmIntell(),
          groupCmReporting: retrievedData.groupCmReporting(),
          groupCmTraffic: retrievedData.groupCmTraffic(),
          groupCmLandingPage: retrievedData.groupCmLandingPage(),
          groupCreative: retrievedData.groupCreative(),
          groupFmxBudgetPacing: retrievedData.groupFmxBudgetPacing(),
          groupFmxOptimisation: retrievedData.groupFmxOptimisation(),
          groupFmxTargeting: retrievedData.groupFmxTargeting(),
          groupFmxBudgetPacingUpcoming: retrievedData.groupFmxBudgetPacing(true),
          groupFmxTargetingUpcoming: retrievedData.groupFmxTargeting(true)
        });
        commit("setLoading", false);
      });
    }
  },
  setGroup({ commit }, group) {
    commit("setGroup", group);
  },
  setSubgroup({ commit }, subgroup) {
    commit("setSubgroup", subgroup);
  },
  setError({ commit }, error) {
    commit("setError", error);
  },
  setClientDescription({ commit }, clientDescription) {
    commit("setClientDescription", clientDescription);
  },
  setLinkedBookings({commit}, booking)
  {
    commit("setLinkedBookings", booking);
  }
};

const mutations = {
  resetState(state) {
    Object.assign(state, resetState());
  },
  setGroup(state, group) {
    state.group = group;
  },
  setError(state, error) {
    state.error = error;
  },
  setSubgroup(state, subgroup) {
    state.subgroup = subgroup;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLinkedBookings(state, booking) {
    state.linkedBookingList.push(booking)
  },
  setIssues(state, issues) {
    state.issues = issues;
  },
  setClientDescription(state, clientDescription) {
    state.clientDescription = clientDescription;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
