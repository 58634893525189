<template>
  <div class="sHeader">
    <h1 class="ml-5">
      {{ title }}
    </h1>
    <div class="mr-4">
      <div
        class="d-inline-block cursorPointer"
        @click="$emit('switchOpen')"
      >
        <v-icon
          left
          small
        >
          far fa-window-minimize
        </v-icon>
      </div>
      <div
        class="d-inline-block cursorPointer"
        @click="$emit('closeHelpMenu')"
      >
        <v-icon left>
          mdi-close
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnehelpMenuHeader",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

.sHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: #F9F9F9;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  h1{
    font-size: 20px;
    line-height: 23px;
    color: #3C3B3B;
    text-align: center;
  }
}
</style>