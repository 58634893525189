<template>
  <div v-if="allowed()">
    <h3
      v-if="show"
      @click="show = false"
    >
      <i class="far fa-minus-square" /> {{ category.category }}
    </h3>
    <h3
      v-else
      @click="show = true"
    >
      <i class="far fa-plus-square" /> {{ category.category }}
    </h3>
    <ul v-show="show">
      <li
        v-for="slide in category.slides"
        :key="slide.id"
        :class="{ selected: slide.component === selected }"
        @click="choosen = slide"
      >
        <h4>
          <i
            :class="{
              far: true,
              'fa-check-square': slide.component === selected,
              'fa-square': slide.component !== selected
            }"
          />
          {{ slide.title }}
        </h4>
        <p v-if="slide.description">
          {{ slide.description }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ReportDisplayBuilderNewCategory",
  /* eslint-disable vue/require-default-prop */
  props: {
    category: Object,
    mode: String,
    selected: String
  },
  /* eslint-enable vue/require-default-prop */
  data: function() {
    return {
      choosen: false,
      show: false
    };
  },
  watch: {
    choosen() {
      this.$emit("selectSlide", this.choosen);
    }
  },
  methods: {
    allowed() {
      if (
        (this.mode !== "presentation" &&
          this.category.mode === "presentation") ||
        this.category.mode === "v2"
      )
        return false;
      return true;
    }
  }
};
</script>

<style scoped lang="sass">
@import "../../../../../scss/fundamental-variables.scss"

h3
  margin: 20px 0 5px 10px
  color: $colorMediumDark
  cursor: pointer
ul
  margin: 0
  padding: 0
  list-style-type: none
  border-top: solid 1px $colorMediumLight
  li
    padding: 10px
    margin: 0
    border-bottom: solid 1px $colorMediumLight
    cursor: pointer
    h4
      font-size: 1.2em
      margin: 0
    p
      margin: 10px 0 0 28px
  li:hover
    background-color: $colorMediumLight
  li.selected
    background-color: $colorMediumLight
</style>
