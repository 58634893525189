import diStore from "@/store/di";
import moment from "moment";
const dateFormat = diStore.getters.dateFormat()
const renderValues = (value) => {
  value = (value).toFixed(2)
  if (value < 75 || value > 125)
    return { text: value + '%', group: 1 };
  if ((value > 75 && value < 90) || (value > 110 && value <= 125))
    return { text: value + '%', group: 2 };
  if (value >= 90 && value <= 110)
    return { text: value + '%', group: 3 };
  return { text: "N/A", };
}

const aa = aaList => {
  if (!aaList.length) return [];

  const mappedList = aaList.map(aa => {
    let {
      uuid,
      name,
      date_start,
      date_end,
      client_id,
      clientName,
      lastLog,
      status
    } = aa;

    const oneDay = 24 * 60 * 60 * 1000;

    const lastLogDays = !lastLog ? 99 : Math.round(
      Math.abs((new Date(lastLog.timestamp) - new Date()) / oneDay));

    const aaDaysToRun = Math.round(Math.abs((new Date() - new Date(date_end)) / oneDay));

    const rules = {
      meta: {
        aaUuid: uuid,
        name,
        date_start,
        date_end,
        client_id,
        clientName,
        aaDaysToRun,
        lastLogDays,
        lastLogBy: lastLog ? lastLog.userName : `-`,
        status,
      },
      lastLog: aaDaysToRun > 3 && lastLogDays >= 5 ? 1 : aaDaysToRun > 3 && lastLogDays >= 3
        ? 2
        : 3,
    };

    return rules;
  });
  return mappedList.filter(aa => aa.meta.status != 0);
};

const amx = amxList => {
  if (!amxList?.length) return [];

  const mappedList = amxList.map(amx => {
    let {
      creativeContent,
      amxAlternativeId,
      dc_placement_advertiserId,
      ax_creative_accountId,
      ax_advertiser_alternativeId,
      ax_creative_advertiserId,
      ax_creative_createDate,
      ax_creative_creativeId,
      ax_creative_creativeName,
      ax_creative_updateDate,
      creativeId
    } = amx;

    const regCondition = new RegExp("data-dcm-param-amx=\'{{LINE\_ITEM\_ID}}-{{AUCTION\_ID}}|amx={{LINE_ITEM_ID}}-{{AUCTION_ID}}", 'g');

    const rules = {
      meta: {
        creativeContent,
        amxAlternativeId,
        dc_placement_advertiserId,
        ax_creative_accountId,
        ax_advertiser_alternativeId,
        ax_creative_advertiserId,
        ax_creative_creativeId,
        ax_creative_creativeName,
        ax_creative_createDate,
        ax_creative_updateDate,
        creativeId
      },

      macros: regCondition.test(creativeContent) ? 3 : 1,
      placement: !amxAlternativeId || !dc_placement_advertiserId || ax_advertiser_alternativeId !== dc_placement_advertiserId.toString() ? 1 : 3,
    };

    return rules;
  });

  return mappedList;
};

const amxCampaignBudget = amxList => {
  if (!amxList?.length) return [];

  const mappedList =  amxList.map(amx => {
    let { campaignBudget } = amx;

    const rules = {
      meta: { ...amx, continents: amx?.continents ? JSON.parse(amx.continents)?.join(',') : 'Unknown' },

      amxCampaignBudget: campaignBudget >= 250000 ? 1 : 3
    };

    return rules;
  });
  return mappedList.filter(item => item.meta.campaignBudget >= 25000);
};

const amxDailyBudget = amxList => {
  if (!amxList?.length) return [];

  const mappedList =  amxList.map(amx => {
    let { dailyBudget } = amx;

    const rules = {
      meta: { ...amx, continents: amx?.continents ? JSON.parse(amx.continents)?.join(',') : 'Unknown' },

      amxDailyBudget:  dailyBudget>=1000 ? 1 : 3
    };

    return rules;
  });
  return mappedList.filter(item => item.meta.dailyBudget >= 1000);
};

const amxCPacing = amxPacingData =>{
  if (!amxPacingData?.length) return [];

  const mappedList = amxPacingData.map(aa => {
    let {
      accountId,
      advertiserId,
      advertiserName,
      campaignId,
      campaignBudget,
      campaignSpend,
      campaignOsi,
      reportDate,
      campaignStartDate,
      campaignEndDate,
      campaignName,
      alternativeId,
    } = aa;

    const pacingHealth = campaignOsi * 100
    const pacingHealthClasses = renderValues(pacingHealth)

    const rules = {
      meta: {
        accountId,
        advertiserId,
        advertiserName,
        campaignId,
        campaignBudget,
        campaignSpend,
        pacingHealth,
        reportDate,
        campaignStartDate,
        campaignEndDate,
        campaignName,
        alternativeId,
        pacingHealthClasses,
      },
      campaigns: pacingHealthClasses.group,
    };

    let start_date = new Date(campaignStartDate);
    let end_date = new Date(campaignEndDate);
    const totalDays = Math.round((end_date - start_date) / (24 * 60 * 60 * 1000)) + 1;
    const daysLeft = Math.ceil((end_date - new Date()) / (24 * 60 * 60 * 1000)) + 1;

    const budgetAtRisk = ((100 - campaignOsi * 100) / 100) * campaignBudget
    rules.meta.budgetAtRisk = budgetAtRisk;
    rules.meta.daysLeft = daysLeft;
    rules.meta.totalDays = totalDays

    return rules;
  });
  return mappedList.filter(item => item.meta.daysLeft >= 0);
}

const amxCSBBudget = amxBudgetData => {
  if (!amxBudgetData?.length) return [];

  const mappedList = amxBudgetData.map(aa => {
    let {
      accountId,
      accountName,
      advertiserId,
      advertiserName,
      campaignId,
      campaignName,
      campaignBudget,
      budgetType,
      startDate,
      endDate,
      alternativeId,
    } = aa;

    const rules = {
      meta: {
        accountId,
        accountName,
        advertiserId,
        advertiserName,
        campaignId,
        campaignName,
        campaignBudget,
        budgetType,
        campaignStartDate: startDate,
        campaignEndDate: endDate,
        alternativeId,
      },
      // Budget should be only "Spend with Vendor Fees" or no budget at all
      campaignSpendBudget: ( budgetType === 2 || [null, undefined, "undefined"].includes(budgetType)) ? 3 : 1,
    };

    let start_date = new Date(startDate);
    let end_date = new Date(endDate);
    const totalDays = Math.round((end_date - start_date) / (24 * 60 * 60 * 1000)) + 1;
    const daysLeft = Math.ceil((end_date - new Date()) / (24 * 60 * 60 * 1000)) + 1;

    rules.meta.daysLeft = daysLeft;
    rules.meta.totalDays = totalDays

    return rules;
  });
  return mappedList.filter(item => item.meta.daysLeft >= 0);
}

const amxLiPacing = amxPacingData => {
  if (!amxPacingData?.length) return [];

  const mappedList = amxPacingData.map(aa => {
    let {
      accountId,
      advertiserId,
      advertiserName,
      campaignId,
      lineItemId,
      lineItemName,
      lineItemBudget,
      lineItemSpend,
      reportDate,
      lineItemStartDate,
      lineItemEndDate,
      lineItemOsi,
      campaignName,
      alternativeId,
      amxLineItemId,
    } = aa;

    const lineItemHealth = lineItemOsi * 100
    const lineItemHealthClasses = renderValues(lineItemHealth)

    const rules = {
      meta: {
        accountId,
        advertiserId,
        advertiserName,
        campaignId,
        lineItemId,
        lineItemName,
        lineItemBudget,
        lineItemSpend,
        reportDate,
        lineItemStartDate,
        lineItemEndDate,
        lineItemHealth,
        campaignName,
        alternativeId,
        amxLineItemId,
        lineItemHealthClasses,
      },
      lineitems: lineItemHealthClasses.group,
    }


    let start_date = new Date(lineItemStartDate);
    let end_date = new Date(lineItemEndDate);
    const totalDays = Math.round((end_date - start_date) / (24 * 60 * 60 * 1000)) + 1;
    const daysLeft = Math.ceil((end_date - new Date()) / (24 * 60 * 60 * 1000)) + 1

    const budgetAtRisk = ((100 - lineItemOsi * 100) / 100) * lineItemBudget
    rules.meta.budgetAtRisk = budgetAtRisk;
    rules.meta.daysLeft = daysLeft;
    rules.meta.totalDays = totalDays

    return rules;
  });
  return mappedList.filter(item => item.meta.daysLeft >= 0);
}

const amxLiBgSPacing = amxPacingData => {
  if (!amxPacingData?.length) return [];

  const mappedList = amxPacingData.map(aa => {
    let {
      accountId,
      accountName,
      advertiserId,
      advertiserName,
      campaignId,
      campaignName,
      amxLineItemId,
      lineItemName,
      alternativeId,
      biddingPacing,
      budgetType,
      endDate,
      startDate,
      lineItemBudget,
      currency
    } = aa;

    const rules = {
      currency,
      meta: {
        accountId,
        accountName,
        advertiserId,
        alternativeId,
        campaignId,
        campaignName,
        advertiserName,
        biddingPacing,
        budgetType,
        endDate,
        lineItemBudget,
        amxLineItemId,
        lineItemName,
        startDate,
        currency
      },
      data : {
        budgetTypeString: "Spend" 
      },
      liBudgetSetting: 1,
    };

    if (biddingPacing === "daily" || biddingPacing === "lifetime") {
      rules.liBudgetSetting = 3;
    } else if (biddingPacing === "flight") {
      rules.liBudgetSetting = 2;
    }

    if (budgetType === 1) {
      rules.data.budgetTypeString = "Impressions"
    } else if (budgetType === 2) {
      rules.data.budgetTypeString = "Spend with Vendor Fees"
    }

    let start_date = new Date(startDate);
    let end_date = new Date(endDate);
    const totalDays = Math.round((end_date - start_date) / (24 * 60 * 60 * 1000)) + 1;
    const daysLeft = Math.ceil((end_date - new Date()) / (24 * 60 * 60 * 1000)) + 1

    rules.data.daysLeft = daysLeft;
    rules.data.totalDays = totalDays

    return rules;
  });
  return mappedList.filter(item => item.data.daysLeft >= 0);
}

const amxCTFBudget = amxBudgetData => {
  if (!amxBudgetData?.length) return [];

  // Accepted vendorFee ids
  const GreenVendorFee = [12, 14, 15, 16, 17, 18, 21, 22];

  const mappedList = amxBudgetData.map(aa => {
    let {
      accountId,
      accountName,
      advertiserId,
      advertiserName,
      campaignId,
      campaignName,
      alternativeId,
      vendors,
      startDate,
      endDate
    } = aa;

    const rules = {
      meta: {
        accountId,
        accountName,
        advertiserId,
        advertiserName,
        campaignId,
        campaignName,
        alternativeId,
        campaignStartDate: startDate,
        campaignEndDate: endDate
      },
      campaignTechFee: 1
    };

    for (let vendor of vendors) {
      if (GreenVendorFee.includes(vendor?.vendorId)) {
        rules.campaignTechFee = 3;
        break;
      }
    }

    let start_date = new Date(startDate);
    let end_date = new Date(endDate);
    const totalDays = Math.round((end_date - start_date) / (24 * 60 * 60 * 1000)) + 1;
    const daysLeft = Math.ceil((end_date - new Date()) / (24 * 60 * 60 * 1000)) + 1;

    rules.meta.daysLeft = daysLeft;
    rules.meta.totalDays = totalDays

    return rules;
  });
  return mappedList.filter(item => item.meta.daysLeft >= 0);
}

const amxLiTargeting = amxTargetingData => {
  if (!amxTargetingData?.length) return [];

  const mappedList = amxTargetingData.map(aa => {
    let {
      accountId,
      accountName,
      advertiserId,
      advertiserName,
      campaignId,
      campaignName,
      amxLineItemId,
      lineItemName,
      modules,
      startDate,
      endDate
    } = aa;

    let groupFlag = 1;
    const { all, any } = modules?.app_site || {};
    const allConditions = all ? (all.domain_list || all.deal_id || all.deal_id_list) : false;
    const anyConditions = any ? (any.domain_list || any.deal_id || any.deal_id_list) : false;

    if (allConditions || anyConditions) {
      groupFlag = 3;
    }

    const rules = {
      meta: {
        accountId,
        accountName,
        advertiserId,
        advertiserName,
        campaignId,
        campaignName,
        amxLineItemId,
        lineItemName,
        startDate,
        endDate
      },
      lineItemTargeting: groupFlag,
    };

    let start_date = new Date(startDate);
    let end_date = new Date(endDate);
    const totalDays = Math.round((end_date - start_date) / (24 * 60 * 60 * 1000)) + 1;
    const daysLeft = Math.ceil((end_date - new Date()) / (24 * 60 * 60 * 1000)) + 1;

    rules.meta.daysLeft = daysLeft;
    rules.meta.totalDays = totalDays

    return rules;
  });
  return mappedList.filter(item => item.meta.daysLeft >= 0);
}

const booking = booking => {
  if (!booking.list) return [];
  const currentDate = new Date();
  const lastYear = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());

  return booking.list.map(booking => {
    let {
      activity_aggregator_id,
      bookingUniqueNumber,
      budgetCodeName,
      campaignName,
      clientName,
      clientReportingCurrency,
      clientUuid,
      costToClient,
      datesList,
      deliveredImpressions,
      excludeFromTrafficking,
      mediaName,
      impressions,
      costStructure,
      createdBy,
      dateInsertion,
      dateEventEnd,
      opportunity,
      opportunityType,
      placementList,
      planUniqueNumber,
      size,
      totalDays,
    } = booking;

    // Compare to yesterday due to delay in picking up delivery figures
    const compareDate = new Date();
    compareDate.setDate(compareDate.getDate() - 1);

    let percentDaysComplete = 0;
    if (new Date(dateEventEnd) < compareDate) {
      percentDaysComplete = 100;
    } else if (new Date(dateInsertion) < compareDate) {
      // Total days includes an extra day as both start and end date are full delivery days
      const daysSoFar = Math.floor((compareDate - new Date(dateInsertion)) / 1000 / 60 / 60 / 24) + 1;
      percentDaysComplete = totalDays > 0 ? (daysSoFar / totalDays) * 100 : 0;
    }
    const directPacingHealth = impressions > 0 && costStructure === "CPM"
      ? ((deliveredImpressions / (percentDaysComplete / 100)) / (impressions * 1000)) * 100
      : 100;

    const ignoreUnlinkedOpportunityTypes = ["Consulting", "Retainer", "Prepayment", "Delta Tag", "Alphix Tag", "Ad Serving", "Charge", "Search Fee"];

    const rules = {
      meta: {
        activity_aggregator_id,
        bookedBy: createdBy,
        bookedImpressions: impressions * 1000,
        bookingUniqueNumber,
        budgetCodeName,
        campaignName,
        clientName,
        clientUuid,
        clientReportingCurrency,
        costToClient,
        datesList,
        directPacingHealth,
        deliveredImpressions,
        endDate: dateEventEnd,
        excludeFromTrafficking,
        mediaName,
        opportunity,
        opportunityType,
        percentDaysComplete,
        placementId: placementList ? String(placementList).split(",") : [],
        planUniqueNumber,
        startDate: dateInsertion,
        size,
        totalDays,
      },
      cpm1Impression: costToClient > 0 && costStructure === "CPM" && impressions <= 1 && mediaName !== "Acceleration"
        ? 2
        : 3,
      unlinkedToActivity: ignoreUnlinkedOpportunityTypes.indexOf(opportunityType) >= 0
        || activity_aggregator_id.length ? 3 : new Date(dateInsertion) > new Date() ? 2 : 1,
      directPacingHealth: directPacingHealth > 125 || directPacingHealth < 75 ? 1 :
        directPacingHealth > 110 || directPacingHealth < 90 ? 2 : 3,
    };

    return rules;
  }).filter(booking =>
    booking.meta.excludeFromTrafficking != 1 && booking.meta.costToClient >= 0 &&
    !['Alphix Solutions Platform', 'Fundamental Monitor', 'Fundamental Studio'].includes(booking.meta.mediaName) &&
    (booking.meta.endDate < lastYear || booking.meta.bookedImpressions > 0)
  );
};

const creative = (booking, creative) => {
  if (!booking) return [];

  const creativeList = booking.trafficType.reduce((creative, traffic) => {
    for (const creativeId of traffic.creativeIds) {
      if (creative.indexOf(creativeId) < 0) {
        creative.push(creativeId);
      }
    }
    return creative;
  }, []);


  const setUnlinked = creative.setUnlinked.reduce((unlinked, creative) => {
    unlinked[creative.id] = creative;
    return unlinked;
  }, {});

  return creativeList.map(creativeId => {
    const defaultPercent = booking.mapAggregateQuery[creativeId] && booking.mapAggregateQuery[creativeId].totImpressions + booking.mapAggregateQuery[creativeId].totDefaultImpressions > 0 ? booking.mapAggregateQuery[creativeId].totDefaultImpressions / (booking.mapAggregateQuery[creativeId].totImpressions + booking.mapAggregateQuery[creativeId].totDefaultImpressions) * 100 : "Unknown"
    const rules = {
      meta: {
        creativeId,
        advertiserId: setUnlinked[creativeId]
          ? setUnlinked[creativeId].advertiserId
          : "Unknown",
        accountId: setUnlinked[creativeId]
          ? setUnlinked[creativeId].accountId
          : "Unknown",
        advertiserName: setUnlinked[creativeId]
          ? setUnlinked[creativeId].advertiserName
          : "Unknown",
        clientUuid: setUnlinked[creativeId] ? setUnlinked[creativeId].clientUuid : "Unknown",
        creativeName: setUnlinked[creativeId] ? setUnlinked[creativeId].name : "Unknown",
        size: setUnlinked[creativeId] ? setUnlinked[creativeId].size : "Unknown",
        clicks: booking.mapAggregateQuery[creativeId] ? booking.mapAggregateQuery[creativeId].clicks : "Unknown",
        impressions: booking.mapAggregateQuery[creativeId] ? booking.mapAggregateQuery[creativeId].totImpressions + booking.mapAggregateQuery[creativeId].totDefaultImpressions : "Unknown",
        defaultImpressions: booking.mapAggregateQuery[creativeId] ? booking.mapAggregateQuery[creativeId].totDefaultImpressions : "Unknown",
        impressionBreakdown: booking.mapAggregateQuery[creativeId] ? booking.mapAggregateQuery[creativeId].impressionBreakdown : "Unknown",
        defaultPercent: defaultPercent,
      },
      setUnlinked: setUnlinked[creativeId] ? 2 : 3,
      dynamicCreative: setUnlinked[creativeId] && booking.mapAggregateQuery[creativeId] && defaultPercent > 5 ? 1 :
        setUnlinked[creativeId] && booking.mapAggregateQuery[creativeId] && defaultPercent > 0 ? 2 : 3
    };

    return rules;
  });
};

const landingPage = urlInvalid => {
  if (!urlInvalid) return [];
  return urlInvalid.map(url => {
    const {
      advertiserName,
      campaignId,
      accountId,
      placementId,
      placementName,
      landing_page,
      last_seen,
      error_code,
    } = url;

    const invalidFlags = {
      invalid: 1,
      unknown: 2,
      domainGoogle: 4,
      doubleQuestionMark: 8,
    };
    const invalidList = [];
    for (const invalidFlag of Object.entries(invalidFlags)) {
      if ((error_code & invalidFlag[1]) === invalidFlag[1]) {
        invalidList.push(invalidFlag[0]);
      }
    }
    const trackingFlags = {
      missingUtmCaField: 16,
      missingCa: 32,
      multipleCa: 64,
      wrongCa: 128,
      missingPlacementId: 256,
      wrongPlacementId: 512,
    };
    const trackingList = [];
    for (const trackingFlag of Object.entries(trackingFlags)) {
      if ((error_code & trackingFlag[1]) === trackingFlag[1]) {
        trackingList.push(trackingFlag[0]);
      }
    }

    const rules = {
      meta: {
        advertiserName,
        campaignId,
        accountId,
        placementId,
        placementName,
        landing_page,
        last_seen,
      },
      data: {
        invalidList,
        trackingList,
      },
      invalidUrl: invalidList.length > 0 ? 1 : 3,
      trackingIssue: trackingList.length > 0 ? 2 : 3,
    };

    return rules;
  });
};

const fmxIo = io => {
  return io.map(io => {
    let {
      advertiser_id,
      campaign_id,
      partnerId,
      insertionOrderId,
      engagedContent,
      name,
      budget_amount,
      margin,
      pacing,
      pacing_rate,
      start_date,
      end_date,
      totalMediaCost,
      totalImpressions,
      timestamp,
      maxLineItemTimestamp,
      advertiserName,
      budget_type,
      currency,
      lastWeekMediaCost
    } = io;

    timestamp = new Date(new Date(timestamp) > new Date(maxLineItemTimestamp) ? timestamp : maxLineItemTimestamp);
    const lastUpdate = '' + new Date(timestamp)
    const timestampSeconds = timestamp.getTime() / 1000;
    let now = new Date();
    const nowSeconds = now.getTime() / 1000;
    const lastUpdateDays = Math.round((nowSeconds - timestampSeconds) / (60 * 60 * 24));

    const data = {
      margin,
      pacing,
      pacing_rate,
      timestamp,
      lastUpdate,
    };
    const rules = {
      meta: {
        advertiser_id,
        advertiserName,
        campaign_id,
        currency,
        partnerId,
        insertionOrderId,
        name,
        budget_amount,
        start_date,
        end_date,
        totalMediaCost,
        totalImpressions,
        budget_type,
        lastWeekMediaCost
      },
      data: {},
      budgetSetting: pacing === "Daily" ? 3 : 2,
      lastUpdate: lastUpdateDays > 7 ? 1 : lastUpdateDays > 3 ? 2 : 3,
    };

    rules.margin = !margin ||
      engagedContent ?
      (margin != 59 ? 1 : 3) :
      (margin != 29 ? 1 : 3)
      ;

    if (pacing_rate === "ASAP") rules.budgetSetting -= 1;

    start_date = new Date(start_date);
    end_date = new Date(end_date);
    if (start_date < new Date() && end_date > new Date()) {
      const totalDays =
        Math.round((end_date - start_date) / (24 * 60 * 60 * 1000)) + 1;
      const daysLeft =
        Math.ceil((end_date - new Date()) / (24 * 60 * 60 * 1000)) + 1;
      const percentComplete = ((totalDays - daysLeft) / totalDays) * 100;

      // NEW CALCULATIONS
      const budgetSpent = totalMediaCost
      // if budget_type is Impressions then budget_amount becomes expected impressions, it is not a monetary amount anymore
      const budgetPercentage = budget_amount ? budget_type !== 'Impressions' ? (budgetSpent / budget_amount) : totalImpressions / budget_amount : 0;
      const goalPacing = (totalDays - daysLeft) / totalDays
      const pacingStatus = budgetPercentage / goalPacing;
      const budgetAtRisk = budget_amount ? (budget_amount - (budget_amount * pacingStatus)) : 0

      data.budgetPercentage = budgetPercentage * 100
      data.goalPacing = goalPacing * 100
      data.pacingStatus = pacingStatus * 100
      data.budgetAtRisk = budgetAtRisk

      rules.pacingHealth = data.goalPacing < 75 || data.goalPacing > 125 ? 1 : (data.goalPacing > 75 && data.goalPacing < 90) || (data.goalPacing > 110 && data.goalPacing <= 125) ? 2 : 3;

      rules.meta.daysLeft = daysLeft;
      rules.meta.percentComplete = percentComplete;
    } else {
      rules.pacingHealth = 3;
      rules.meta.daysLeft = 0;
      rules.meta.percentComplete = 100;
    }

    rules.data = data;

    return rules;
  });
};

const fmxLi = li => {
  return li.map(li => {
    let {
      advertiser_id,
      advertiserName,
      budget_amount,
      budget_type,
      currency,
      dealType,
      campaign_id,
      end_date,
      engagedContent,
      insertion_order_id,
      ioName,
      lastWeekMediaCost,
      line_item_id,
      margin,
      minDate,
      maxDate,
      name,
      pacing,
      pacing_rate,
      partnerId,
      siteList,
      start_date,
      targetingSite,
      targetingAudience,
      targetingBrandSafety,
      targetingGeo,
      targetingKeyWord,
      targetingLanguage,
      targetingViewability,
      totalImpressions,
      totalMediaCost,
    } = li;

    const data = {
      margin,
      pacing,
      pacing_rate,
      targetingSite: targetingSite ? "yes" : "no",
      targetingAudience: targetingAudience ? "yes" : "no",
      targetingKeyWord: targetingKeyWord ? "yes" : "no",
      targetingBrandSafety: targetingBrandSafety ? "yes" : "no",
      targetingViewability: targetingViewability ? "yes" : "no",
      targetingGeo: targetingGeo ? "yes" : "no",
      targetingLanguage: targetingLanguage ? "yes" : "no",
    };
    const rules = {
      currency,
      meta: {
        advertiser_id,
        advertiserName,
        budget_amount,
        budget_type,
        campaign_id,
        currency,
        end_date,
        insertionOrderId: insertion_order_id,
        ioName,
        lastWeekMediaCost,
        lineItemId: line_item_id,
        maxDate,
        minDate,
        name,
        partnerId,
        siteList,
        siteListYearCount: siteList.filter(s => s.yearImpressions > 0).length,
        siteListWeekCount: siteList.filter(s => s.weekImpressions > 0).length,
        siteListDayCount: siteList.filter(s => s.dayImpressions > 0).length,
        start_date,
        totalImpressions,
        totalMediaCost,
      },
      budgetSetting: pacing === "Daily" ? 3 : 2,
    };

    rules.margin = !margin ||
      engagedContent ?
      (margin != 59 ? 1 : 3) :
      (margin != 29 ? 1 : 3)
      ;

    start_date = new Date(start_date);
    end_date = new Date(end_date);
    if (start_date < new Date() && end_date > new Date()) {
      const totalDays =
        Math.round((end_date - start_date) / (24 * 60 * 60 * 1000)) + 1;
      const daysLeft =
        Math.ceil((end_date - new Date()) / (24 * 60 * 60 * 1000)) + 1;
      const percentComplete = ((totalDays - daysLeft) / totalDays) * 100;

      // NEW CALCULATIONS
      const budgetSpent = totalMediaCost
      const budgetPercentage = budget_amount ? budget_type !== 'Impressions' ? (budgetSpent / budget_amount) : totalImpressions / budget_amount : 0;
      const goalPacing = (totalDays - daysLeft) / totalDays

      const pacingStatus = budgetPercentage / goalPacing;
      const budgetAtRisk = budget_amount ? (budget_amount - (budget_amount * pacingStatus)) : 0

      data.budgetPercentage = budgetPercentage * 100
      data.goalPacing = goalPacing * 100
      data.pacingStatus = pacingStatus * 100
      data.budgetAtRisk = budgetAtRisk

      rules.pacingHealth = data.goalPacing < 75 || data.goalPacing > 125 ? 1 : (data.goalPacing > 75 && data.goalPacing < 90) || (data.goalPacing > 110 && data.goalPacing <= 125) ? 2 : 3;

      rules.meta.daysLeft = daysLeft;
      rules.meta.percentComplete = percentComplete;
    } else {
      rules.pacingHealth = 3;
      rules.meta.daysLeft = 0;
      rules.meta.percentComplete = 100;
    }

    if (pacing_rate === "ASAP" && dealType === "PMP") {
      rules.budgetSetting = 3;
    } else if (pacing_rate === "ASAP") {
      rules.budgetSetting -= 1;
    }

    if (dealType === "OMP") {
      rules.vitalTargeting =
        targetingSite > 0 ? (targetingAudience > 0 ? 3 : 2) : 1;

      let targetingHealth = 0;
      if (targetingSite > 0) targetingHealth += 50;
      if (targetingAudience > 0) targetingHealth += 24;
      if (targetingKeyWord > 0) targetingHealth += 4;
      if (targetingBrandSafety > 0) targetingHealth += 4;
      if (targetingViewability > 0) targetingHealth += 4;
      if (targetingGeo > 0) targetingHealth += 4;
      if (targetingLanguage > 0) targetingHealth += 4;

      rules.targetingHealth =
        targetingHealth > 49 ? 3 : targetingHealth > 20 ? 2 : 1;
    } else {
      rules.vitalTargeting = 3;
      rules.targetingHealth = 3;
    }

    rules.data = data;

    rules.siteList = siteList.length >= 100 ? 2 : 3;

    return rules;
  });
};

const trafficLastYear = bookings => {
  if (!bookings || !bookings.trafficLastYear) return [];

  return bookings.trafficLastYear.map((row) => {
    let {
      advertiserName,
      campaignId,
      accountId,
      campaignName,
      placementId,
      placementName,
      siteId,
      siteName,
      impressions,
      clicks
    } = row;

    let meta = {
      accountId,
      advertiserName,
      campaignId,
      campaignName,
      placementId,
      placementName,
      siteId,
      siteName,
    };

    let outOfRange;

    const matched = !bookings.unmatchedPlacement.find(u => u.placementId === placementId);

    if (matched) {
      outOfRange = bookings.outOfRange.find(p => p.placementId === placementId);
      if (outOfRange) {
        meta = {
          ...meta,
          ...Object.fromEntries(Object.entries(outOfRange).map(o => [`outOfRange${o[0].charAt(0).toUpperCase()}${o[0].slice(1)}`, o[1]])),
        };
        meta['impressionDateRange'] = `${moment(meta.outOfRangeMinDate).format(dateFormat)} - ${moment(meta.outOfRangeMaxDate).format(dateFormat)}`
      }
    }

    return {
      meta,
      data: {
        impressions,
        clicks,
      },
      outOfRange: outOfRange ? 2 : 3,
      unmatched: matched ? 3 : 2,
    };
  });
};

const trafficType = (bookings) => {
  if (!bookings || !bookings.trafficType) return [];

  return bookings.trafficType.map((row) => {
    let {
      advertiserName,
      campaignId,
      accountId,
      advertiserId,
      campaignName,
      placementId,
      placementName,
      siteId,
      siteName,
      impressions,
      defaultImpressions,
      measurableImpressions,
      viewableImpressions,
      firstDate,
      latestDate,
      firstDefaultDate,
      latestDefaultDate,
      lastWeekMeasurableImpressions,
      lastWeekViewableImpressions,
      previousWeekMeasurableImpressions,
      previousWeekViewableImpressions,
      clicks,
      invalidClicks,
    } = row;

    const defaultPercent =
      defaultImpressions > 0 || impressions > 0 ? (defaultImpressions /
        (impressions + defaultImpressions)) * 100 : 0;
    const viewablePercent =
      measurableImpressions > 0
        ? (viewableImpressions / measurableImpressions) * 100
        : 100;
    const lastWeekViewablePercent =
      lastWeekMeasurableImpressions > 0
        ? (lastWeekViewableImpressions / lastWeekMeasurableImpressions) * 100
        : 100;
    const previousWeekViewablePercent =
      previousWeekMeasurableImpressions > 0
        ? (previousWeekViewableImpressions / previousWeekMeasurableImpressions) * 100
        : 100;
    const lastWeeksViewabilityChange = (1 -
      (previousWeekViewablePercent / lastWeekViewablePercent)) * 100;

    const invalidRatio = clicks > 0 && invalidClicks > 0 ? (invalidClicks /
      (clicks + invalidClicks) * 100) : 0;

    let meta = {
      advertiserName,
      advertiserId,
      campaignId,
      accountId,
      campaignName,
      placementId,
      placementName,
      siteId,
      siteName,
      firstDate,
      latestDate,
      firstDefaultDate,
      latestDefaultDate,
      impressions,
      defaultImpressions,
      lastWeekViewablePercent,
      previousWeekViewablePercent,
      lastWeeksViewabilityChange,
      clicks,
      invalidClicks,
      invalidRatio,
    };

    return {
      meta,
      data: {
        defaultPercent,
        defaultImpressions,
        viewablePercent,
      },
      defaultCreative: defaultImpressions > 100 && defaultPercent >= 5 ? 1 :
        defaultImpressions > 100 && defaultPercent >= 3 ? 2 : 3,
      viewability: viewablePercent < 50 ? 1 : viewablePercent < 60 ? 2 : 3,
      invalidClick: invalidRatio > 25 ? 2 : 3
    };
  });
};

export class CoreTroubleshooterAggregatorRules {
  constructor(data, access) {
    this.data = data;
    this.access = access;
    this.processedData = false;
  }

  groups() {
    return {
      aa: this.groupAa().total,
      amx: this.groupAmx().total,
      amxBudget: this.groupAmxBudget().total,
      amxPacing: this.groupAmxPacing().total,
      amxTargeting: this.groupAmxTargeting().total,
      cmIntell: this.groupCmIntell().total,
      cmReporting: this.groupCmReporting().total,
      cmTraffic: this.groupCmTraffic().total,
      cmLandingPage: this.groupCmLandingPage().total,
      creative: this.groupCreative().total,
      fmxBudgetPacing: this.groupFmxBudgetPacing().total,
      fmxTargeting: this.groupFmxTargeting().total,
      fmxBudgetPacingUpcoming: this.groupFmxBudgetPacing(true).total,
      fmxTargetingUpcoming: this.groupFmxTargeting(true).total,
      fmxOptimisation: this.groupFmxOptimisation().total,
    };
  }

  groupAa() {
    return this._buildGroup(
      ["lastLog"],
      this.processData().aa,
      "aaUuid",
    );
  }

  groupAmx() {
    return this._buildGroup(
      ["macros", "placement"],
      this.processData().amx,
      "amxAlternativeId",
    );
  }

  groupAmxBudget() {
    const group = { 
      amxCampaignBudget: this._buildGroup(["amxCampaignBudget"], this.processData().amxBudget.amxCampaignBudget, "campaignId",),
      amxDailyBudget: this._buildGroup(["amxDailyBudget"], this.processData().amxBudget.amxDailyBudget, "campaignId",),
      campaignSpendBudget: this._buildGroup(["campaignSpendBudget"], this.processData().amxBudget.campaignSpendBudget, "campaignId",),
      campaignTechFee: this._buildGroup(["campaignTechFee"], this.processData().amxBudget.campaignTechFee, "campaignId",),
    };
    group.total = {
      1: group.amxCampaignBudget.total[1] + group.amxDailyBudget.total[1] + group.campaignSpendBudget.total[1] + group.campaignTechFee.total[1],
      2: group.amxCampaignBudget.total[2] + group.amxDailyBudget.total[2] + group.campaignSpendBudget.total[2] + group.campaignTechFee.total[2],
      3: group.amxCampaignBudget.total[3] + group.amxDailyBudget.total[3] + group.campaignSpendBudget.total[3] + group.campaignTechFee.total[3],
    };

    const groupedClients = [group.amxCampaignBudget.client, group.amxDailyBudget.client, group.campaignSpendBudget.client, group.campaignTechFee.client];
    const groupedClientOffice = [group.amxCampaignBudget.clientOffice, group.amxDailyBudget.clientOffice, group.campaignSpendBudget.clientOffice, group.campaignTechFee.clientOffice];
    const groupedClientType = [group.amxCampaignBudget.clientType, group.amxDailyBudget.clientType, group.campaignSpendBudget.clientType, group.campaignTechFee.clientType];

    if(this.access.troubleshooter.insights){
      group.client = this.combineGroups(groupedClients, 'clientName');
      group.clientOffice = this.combineGroups(groupedClientOffice);
      group.clientType = this.combineGroups(groupedClientType);
    }

    group.amxCampaignBudget.errors = group.amxCampaignBudget.amxCampaignBudget.errors
    group.amxDailyBudget.errors = group.amxDailyBudget.amxDailyBudget.errors
    group.campaignSpendBudget.errors = group.campaignSpendBudget.campaignSpendBudget.errors
    group.campaignTechFee.errors = group.campaignTechFee.campaignTechFee.errors

    return group;
  }

  groupAmxPacing() {
    const group = { 
      campaigns: this._buildGroup(["campaigns"], this.processData().amxPacing.campaigns, "campaignId",),
      lineitems: this._buildGroup(["lineitems"], this.processData().amxPacing.lineitems, "amxLineItemId",),
      liBudgetSetting: this._buildGroup(["liBudgetSetting"], this.processData().amxPacing.liBudgetSetting, "amxLineItemId",)
    };
    group.total = {
      1: group.campaigns.total[1] + group.lineitems.total[1] + group.liBudgetSetting.total[1],
      2: group.campaigns.total[2] + group.lineitems.total[2] + group.liBudgetSetting.total[2],
      3: group.campaigns.total[3] + group.lineitems.total[3] + group.liBudgetSetting.total[3],
    };

    const groupedClients = [group.campaigns.client, group.lineitems.client, group.liBudgetSetting.client];
    const groupedClientOffice = [group.campaigns.clientOffice, group.lineitems.clientOffice, group.liBudgetSetting.clientOffice]
    const groupedClientType = [group.campaigns.clientType, group.lineitems.clientType, group.liBudgetSetting.clientType]

    if(this.access.troubleshooter.insights){
      group.client = this.combineGroups(groupedClients, 'clientName');
      group.clientOffice = this.combineGroups(groupedClientOffice);
      group.clientType = this.combineGroups(groupedClientType);
    }

    group.campaigns.errors = group.campaigns.campaigns.errors
    group.lineitems.errors = group.lineitems.lineitems.errors
    group.liBudgetSetting.errors = group.liBudgetSetting.liBudgetSetting.errors

    return group;
  }

  groupAmxTargeting() {
    return this._buildGroup(
      ["lineItemTargeting"],
      this.processData().amxTargeting.lineItemTargeting,
      "amxLineItemId"
    );
  }

  groupCmIntell() {
    return this._buildGroup(
      ["cpm1Impression", "unlinkedToActivity", "directPacingHealth"],
      this.processData().booking,
      "bookingUniqueNumber",
    );
  }

  groupCmTraffic() {
    return this._buildGroup(
      ["defaultCreative", "viewability", "invalidClick"],
      this.processData().trafficType,
      "placementId",
    );
  }

  groupCmReporting() {
    return this._buildGroup(
      ["outOfRange", "unmatched"],
      this.processData().trafficLastYear,
      "placementId",
    );
  }

  groupCmLandingPage() {
    return this._buildGroup(
      ["invalidUrl", "trackingIssue"],
      this.processData().landingPage,
      "placementId",
    );
  }

  groupCreative() {
    return this._buildGroup(
      ["setUnlinked", "dynamicCreative"],
      this.processData().creative,
      "creativeId",
    );
  }

  groupFmxBudgetPacing(upcoming = false) {

    const group = {
      io: this._buildGroup(
        ["budgetSetting", "margin", "pacingHealth"],
        this.processData().fmx[upcoming ? `upcoming_io` : "io"],
        "insertionOrderId",
      ),
      li: this._buildGroup(
        ["budgetSetting", "margin", "pacingHealth"],
        this.processData().fmx[upcoming ? `upcoming_li` : "li"],
        "lineItemId",
      ),
    };
    group.total = {
      1: group.io.total[1] + group.li.total[1],
      2: group.io.total[2] + group.li.total[2],
      3: group.io.total[3] + group.li.total[3],
    };
    if (this.access.troubleshooter.insights) {
      group.client = { ...group.io.client, ...group.li.client };
      group.clientOffice = { ...group.io.clientOffice, ...group.li.clientOffice };
      group.clientType = { ...group.io.clientType, ...group.li.clientType };
    }

    const allIOErrors = [];

    const mapIO = new Map();
    for (const item of group.io.budgetSetting.errors) {
      if (!mapIO.has(item.meta.insertionOrderId)) {
        mapIO.set(item.meta.insertionOrderId, true);
        allIOErrors.push(item);
      }
    }

    for (const item of group.io.margin.errors) {
      if (!mapIO.has(item.meta.insertionOrderId)) {
        mapIO.set(item.meta.insertionOrderId, true);
        allIOErrors.push(item);
      }
    }

    for (const item of group.io.pacingHealth.errors) {
      if (!mapIO.has(item.meta.insertionOrderId)) {
        mapIO.set(item.meta.insertionOrderId, true);
        allIOErrors.push(item);
      }
    }

    group.io.errors = allIOErrors


    const allLIErrors = [];

    const mapLI = new Map();
    for (const item of group.li.budgetSetting.errors) {
      if (!mapLI.has(item.meta.insertionOrderId)) {
        mapLI.set(item.meta.insertionOrderId, true);
        allLIErrors.push(item);
      }
    }

    for (const item of group.li.margin.errors) {
      if (!mapLI.has(item.meta.insertionOrderId)) {
        mapLI.set(item.meta.insertionOrderId, true);
        allLIErrors.push(item);
      }
    }

    for (const item of group.li.pacingHealth.errors) {
      if (!mapLI.has(item.meta.insertionOrderId)) {
        mapLI.set(item.meta.insertionOrderId, true);
        allLIErrors.push(item);
      }
    }

    group.li.errors = allLIErrors

    return group;
  }

  groupFmxOptimisation(upcoming = false) {
    return this._buildGroup(
      ["lastUpdate"],
      this.processData().fmx.io,
      "insertionOrderId",
    );
  }

  groupFmxTargeting() {
    return this._buildGroup(
      ["vitalTargeting", "targetingHealth", "siteList"],
      this.processData().fmx.li,
      "lineItemId",
    );
  }

  processData() {
    if (!this.processedData) {
      this.processedData = {
        booking: booking(this.data.booking),
        fmx: {
          io: fmxIo(this.data.fmx.io ? this.data.fmx.io : []),
          li: fmxLi(this.data.fmx.li ? this.data.fmx.li : []),
          upcoming_io: fmxIo(this.data.fmx.upcomingIo ? this.data.fmx.upcomingIo : []),
          upcoming_li: fmxLi(this.data.fmx.upcomingLi ? this.data.fmx.upcomingLi : []),
        },
        trafficType: trafficType(this.data.booking),
        trafficLastYear: trafficLastYear(this.data.booking),
        landingPage: landingPage(this.data.placement.urlInvalid),
        creative: creative(this.data.booking, this.data.creative),
        aa: aa(this.data.aa),
        amx: amx(this.data.amx),
        amxBudget: { 
          campaignSpendBudget: amxCSBBudget(this.data.amxBudget),
          campaignTechFee: amxCTFBudget(this.data.amxBudget),
          amxCampaignBudget: amxCampaignBudget(this.data.amxBudget),
          amxDailyBudget: amxDailyBudget(this.data.amxBudget)
        },
        amxPacing: {
          campaigns: amxCPacing(this.data.amxPacing.campaigns),
          lineitems: amxLiPacing(this.data.amxPacing.lineItems),
          liBudgetSetting: amxLiBgSPacing(this.data.amxPacing.liBudgetSetting)
        },
        amxTargeting: {
          lineItemTargeting: amxLiTargeting(this.data.amxTargeting.lineItemTargeting)
        }
      };
    }

    return this.processedData;
  }

  _buildGroup(errorList, data, metaClientLink) {
    const group = {
      total: {
        1: 0,
        2: 0,
        3: 0
      }
    };
    if (this.access.troubleshooter.insights) {
      group.client = {};
      group.clientOffice = {};
      group.clientType = {};
    }
    for (const error of errorList) {
      group[error] = {
        1: 0,
        2: 0,
        3: 0,
        errors: [],
        client: {},
        clientOffice: {},
        clientType: {}
      };
    }

    for (const row of data) {
      
      const connection = this.access.troubleshooter.insights
        ? this.data?.connection[metaClientLink]?.[String(row.meta[metaClientLink])]
        : [];
      let total = 4;
      for (const error of errorList) {
        if (row[error]) {
          if (row[error] < total) total = row[error];
          if (row[error] < 3) group[error].errors.push(row);
          if (group[error]) group[error][row[error]] += 1;
        }
        if (connection && connection.length) {
          for (const client of connection) {
            const {
              clientUuid,
              clientName,
              fundamentalInvoiceOffice,
              type
            } = client;
            if (!group[error]) continue;
            if (!group[error].client[clientUuid]) {
              group[error].client[clientUuid] = {
                1: 0,
                2: 0,
                3: 0,
                clientName
              };
            }
            group[error].client[clientUuid][row[error]] += 1;
            if (!group[error].clientOffice[fundamentalInvoiceOffice]) {
              group[error].clientOffice[fundamentalInvoiceOffice] = {
                1: 0,
                2: 0,
                3: 0
              };
            }
            group[error].clientOffice[fundamentalInvoiceOffice][row[error]] += 1;
            if (!group[error].clientType[type]) {
              group[error].clientType[type] = {
                1: 0,
                2: 0,
                3: 0
              };
            }
            group[error].clientType[type][row[error]] += 1;
          }
        }
      }
      if (total < 4) group.total[total] += 1;
      if (connection && connection.length) {
        for (const client of connection) {
          const {
            clientUuid,
            clientName,
            fundamentalInvoiceOffice,
            type
          } = client;
          if (!group.client[clientUuid]) {
            group.client[clientUuid] = {
              1: 0,
              2: 0,
              3: 0,
              clientName
            };
          }
          group.client[clientUuid][total] += 1;
          if (!group.clientOffice[fundamentalInvoiceOffice]) {
            group.clientOffice[fundamentalInvoiceOffice] = {
              1: 0,
              2: 0,
              3: 0
            };
          }
          group.clientOffice[fundamentalInvoiceOffice][total] += 1;
          if (!group.clientType[type]) {
            group.clientType[type] = {
              1: 0,
              2: 0,
              3: 0
            };
          }
          group.clientType[type][total] += 1;
        }
      }
    }
    return group;
  }

  combineGroups(objectArray, constantKey = null) {
    const result = {};

    const dynamicKeySet = new Set(); //creates a set of overall keys, e.g. client UUIDs for an object Array of clients
    for (const category of objectArray) {
      const setKeys = Object.keys(category);
      for (const item of setKeys) {
        dynamicKeySet.add(item)
      }
    }

    for (const dynamicKey of dynamicKeySet) {
      result[dynamicKey] = {};

      if(constantKey){ //If there's a key that appears in all sets e.g. clientName it will create an object with that key
        for(const item of objectArray){
          if (item[dynamicKey]) {
            result[dynamicKey][constantKey] = item[dynamicKey][constantKey];
          }
        }
      }

      const keySet = new Set(); //Creates a set of score keys 1, 2, 3 plus any constant keys
      for (const category of objectArray){
        if(category[dynamicKey]) {
          const nestedKeyList = Object.keys(category[dynamicKey]);
          for (const key of nestedKeyList){
            keySet.add(key)
          }
        }
      }

      for(const score of keySet) {
        if(score !== constantKey) { //Sum the individual scores into one overall total, ignoring constantKey
          result[dynamicKey][score] = objectArray.reduce((sum, obj) => sum + (obj[dynamicKey]?.[score] || 0), 0)
        }
      }
    }
    return result;
  }
}
