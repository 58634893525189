import { render, staticRenderFns } from "./Setup.vue?vue&type=template&id=1a7f592f&scoped=true"
import script from "./Setup.vue?vue&type=script&lang=js"
export * from "./Setup.vue?vue&type=script&lang=js"
import style0 from "./Setup.vue?vue&type=style&index=0&id=1a7f592f&prod&lang=sass"
import style1 from "./Setup.vue?vue&type=style&index=1&id=1a7f592f&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a7f592f",
  null
  
)

export default component.exports