<template>
  <div class="sStickyHeader">
    <transition name="slide-left">
      <v-navigation-drawer
        v-model="showMenu"
        app
        mobile-breakpoint="9000"
        class="pa-0"
        :class="$vuetify.breakpoint.width >= '9000' ? 'elevation-1' : ''"
        :clipped="$vuetify.breakpoint.width >= '9000' "
        fixed
        floating
        width="276"
      >
        <page-header-nav
          @close="closeMenu()"
        />
      </v-navigation-drawer>
    </transition>
    <transition name="slide-down">
      <header>
        <div class="menuButton">
          <div
            v-if="getUserHasAcceptedTerms"
            class="box"
            @click="showMenu = true"
          >
            <i class="far fa-bars" />
            <span>MENU</span>
            <transition
              appear
              name="fade"
            >
              <v-btn
                v-if="button.text"
                key="testing"
                color="primary"
                outlined
                class="ml-10"
                @click="button.action()"
              >
                Go to {{ button.text }}
              </v-btn>
            </transition>
          </div>
        </div>
        <div class="logo">
          <router-link 
            v-slot="{ navigate }"
            :to="$route.path !== '/terms' ? '/': ''" 
            :disabled="$route.path === '/terms'" 
            custom 
          >
            <span 
              role="link"
              @click="navigate"
            >            
              <img
                src="../../assets/logo.svg"
                alt="Fundamental Media Portal"
              >
            </span>
          </router-link>
        </div>
        <div class="menuRightButton">
          <div
            class="helpBox"
            title="OneHelp Menu"
          >
            <v-menu
              v-model="helpMenu"
              content-class="sOneHelpMenu"
              bottom
              :close-on-click="false"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-help-circle-outline
                  </v-icon>
                </div>
              </template>
              <onehelp-menu-route
                title="OneHelp"
                project-id="PORTAL"
                :help-menu="helpMenu"
                @closeHelpMenu="handleOneHelpClose"
              />
            </v-menu>
          </div>
          <div
            class="box"
            title="Manage your settings and logout"
            @click="showRightMenu = !showRightMenu"
            @mouseleave="showRightMenu = false"
          >
            <div class="sUser">
              <i class="fal fa-user-circle" />
              <div class="sName">
                {{ getName }}
              </div>
              <i class="far fa-ellipsis-v" />
            </div>
            <transition name="fade">
              <div
                v-if="showRightMenu"
                class="menuRight"
              >
                <ul>
                  <li
                    v-if="getUserHasAcceptedTerms"
                    @click="
                      $router.push('/user/detail');
                    "
                  >
                    My Details
                  </li>
                  <li @click="logout()">
                    Sign out
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
      </header>
    </transition>
    <transition name="slide-down">
      <page-header-breadcrumb
        v-if="breadcrumb"
        :breadcrumb="breadcrumb"
        class="sBreadcrumbTransition"
        :style="{ marginLeft: showMenu && $vuetify.breakpoint.width >= '9000' ? '276px': '' }"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PageHeaderNav from "./Header/Nav";
import PageHeaderBreadcrumb from "./Header/Breadcrumb";
import Api from "../../connect/api";
import OnehelpMenuRoute from "@/components/Onehelp/Menu/Route";
import {getLoginRoute} from '@/core/domain';

export default {
  components: {
    OnehelpMenuRoute,
    PageHeaderBreadcrumb,
    PageHeaderNav
  },
  props: {
    breadcrumb: {
      type: Object,
      default: null
    }
  },
  data: function() {
    return {
      access: {},
      envs: ["development", "staging", "release", "beta", "alpha"],
      helpMenu: false,
      menuCloseTimeout: false,
      showMenu: Boolean(this.$vuetify.breakpoint.width >= '9000' ),
      showRightMenu: false
    };
  },
  computed: {
    ...mapGetters("di", ["getName", "getId", "getEnv", "getSettings", "getUserHasAcceptedTerms"]),
    ...mapGetters("subroute", ["getOnehelp"]),
    button() {
      let action, text;
      if( this.envs.includes(this.getEnv))
      {
        action = () => window.location.href = ("https://portal.fundamentalmedia.com" + window.location.pathname)
        text = "production"
      } else if (!!(this.getSettings && this.getSettings['release-show']) && this.getEnv === "production") {
        action = () => window.location.href = ("https://release-portal.fundamentalmedia.com" + window.location.pathname)
        text = "release"
      } else if (!!(this.getSettings && this.getSettings['staging-show']) && this.getEnv === "production") {
        action = () => window.location.href = ("https://staging-portal.fundamentalmedia.com" + window.location.pathname)
        text = "staging"
      }
      return {
        text,
        action
      }
    },
  },
  mounted: function() {
    this.lookUpSettings();
  },
  methods: {
    ...mapActions("di", ["lookUpSettings"]),
    ...mapActions("subroute", ["routeOnehelp"]),
    closeMenu() {
      const $this = this;
      const close = () => {
        $this.showMenu = false;
      };

      close();
    },
    handleOneHelpClose() {
      this.helpMenu = !this.helpMenu
      if(this.getOnehelp.name === "submissionSuccess") {
        this.routeOnehelp("home")
      }
    },
    logout() {
      this.closeMenu();
      Api.get("auth/logout", () => {
        window.location = getLoginRoute(false, 'logout');
      });
    },
    preserveMenu() {
      if (this.menuCloseTimeout) clearTimeout(this.menuCloseTimeout);
    },
    userDetail() {
      this.$router.push({ name: "user-detail", params: { id: this.getId } });
    }
  }
};
</script>

<style scoped lang="scss">
.sOneHelpMenu{
  max-height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;

  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 5px solid #DEE2EB;
    height: 10px;

  }
}
</style>

<style scoped lang="sass">
@import "../../scss/fundamental-variables.scss"

.sBreadcrumbTransition
  transition: margin 150ms

.sStickyHeader
  position: -webkit-sticky
  position: sticky
  top: 0
  z-index: 100

.fade-enter-active
  transition: all 1.28s ease

.fade-leave-active
  transition: all 1.28s ease

.fade-enter, .fade-leave-to
  opacity: 0


header
  height: 100px
  width: 100%
  display: grid
  grid-template-columns: 30% 40% 30%
  grid-template-areas: "menuButton logo menuRightButton"
  color: $colorDark
  background-color: white
  border-bottom: solid 1px #DDDDDD
  .menuButton
    .box
      padding: 0 39px
      height: 100px
      display: inline-flex
      align-items: center
      cursor: pointer
      i
        color: $colorLight
        font-size: 1.5em
      span
        margin-left: 14px
  .logo
    text-align: center
    span
      cursor: pointer
    img
      height: 60px
      margin-top: 20px
  .menuRightButton
    text-align: right
    .helpBox
      position: relative
      bottom: 6px
      display: inline-flex
    .box
      position: relative
      height: 100px
      display: inline-flex
      align-items: center
      padding: 0 56px 0 30px
      border-left: solid 1px $colorLighterThenWhite
      cursor: pointer
      .sUser
        display: flex
        div
          padding-top: 4px
        i
          color: $colorLight
          font-size: 1.5em
          &.fa-user-circle
            padding-right: 15px
            font-size: 2em
          &.fa-ellipsis-v
            padding-top: 4px
            padding-left: 20px
    .menuRight
      position: absolute
      width: 100%
      top: 100px
      right: 0
      background-color: white
      box-shadow: 0px 3px 6px $colorLight
      z-index: 100
      ul
        margin: 0
        padding: 0
        list-style: none
        li
          padding: 20px
          text-align: left
          cursor: pointer
          &:hover
            background-color: $colorWhite
</style>
