const templateList = [
  {
    id: "T0001",
    name: "Standard Template",
    description:
      "A selection of the main DoubleClick slides to use as a default",
    slides: [
      {
        id: "TS0001",
        component: "cover",
        title: "Cover Page",
        options: {
          cover: "https://gcs.fundamentalintelligence.com/cover-global-1.png"
        }
      },
      {
        id: "TS0002",
        component: "campaign-total",
        title: "Campaign Overview",
        options: {
          group: "campaign"
        }
      },
      {
        id: "TS0003",
        component: "campaign-status",
        title: "Campaign Status"
      },
      {
        id: "TS0004",
        component: "campaign-status-campaign",
        title: "Campaigns Status"
      },
      {
        id: "TS0005",
        component: "campaign-status-dbm-campaign",
        title: "Campaigns FMX Status"
      },
      {
        id: "TS0006",
        component: "placement-type",
        title: "Placements by Size",
        options: {
          group: "size"
        }
      },
      {
        id: "TS0007",
        component: "placement-type",
        title: "Placements by Opportunity",
        options: {
          group: "opportunity"
        }
      },
      {
        id: "TS0008",
        component: "creative-performance",
        title: "Creative Performance",
        options: {
          group: "site"
        }
      },
      {
        id: "TS0009",
        component: "creative-comparison",
        title: "Creative Comparison"
      },
      {
        id: "TS0011",
        component: "campaign-impression-delivery",
        title: "All Time Delivery By Site"
      },
      {
        id: "TS0015",
        component: "campaign-insight",
        title: "Daily Campaign Breakdown"
      },
      {
        id: "TS0016",
        component: "creative-site-comparison",
        title: "Creative Site Comparison"
      },
      {
        id: "TS0013",
        component: "thankyou",
        title: "Thank You",
        position: "end"
      }
    ]
  },
  {
    id: "T0002",
    name: "Blank Template",
    description: "A blank canvas on which to craft a report or presentation",
    slides: []
  }
];

module.exports = { templateList };
