<template>
  <div
    :class="open ? 'sOpenContainer' : 'sClosedContainer'"
    :style="open ? containerStyle : null"
  >
    <onehelp-menu-header
      :title="title"
      v-on="$listeners"
      @switchOpen="open = !open"
    />
    <div
      v-show="open"
      class="sContainer"
      :style="styleHeight"
    >
     <div class="sLowerHeader">
      <div
        v-if="backRoute"
        class="sBackRoute"
      >
        <span
          class="cursorPointer"
          @click="$emit('routeChange', backRoute.route)"
        >
          <v-icon left>
            fal fa-angle-left
          </v-icon>
          <span>{{ backRoute.text }}</span>
        </span>
      </div>
      <div class="sLowerHeaderIcons">
        <v-btn
            v-if="searchOn"
            icon
            @click="toggleSearch"
        >
          <v-icon color="black">
          mdi mdi-magnify
          </v-icon>
        </v-btn>
        <v-btn
            v-else
            icon
            @click="toggleSearch"
        >
          <v-icon>
          mdi mdi-magnify
          </v-icon>
        </v-btn>
        <v-tooltip bottom
         v-for="(button, index) in headerIcons"
         :key="index"
         color="#ffffff"
         content-class="white-tooltip"

        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="button.action"
            >
              <v-icon size="20">
                {{ button.icon }}
              </v-icon>
            </v-btn>
          </template>
          <div class="sTooltipTitle">
            {{ button.title }}
          </div>
          <div class="sTooltipSubtitle">
            {{ button.subtitle }}
          </div>
        </v-tooltip>
      </div>
     </div>
      <div v-if="searchOn">
        <v-autocomplete
          v-if="hasSearch"
          v-model="search"
          class="sSearch"
          height="50"
          :items="articleList ? articleList : []"
          item-text="title"
          item-value="id"
          hide-details
          flat
          label="Search help"
          :menu-props="{
            maxWidth: 360
          }"
          solo
        />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import OnehelpMenuHeader from "@/components/Onehelp/Menu/Header";

export default {
  name: "OnehelpMenuSimple",
  components: {OnehelpMenuHeader},
  props: {
    articleList: {
      type: Array,
      default: null
    },
    backRoute: {
      type: Object,
      default: null
    },
    containerStyle: {
      type: Object,
      default: () => {}
    },
    hasSearch: {
      type: Boolean,
      default: false
    },
    headerIcons: {
      type: Array,
      default: null
    },
    helpMenu: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    styleHeight: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      open: true,
      search: '',
      searchOn: false,
    }
  },
  watch: {
    helpMenu(opening) {
      if(opening) this.open = opening
    },
    search(itemId) {
      this.$emit('routeChange', { name: 'article', id: itemId })
    }
  },
  methods: {
    toggleSearch(){
      this.searchOn = !this.searchOn;
    }
  }
}
</script>

<style scoped lang="scss">

.sContainer {
  max-height: 868px;
  min-height: 468px;

  @media (max-height: 840px) {
    max-height: 468px;
    min-height: 268px;
  }
}

.sClosedContainer{
  width: 420px;
  height: 72px;
  background-color: white;
}

.sOpenContainer{
  max-height: 852px;
  width: 420px;
  background-color: white;

  .sSearch {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.sLowerHeader{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

}
.sBackRoute{
  display: flex;
  align-items: center;
  padding: 7px 20px 7px 20px;
  color: #5B5B5B;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
}
.sLowerHeaderIcons{
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 19px 7px 34px;

}

.sTooltipTitle {
  font-weight: 500;
  font-size: 14px;
  color: black;
}
.sTooltipSubtitle {
  font-weight: 400;
  font-size: 14px;
  color: black;
}

.sMenuFooter{
  padding: 10px 0 0;
}


</style>