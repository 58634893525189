<template>
  <span class="saveContainer">

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          elevation="8"
          color="red"
          :loading="loading"
          v-bind="attrs"
          v-on="on"
        >
          <i class="fa fa-save" />&nbsp;Save
        </v-btn>
      </template>
      <v-list
        dense
      >
        <v-list-item
          v-for="(item, index) in menuOptions"
          :key="index"
          class="save-item"
        >
          <v-list-item-title @click="menuChoice(item)">{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <br>
      <v-text-field
        v-model="saveAsName"
        label="New Report Name"
        @keydown.enter="saveAs()"
      />
      <div>
        <v-btn
          elevation="8"
          color="red"
          large
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          elevation="8"
          color="primary"
          style="margin-left:30px"
          :disabled="!saveAsName || saveAsName.trim() === ''"
          large
          @click="saveAs()"
        >
          Save
        </v-btn>
      </div>
    </v-dialog>
  </span>
</template>

<script>
import { mapMutations } from "vuex";
import _ from "lodash";
import Api from "../../../../connect/api";

export default {
  /* eslint-disable vue/require-default-prop */
  props: {
    slides: Array,
    slideOrder: Array,
    excludeSlides: Array
  },
  /* eslint-enable vue/require-default-prop */
  data: function() {
    return {
      loading: false,
      dialog: false,
      saveAsModal: "saveAsModal",
      saveAsName: "",
      menuOptions: [
        {
          id: "save",
          label: "Save Report"
        },
        {
          id: "saveAs",
          label: "Save As New Report"
        }
      ]
    };
  },
  mounted: function() {
    this.saveAsName = this.$store.state.report.name
      ? this.$store.state.report.name
      : "";
  },
  methods: {
    ...mapMutations("report", ["setReportId", "setName"]),
    menuChoice(choice) {
      switch (choice.id) {
        case "save":
          this.save();
          break;
        case "saveAs":
          this.dialog=true;
          break;
      }
    },
    save: function() {
      let data = this.saveData();
      Api.post(
        "report/set",
        data,
        response => {
          this.loading = false;
          this.setReportId(response.reportId);
          window.history.replaceState(
            "object or string",
            "Save As Report",
            "/reports/display/" + response.reportId
          );
          this.$emit("message", { message: "Report Saved" });
        },
        error => {
          this.loading = false;
          this.$emit("message", {
            message: "Failed to save report due to error: " + error
          });
        }
      );
    },
    saveAs: function() {
      let data = this.saveData();
      data.reportId = null;
      data.name = this.saveAsName;
      this.dialog = false
      Api.post(
        "report/set",
        data,
        response => {
          this.loading = false;
          this.setReportId(response.reportId);
          window.history.replaceState(
            "object or string",
            "Save As Report",
            "/reports/display/" + response.reportId
          );
          this.setName(this.saveAsName);
          this.$emit("message", {
            message: "Report Saved As " + this.saveAsName
          });
        },
        error => {
          this.loading = false;
          this.$emit("message", {
            message: "Failed to save report due to error: " + error
          });
        }
      );
    },
    saveData: function() {
      this.loading = true;

      let reportOptions = {
        campaignAll: this.$store.state.report.campaignAll,
        placementAll: this.$store.state.report.placementAll,
        typeDate: this.$store.state.report.typeDate,
        benchmarkTypeDate: this.$store.state.report.benchmarkTypeDate,
        benchmarkImpressions: this.$store.state.report.benchmarkImpressions,
        benchmarkCtr: this.$store.state.report.benchmarkCtr,
        mode: this.$store.state.report.mode,
        reportImpressionsBy: this.$store.state.report.reportImpressionsBy
      };

      if (this.$store.state.report.template !== null)
        reportOptions.template = this.$store.state.report.template.id;

      if (reportOptions.typeDate === "cr") {
        reportOptions.customStartDate = this.$store.state.report.startDate;
        reportOptions.customEndDate = this.$store.state.report.endDate;
      }
      if (reportOptions.benchmarkTypeDate === "cr") {
        reportOptions.benchmarkCustomStartDate = this.$store.state.report.benchmarkStartDate;
        reportOptions.benchmarkCustomEndDate = this.$store.state.report.benchmarkEndDate;
      }
      if (reportOptions.campaignAll === false)
        reportOptions.campaignIds = this.$store.state.report.campaignIds.split(
          ","
        );
      if (reportOptions.placementAll === false)
        reportOptions.placementIds = this.$store.state.report.placementIds.split(
          ","
        );

      let slides = this.slides;
      let $this = this;
      for (let i = 0; i < this.slideOrder.length; i++) {
        _.find(slides, { id: $this.slideOrder[i] }).count = i + 1;
      }

      for (let i = 0; i < slides.length; i++) {
        if ($this.excludeSlides.indexOf(slides[i].id) > -1)
          slides[i].exclude = true;
      }

      return {
        reportId: this.$store.state.report.reportId,
        name: this.$store.state.report.name,
        advId: this.$store.state.report.advertiserId,
        options: reportOptions,
        slides: this.slides
      };
    }
  }
};
</script>

<style scoped lang="sass">
.saveContainer
    position: relative
    text-align: left
    a
        position: absolute
        bottom: 0
        left: 0
::v-deep .v-dialog.v-dialog--active
      background-color: white!important
      padding: 20px 35px!important
::v-deep .save-item:hover
  background-color: #F44336
  cursor: pointer
</style>
