<template>
  <div :class="{ mainContainer: true, sRow: true, rowEdit: showEdit }">
    <div class="slideCol slideMenu">
      <div class="scroll">
        <h4 title="Report setup and advanced settings">
          <i
            v-if="access.createReport"
            class="fas fa-edit"
            @click="editReport()"
          />
          {{ name }}
        </h4>
        <div class="pages">
          <report-display-builder-page
            v-for="(slideId, index) in listSlideTemplateOrder"
            :id="slideId"
            :key="'t' + index"
            :index="index"
            :show-add="showAdd"
            :current="current"
            :editing="editing"
            :title="getSlide(slideId).title"
            :exclude="excludedSlide(slideId)"
            :template="true"
            :access="access"
            @view="view(slideId)"
            @edit="edit(slideId)"
            @excludeTemplateToggle="excludeTemplateToggle(slideId)"
          />
          <draggable v-model="listSlideOrder">
            <report-display-builder-page
              v-for="(slideId, index) in listSlideOrder"
              :id="slideId"
              :key="'s' + index"
              :index="index + listSlideTemplateOrder.length"
              :show-add="showAdd"
              :current="current"
              :editing="editing"
              :title="getSlide(slideId).title"
              :exclude="excludedSlide(slideId)"
              :template="false"
              :access="access"
              @view="view(slideId)"
              @edit="edit(slideId)"
              @excludeTemplateToggle="excludeTemplateToggle(listSlide.id)"
            />
          </draggable>
          <report-display-builder-page
            v-for="(slideId, index) in listSlideTemplateEndOrder"
            :id="slideId"
            :key="'e' + index"
            :index="
              index + listSlideOrder.length + listSlideTemplateOrder.length
            "
            :show-add="showAdd"
            :current="current"
            :editing="editing"
            :title="getSlide(slideId).title"
            :exclude="excludedSlide(slideId)"
            :template="true"
            :access="access"
            @view="view(slideId)"
            @edit="edit(slideId)"
            @excludeTemplateToggle="excludeTemplateToggle(slideId)"
          />
          <report-display-builder-page
            v-if="access.createReport"
            id="add"
            :show-add="showAdd"
            :index="-1"
            title="Add new slide"
            :add-mode="true"
            :access="access"
            @add="add()"
          />
        </div>
        <feature
          name="reportDownload"
          variant="v1"
          :data="featureData"
        >
          <div class="pageButtons">
            <report-display-builder-save
              v-if="access.createReport"
              :slides="listSlide"
              :slide-order="listSlideOrder"
              :exclude-slides="excludeSlides"
              @message="$emit('message', $event)"
            />

            <v-btn
              elevation="8"
              :loading="previewLoading"
              title="Download a PDF of the selected page"
              @click="preview('generate')"
            >
              <i class="fas fa-file-download" />
              &nbsp;Preview
            </v-btn>

            <v-btn
              color="accent"
              elevation="8"
              :loading="downloadLoading"
              title="Download a PDF of the whole report"
              @click="download('generate')"
            >
              <i class="fa fa-download" />&nbsp;Download
            </v-btn>
          </div>
        </feature>
        <feature
          name="reportDownload"
          variant="v2"
          :data="featureData"
        >
          <div class="pageButtons">
            <report-display-builder-save
              v-if="access.createReport"
              :slides="listSlide"
              :slide-order="listSlideOrder"
              :exclude-slides="excludeSlides"
              @message="$emit('message', $event)"
            />
            <v-btn
              elevation="8"
              :loading="previewLoading"
              title="Download a PDF of the selected page"
              @click="preview('download')"
            >
              <i class="fas fa-file-download" />
              &nbsp;Preview
            </v-btn>

            <v-btn
              color="accent"
              elevation="8"
              :loading="downloadLoading"
              title="Download a PDF of the whole report"
              @click="download('download')"
            >
              <i class="fa fa-download" />
              &nbsp;Download
            </v-btn>
          </div>
        </feature>
      </div>
    </div>
    <div v-if="showAdd">
      <report-display-builder-new
        @newSlide="newSlide($event)"
      />
    </div>
    <div
      v-if="showEdit"
      class="editingSlide"
    >
      <report-display-builder-edit
        :slide="editingSlide"
        :excluded="excludedSlide(editingSlide.id)"
        @editSlide="editSlide($event)"
        @refresh="refreshSlide()"
        @deleteSlide="deleteSlide($event)"
        @toggleSlide="excludeTemplateToggle($event)"
      />
    </div>
    <div :class="slideViewClass">
      <div class="slideViewScroll">
        <div class="slideBox">
          <div
            v-if="advertiserClientData"
            class="slideBoxInner"
          >
            <template v-for="(slide, slideIndex) in listSlide">
              <div :key="slide.id + '-' + slideIndex">
                <report-display-builder-slide
                  v-if="slideShown(slide.id)"
                  v-show="slide.id === current"
                  :ref="'SLIDE-' + slide.id"
                  :slide="slide"
                  :data="slideData()"
                />
              </div>
            </template>
          </div>
          <loader-fullpage
            v-else
            message="Waiting for advertiser data..."
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import { templateList } from "../../../data/report/templates-v1";
import actionLog from "../../../logger/action";
import helper from "../../../helper/report/builder";
import ReportDisplayBuilderSlide from "./Builder/Slide";
import ReportDisplayBuilderPage from "./Builder/Page";

export default {
  name: "ReportDisplayBuilder",
  components: {
    ReportDisplayBuilderPage,
    ReportDisplayBuilderSlide
  },
  /* eslint-disable-next-line vue/require-prop-types */
  props: ["slides", "options", "access"],
  data: function() {
    return {
      current: "",
      downloadLoading: false,
      editing: false,
      excludeSlides: [],
      listSlide: [],
      listSlideOrder: [],
      listSlideTemplateOrder: [],
      listSlideTemplateEndOrder: [],
      loadTime: Math.round(new Date().getTime() / 1000),
      previewLoading: false,
      saving: false,
      shown: [],
      showAdd: false,
      showEdit: false
    };
  },
  computed: {
    ...mapState("report", [
      "name",
      "advertiserClientData",
      "chartList",
      "reportImpressionsBy",
      "variousData",
      "template",
      "setupComplete",
      "reportId"
    ]),
    ...mapGetters("report", ["clientLogoUrl", "clientName", "getMode"]),
    ...mapGetters("di", ["featureData"]),
    editingSlide: function() {
      if (!this.editing) return false;
      return _.find(this.listSlide, { id: this.editing });
    },
    slideViewClass: function() {
      return {
        invisible: this.showAdd,
        "col col4": this.showEdit,
        "col col2": !this.showAdd && !this.showEdit,
        "slideCol slideView": true
      };
    }
  },
  watch: {
    setupComplete() {
      if (this.setupComplete === true && this.current === "")
        this.view(
          this.listSlideTemplateOrder.length > 0
            ? this.listSlideTemplateOrder[0]
            : this.listSlideOrder[0]
        );
    },
    template: {
      handler: "templateChange"
    },
    getMode: {
      handler: "templateChange"
    }
  },
  mounted: function() {
    let $this = this;

    if (this.options && typeof this.options.excludeTemplateFromPdf === "object")
      this.excludeSlides = this.options.excludeTemplateFromPdf;

    if (this.slides && this.slides.length > 0) {
      _.forEach(_.sortBy(this.slides, "count"), function(slide) {
        if (slide.template === true) return true;
        slide.template = false;
        if (slide.options.excludeFromPdf === "yes")
          $this.excludeSlides.push(slide.id);
        $this.listSlide.push(slide);
        $this.listSlideOrder.push(slide.id);
      });
    }

    this.$nextTick(() => {
      if (!this.reportId || this.getMode !== "presentation") {
        this.templateChange();
      }
      this.view();
      this.$emit("reportLoaded");
    });
  },
  methods: {
    ...mapActions("report", ["markSetupComplete"]),
    ...mapActions(["subscribe"]),
    add: function() {
      this.editing = false;
      this.showEdit = false;
      this.showAdd = !this.showAdd;
    },
    deleteSlide: function(slide) {
      this.getSlide(slide.id).show = "no";
      this.showEdit = false;
      this.editing = false;
      this.listSlideOrder = _.remove(this.listSlideOrder, order => {
        return order !== slide.id;
      });
      this.view();
    },
    download: function(type) {
      if (this.listSlide.length < 1) {
        this.$notify({
          type: "warning",
          title: "No slides",
          text: "At least one slide needs to be added to download the report"
        });
        return;
      }
      let $this = this;
      _.forEach(_.sortBy(this.listSlide, "count"), function(slide) {
        if (!$this.slideShown(slide.id) && !$this.excludedSlide(slide.id))
          $this.shown.push(slide.id);
      });
      let html = () => {
        let code = "";
        _.forEach(
          [
            ...this.listSlideTemplateOrder,
            ...this.listSlideOrder,
            ...this.listSlideTemplateEndOrder
          ],
          function(slideId) {
            if (!$this.excludedSlide(slideId))
              code += $this.$refs["SLIDE-" + slideId][0].$el.innerHTML;
          }
        );
        return code;
      };
      this.downloadLoading = true;
      helper[type === "download" ? "download" : "generate"](
        this.name,
        html,
        this.chartList,
        document
      )
        .then(cleanName => {
          this.downloadLoading = false;
          if (type === "download") {
            this.subscribe({ queue: "download", data: { file: cleanName } });
            this.$notify({
              type: "success",
              title: "Waiting for Report",
              text: "Report sent for PDF generation"
            });
          }
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: "Failed Download Request",
            text: "Unable to request your report at this time"
          });
          this.downloadLoading = false;
        });
    },
    edit: function(id) {
      if (id !== this.current) this.view(id);
      this.editing = id;
      this.showAdd = false;
      this.showEdit = true;
    },
    editReport() {
      this.current = "";
      this.shown = [];
      this.markSetupComplete();
    },
    editSlide: function(slide) {
      let currentSlide = this.getSlide(slide.id);
      if (slide.options !== null) currentSlide.options = slide.options;
      currentSlide.title = slide.title;
      let id = helper.generateSlideId();
      currentSlide.id = id;
      this.$set(this.listSlideOrder, this.listSlideOrder.indexOf(slide.id), id);
      this.view(id);
      this.showEdit = false;
      this.editing = false;
    },
    excludeTemplateToggle(id) {
      if (this.excludeSlides.indexOf(id) > -1) {
        this.excludeSlides.splice(this.excludeSlides.indexOf(id), 1);
      } else {
        this.excludeSlides.push(id);
      }
    },
    excludedSlide(id) {
      return this.excludeSlides.indexOf(id) > -1;
    },
    getSlide: function(id) {
      return _.find(this.listSlide, { id: id });
    },
    newSlide: function(slide) {
      slide.id = helper.generateSlideId();
      slide.show = "yes";
      slide.template = false;
      this.listSlide.push(slide);
      this.listSlideOrder.push(slide.id);
      this.view(slide.id);
      this.showAdd = false;
      actionLog("report-slide", {
        component: slide.component,
        title: slide.title,
        options: slide.options
      });
    },
    preview: function(type) {
      if (!this.current) {
        this.$notify({
          type: "warning",
          title: "No slide to preview",
          text: "Please select a slide to download a preview of"
        });
        return;
      }
      this.previewLoading = true;
      helper[type === "download" ? "download" : "generate"](
        this.name + " Preview",
        () => {
          return this.$refs["SLIDE-" + this.current][0].$el.innerHTML;
        },
        this.chartList,
        document
      )
        .then(cleanName => {
          this.previewLoading = false;
          if (type === "download") {
            this.subscribe({ queue: "download", data: { file: cleanName } });
            this.$notify({
              type: "success",
              title: "Waiting for Preview",
              text: "Preview sent for PDF generation"
            });
          }
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: "Failed Preview Request",
            text: "Unable to request your preview at this time"
          });
          this.previewLoading = false;
        });
    },
    refreshSlide() {
      if (this.editing) {
        let shown = this.shown;
        let index = shown.indexOf(this.editing);
        if (index > -1) shown.splice(index, 1);
        this.shown = shown;
        this.$nextTick(() => {
          this.view(this.editing);
        });
      }
    },
    slideData: function() {
      return {
        ...this.variousData,
        advertiserName: this.clientName,
        clientLogoUrl: this.clientLogoUrl
      };
    },
    slideShown: function(slideId) {
      return this.shown.indexOf(slideId) > -1 || slideId === "AAA-111";
    },
    templateChange: function() {
      if (
        Math.round(new Date().getTime() / 1000) - this.loadTime < 10 &&
        this.reportId &&
        this.getMode === "presentation" &&
        this.slides.length > 0
      )
        return;

      let $this = this;

      if (this.getMode !== "presentation") {
        let removedIds = _.map(
          _.remove(this.listSlide, { template: true }),
          "id"
        );
        _.remove(this.listSlideOrder, value => {
          return removedIds.indexOf(value) > -1;
        });
      }
      this.listSlideTemplateOrder = [];
      this.listSlideTemplateEndOrder = [];

      _.remove(this.listSlide, slide => {
        return (
          this.listSlideTemplateOrder.indexOf(slide.id) < 0 &&
          this.listSlideTemplateEndOrder.indexOf(slide.id) < 0 &&
          this.listSlideOrder.indexOf(slide.id) < 0
        );
      });

      if (this.template === null) return;
      let template = _.find(templateList, { id: this.template.id });

      let listSlideOrderStart = [];
      let listSlideOrderEnd = [];
      if (
        typeof template !== "undefined" &&
        template.slides &&
        template.slides.length > 0
      ) {
        template.slides.forEach(function(slide) {
          slide.template = true;
          slide.show = "yes";
          $this.listSlide.push(slide);
          if ($this.getMode === "presentation" && slide.position === "end") {
            listSlideOrderEnd.push(slide.id);
          } else if ($this.getMode === "presentation") {
            listSlideOrderStart.push(slide.id);
          } else if (slide.position === "end") {
            $this.listSlideTemplateEndOrder.push(slide.id);
          } else {
            $this.listSlideTemplateOrder.push(slide.id);
          }
        });
      }
      if (listSlideOrderStart.length > 0 || listSlideOrderEnd.length > 0) {
        this.listSlideOrder = [
          ...listSlideOrderStart,
          ...this.listSlideOrder,
          ...listSlideOrderEnd
        ];
      }

      if (this.listSlide.length === 0) {
        this.listSlide.push(helper.slideCover);
        this.listSlideOrder.push(helper.slideCover.id);
      }
    },
    view: function(id = null) {
      if (id === null)
        id =
          this.listSlideTemplateOrder.length > 0
            ? this.listSlideTemplateOrder[0]
            : this.listSlideOrder.length > 0
            ? this.listSlideOrder[0]
            : null;
      if (!this.slideShown(id)) {
        actionLog("reportSlideShown", this.getSlide(id));
        this.shown.push(id);
      }
      this.editing = false;
      this.showAdd = false;
      this.showEdit = false;
      this.current = id;
    }
  }
};
</script>

<style type="text/css" lang="sass">
.pageContent
    font-size: 0.9em
    height: 20.55cm
    width: 29.5cm
    .showOnPdfOnly
        display: none !important
</style>

<style scoped lang="sass">
@import "../../../scss/fundamental-variables.scss"

.sRow
  display: grid
  grid-template-columns: 25% auto
  &.rowEdit
    grid-template-columns: 25% 25% auto

@media only screen and (max-width: 1400px)
  .sRow
    grid-template-columns: 33% auto
    &.rowEdit
      grid-template-columns: 33% 33% auto

@media only screen and (max-width: 1000px)
  .sRow
    grid-template-columns: 100%
    &.rowEdit
      grid-template-columns: 100%
    .sideCol, .slideMenu, .scroll
      min-height: auto
      height: auto !important
    .scroll
      margin-bottom: 20px

.invisible
    display: none
div.mainContainer
    margin: 0
    background-color: $colorLight
div.slideCol
    min-height: calc(100vh - 101px)
div.slideMenu
  position: relative
  height: calc(100vh - 101px)
  padding: 0
  background: $colorLightest
  div.scroll
    box-shadow: inset -7px 0 5px -7px rgba(0,0,0,0.2)
    overflow-y: auto
    height: calc(100vh - 101px)
    width: 100% !important
    h4
      margin: 0 0 10px
      padding-left: 10px
      height: 36px
      font-size: 1.3em
      padding-top: 8px
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap
      width: 96%
      i
        cursor: pointer
div.slideView
    position: relative
    height: calc(100vh - 101px)
    background-color: $colorLight
div.slideViewScroll
    height: calc(100vh - 121px)
div.slideBox
    max-width: calc(29.5cm + 5px)
    max-height: 20.55cm
    height: calc(100vh - 143px)
    width: 95%
    position: relative
    margin: 20px auto
    background: white
    box-shadow: 0 10px 5px -10px #777
    border: solid 1px #e0e0e0
div.slideBoxInner
    max-height: 20.55cm
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    overflow: auto
    &::-webkit-scrollbar-track
      background-color: #FFF
    &::-webkit-scrollbar
      width: 5px
      height: 5px
      background-color: #FFF
    &::-webkit-scrollbar-thumb
      background-color: $colorImpactDark
div.pages
    width: 100%
    border-top: solid 1px $colorLight
div.pageButtons
    clear: both
    padding-top: 20px
    text-align: center
    button
      margin-left: 10px
div.editingSlide
    background-color: $colorMediumLight
</style>
