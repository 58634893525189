import { CoreTroubleshooterAggregatorData } from "./data";
import { CoreTroubleshooterAggregatorRules } from "./rules";

export class CoreTroubleshooterAggregatorFilter {
  constructor(filters, access) {
    this.filters = filters;
    this.access = access;
    this.data = new CoreTroubleshooterAggregatorData(filters);
  }
  async fetchData() {
    await this.data.fetchData(this.access);
  }
  rules() {
    return new CoreTroubleshooterAggregatorRules(this.data, this.access);
  }
}
