<template>
  <component
    :is="'report-slide-' + slide.component"
    :key="slide.id"
    :start-date="startDate"
    :end-date="endDate"
    :data="data"
    :report-name="name"
    :adv-id="advertiserId"
    :campaign-list="campaignIds"
    :placement-list="placementIds"
    :b-date-from="benchmarkStartDate"
    :b-date-to="benchmarkEndDate"
    :bench-imp-limit="benchmarkImpressions"
    :bench-ctr-limit="benchmarkCtr"
    :b-client-type="advertiserClientData.clientType"
    :options="slideOptions()"
  />
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "ReportDisplayBuilderSlide",
  components: {
    /* eslint-disable vue/no-unused-components */
    ReportPartBase: () =>
      import(/* webpackChunkName: "slide" */ "../../Part/Base.vue"),
    ReportSlideCover: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Cover.vue"),
    ReportSlideGlossary: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Glossary.vue"),
    ReportSlideThankyou: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Thankyou.vue"),
    ReportSlideOverview: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Overview.vue"),
    ReportSlideAdwordsCampaign: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Adwords/Campaign.vue"
      ),
    ReportSlideAdwordsKeyword: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Adwords/Keyword.vue"),
    ReportSlideAdwordsPeriod: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Adwords/Period.vue"),
    ReportSlideAdwordsTable: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Adwords/Table.vue"),
    ReportSlideAnalyticsSiteImpact: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Analytics/Site/Impact.vue"
      ),
    ReportSlideCampaignInsight: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Campaign/Insight.vue"
      ),
    ReportSlideCampaignImpressionDelivery: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Campaign/Impression/Delivery.vue"
      ),
    ReportSlideCampaignImpressionDeliveryBreakdown: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Campaign/Impression/Delivery/Breakdown.vue"
      ),
    ReportSlideCampaignOverview: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Campaign/Overview.vue"
      ),
    ReportSlideCampaignPeriod: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Campaign/Period.vue"),
    ReportSlideCampaignStatus: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Campaign/Status.vue"),
    ReportSlideCampaignStatusCampaign: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Campaign/Status/Campaign.vue"
      ),
    ReportSlideCampaignStatusDbm: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Campaign/Status/Dbm.vue"
      ),
    ReportSlideCampaignStatusDbmCampaign: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Campaign/Status/Dbm/Campaign.vue"
      ),
    ReportSlideCampaignTotal: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Campaign/Total.vue"),
    ReportSlideCampaignViewability: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Campaign/Viewability.vue"
      ),
    ReportSlideCountryComparison: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Country/Comparison.vue"
      ),
    ReportSlideCountryTop: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Country/Top.vue"),
    ReportSlideCountryViewability: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Country/Viewability.vue"
      ),
    ReportSlideCreativeComparison: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Creative/Comparison.vue"
      ),
    ReportSlideCreativeCountry: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Creative/Country.vue"
      ),
    ReportSlideCreativeDynamicCreative: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Creative/Dynamic/Creative.vue"
      ),
    ReportSlideCreativeDynamicGraph: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Creative/Dynamic/Graph.vue"
      ),
    ReportSlideCreativeDynamicHeadline: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Creative/Dynamic/Headline.vue"
      ),
    ReportSlideCreativeDynamicTable: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Creative/Dynamic/Table.vue"
      ),
    ReportSlideCreativeRegressionComparison: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Creative/Regression/Comparison.vue"
      ),
    ReportSlideCreativeSiteComparison: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Creative/Site/Comparison.vue"
      ),
    ReportSlideCreativePerformance: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Creative/Performance.vue"
      ),
    ReportSlideCreativePerformanceOvertime: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Creative/Performance/Overtime.vue"
      ),
    ReportSlideFloodlightTable: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Floodlight/Table.vue"
      ),
    ReportSlideGaLandingBooking: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Ga/Landing/Booking.vue"
      ),
    ReportSlideGaLandingBookingBounce: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Ga/Landing/Booking/Bounce.vue"
      ),
    ReportSlideGaLandingBookingOpportunity: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Ga/Landing/Booking/Opportunity.vue"
      ),
    ReportSlideGaChannel: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Ga/Channel.vue"),
    ReportSlideGaCountry: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Ga/Country.vue"),
    ReportSlideGaDevice: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Ga/Device.vue"),
    ReportSlideGaGoal: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Ga/Goal.vue"),
    ReportSlideGaSource: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Ga/Source.vue"),
    ReportSlideGaTraffic: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Ga/Traffic.vue"),
    ReportSlideLeadforensicsPageBusiness: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Leadforensics/Page/Business.vue"
      ),
    ReportSlideLeadforensicsPageBusinessTable: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Leadforensics/Page/Business/Table.vue"
      ),
    ReportSlidePlacementTaut: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Placement/Taut.vue"),
    ReportSlidePlacementType: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Placement/Type.vue"),
    ReportSlidePresentationBullet: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Presentation/Bullet.vue"
      ),
    ReportSlidePresentationFull: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Presentation/Full.vue"
      ),
    ReportSlidePresentationGallery: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Presentation/Gallery.vue"
      ),
    ReportSlidePresentationLinkedinCampaign: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Presentation/Linkedin/Campaign.vue"
      ),
    ReportSlidePresentationSiteTop: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Presentation/Site/Top.vue"
      ),
    ReportSlideSearchCampaign: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Search/Campaign.vue"),
    ReportSlideSearchKeyword: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Search/Keyword.vue"),
    ReportSlideSearchPeriod: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Search/Period.vue"),
    ReportSlideSitePeriod: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Site/Period.vue"),
    ReportSlideSiteSpend: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Site/Spend.vue"),
    ReportSlideSiteViewability: () =>
      import(
        /* webpackChunkName: "slide" */ "../../Slide.v1/Site/Viewability.vue"
      ),
    ReportSlideVoteRange: () =>
      import(/* webpackChunkName: "slide" */ "../../Slide.v1/Vote/Range.vue")
  },
  /* eslint-disable-next-line vue/require-prop-types */
  props: ["slide", "data"],
  computed: {
    ...mapState("report", [
      "name",
      "endDate",
      "startDate",
      "advertiserId",
      "campaignIds",
      "placementIds",
      "benchmarkEndDate",
      "benchmarkStartDate",
      "benchmarkImpressions",
      "benchmarkCtr",
      "advertiserClientData",
      "reportImpressionsBy",
      "campaignDetails"
    ]),
    ...mapGetters("report", ["clientUuid"])
  },
  methods: {
    slideOptions() {
      let options = this.slide.options ? this.slide.options : {};
      options.reportImpressionsBy = this.reportImpressionsBy;
      options.general_coverImage = options.cover;
      options.client_name = this.data.advertiserName;
      options.client_logo = this.data.clientLogoUrl;
      options.client_type = this.advertiserClientData.clientType;
      options.dateRange = { start: this.startDate, end: this.endDate };
      options.cm_advertiser = [this.advertiserId];
      options.cm_campaign = this.campaignIds.split(",");
      options.cm_placement = this.placementIds.split(",");
      options.bench_end = this.benchmarkEndDate;
      options.bench_start = this.benchmarkStartDate;
      options.bench_impression = this.benchmarkImpressions;
      options.bench_ctr = this.benchmarkCtr;
      options.cm_impressionType = this.reportImpressionsBy;
      options.general_slideGroup = options.awReportType
        ? options.awReportType
        : options.period
        ? options.period
        : options.group;
      options.general_showTable = options.table;
      options.general_subtitle = options.subTitle
        ? options.subTitle
        : options.title;
      options.general_currency = options.currency;
      options.general_impressionLimit = options.impressionLimit;
      options.general_keyMetric = [
        options.metric1,
        options.metric2,
        options.metric3,
        options.metric4,
        options.metric5
      ];
      options.general_country = options.countries;
      options.aw_campaign = options.campaign;
      options.ga_view = options.gaView;
      options.li_account = options.liAccount;
      options.li_campaign = options.liCampaign;
      options.cm_dynamicProfile = options.dynamic;
      options.cm_dynamicElementFilter = options.filter;
      if (options.dcmCampaign) options.cm_campaign = [options.dcmCampaign];
      options.ga_bounceRateBad = options.bounceRateBad;
      options.ga_bounceRateGood = options.bounceRateGood;
      options.ga_booking = options.gaBooking;
      options.ga_bookingMediaName = options.gaMediaName;
      options.ga_sourceBreakdown = options.gaSourceBreakdown;
      options.ga_source = options.gaSource;
      options.names = {
        cmCampaign: this.campaignDetails
          ? this.campaignDetails.reduce((obj, item) => {
              return {
                ...obj,
                [item.value]: item.label
              };
            }, {})
          : {}
      };
      options.custom_bulletPointsBig = options.bulletBig;
      options.custom_textSize = options.size;
      options.custom_imageLocation = options.location;
      if (options.whatWorked) options.custom_whatWorked = options.whatWorked;
      if (options.toWatch) options.custom_toWatch = options.toWatch;
      if (options.nextSteps) options.custom_nextSteps = options.nextSteps;
      options.custom_imageLayout = options.layout;
      options.custom_imageAlign = options.align;
      options.custom_imageList = options.images;
      options.li_benchmarkCTR = options.benchmarkCTR;
      options.li_benchmarkEngagement = options.benchmarkEngagement;
      options.li_benchmarkOpen = options.benchmarkOpen;
      options.li_account = options.accounts ? options.accounts : [];
      options.li_campaign = options.campaigns ? options.campaigns : [];
      options.li_campaignType = options.type;
      options.vote_question = options.voteQuestion;
      options.client_uuid = this.clientUuid;
      options.dcs_topKeywords = options["additional-top"];
      options.dcs_campaign = options.campaign;

      return options;
    }
  }
};
</script>
