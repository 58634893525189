import Api from "../connect/api";

const getDefaultState = () => {
  return {
    statusOptions: [
      {
        text: "Cannot be fixed",
        value: "cannot-fix",
      },
      {
        text: "Already fixed",
        value: "fixed",
      },
      {
        text: "Working on it",
        value: "wip",
      }
    ],

  };
};

// initial state
const state = {
  statusOptions: [
    {
      text: "Cannot be fixed",
      value: "cannot-fix",
    },
    {
      text: "Already Fixed",
      value: "fixed",
    },
    {
      text: "Working on it",
      value: "wip",
    },
    {
      text: "No status",
      value: "none",
    }
  ],
}

const getters = {
  getStatusOptions() {
    return state.statusOptions;
  }
};

const actions = {
  // call status api
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
