import Api from "../connect/api";
import router from "../router";

const getDefaultState = () => {
  return {
    allowed: null,
    settings: null,
    user: null,
    userTermsAcceptedRecently: false,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  dateFormat: function() {
    if (state.user && state.user.locale === "USA") return "MM/DD/YYYY";
    return "DD/MM/YYYY";
  },
  elDateFormat: function() {
    if (state.user && state.user.locale === "USA") return "MM/dd/yyyy";
    return "dd/MM/yyyy";
  },
  highChartsDateFormat: function() {
    if (state.user && state.user.locale === "USA") return "%m/%d/%Y";
    return "%d/%m/%Y";
  },
  featureData: () => {
    return {
      userId: state.user ? state.user.id : false
    };
  },
  getAllowed: function() {
    return state.allowed;
  },
  getEnv() {
    if (process.env.NODE_ENV === "development") {
      return 'local';
    }

    if (window.location.host.includes("staging")) {
      return "staging"
    }

    if (window.location.host.includes("release")) {
      return "release"
    }

    if (window.location.host.includes("beta")) {
      return "beta"
    }

    if (window.location.host.includes("alpha")) {
      return "alpha"
    }

    return "production";
  },
  getSettings() {
    return state.settings;
  },
  getId: function() {
    return state.user ? state.user.id : false;
  },
  getName: function() {
    if (state.user === null) return "...";

    if (state.user === false) return "Unknown User";

    return state.user.name;
  },
  getUser: function() {
    return state.user;
  },
  getUserHasAcceptedTerms() {
    if(state.user)
      return state.user.hasAcceptedTerms ? state.user.hasAcceptedTerms : false;
  },
  getUserHasOnlyOneClient() {
    return state.user.clientAccess.length === 1;
  },
  getUserTermsAcceptedRecently() {
    return state.userTermsAcceptedRecently;
  },
  isInternal: function() {
    if (state.user === null) return false;

    if (state.user === false) return false;

    const emailParts = state.user.email.split("@");

    return emailParts[1] === "fundamentalmedia.com";
  }
};

const actions = {
  lookupAllowed({ commit }) {
    Api.get(
      "auth/allowed",
      response => {
        commit("setAllowed", response);
      },
      () => {
        commit("setUser", false);
      }
    );
  },
  lookupUser({ commit }) {
    Api.get(
      "auth/current",
      response => {
        if(!response.hasAcceptedTerms)
        {
          // redirect to terms
          if(router.currentRoute.name !== 'terms') {
            router.push({name: 'terms'})
          }
        } else {
          if(router.currentRoute.name === 'terms') {
            router.push({name: 'home'})
          }
        }
        commit("setUser", response);
      },
      () => {
        commit("setUser", false);
      }
    );
  },
  lookUpSettings({commit}) {
    Api.get(
      "setting",
      response => {
        commit("setSettings", response);
      }
    );
  },
  lookupUserNames({ commit }) {
    Api.get(
      "auth/names",
      response => {
        commit("setUserNames", response);
      },
      () => {
        commit("setUserNames", false);
      }
    );
  },
  resetState({ commit }) {
    commit("resetState");
  },
  userTermsAcceptedRecently({commit}) {
    commit("setUserTermsAcceptedRecently");
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setAllowed(state, allowed) {
    state.allowed = allowed;
  },
  setUser(state, user) {
    state.user = user;
  },
  setSettings(state, settings) {
    state.settings = settings;
  },
  setUserTermsAcceptedRecently(state) {
    state.userTermsAcceptedRecently = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
