<template>
  <div>
    <label class="sLabel">
      <input
        :id="id"
        ref="input"
        type="checkbox"
        :value="value"
        :checked="checked"
        @change="toggle"
      >
      <span class="sCheckmark" />
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    id: {
      type: [Number, String],
      default: null
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: [Number, String],
      default: "yes"
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      this.$emit("change", this.$refs.input.checked ? this.value : false);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/fundamental-variables.scss";
/* The sLabel*/
.sLabel {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  letter-spacing: 0.25px;
}

/* Hide the browser's default checkbox */
.sLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.sCheckmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #747474;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.sLabel:hover input ~ .sCheckmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.sLabel input:checked ~ .sCheckmark {
  background-color: $colorBlue;
  border-color: $colorBlue;
}

/* Create the sCheckmark/indicator (hidden when not checked) */
.sCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the sCheckmark when checked */
.sLabel input:checked ~ .sCheckmark:after {
  display: block;
}

/* Style the sCheckmark/indicator */
.sLabel .sCheckmark:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
