<template>
  <div>
    <v-text-field
      v-model="slideName"
      label="Slide Name"
    />
    <component
      :is="'report-option-' + slide.component"
      v-if="slideHasOptions()"
      :load-options="loadOptions"
      @options="options = $event"
    />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "ReportDisplayBuilderOption",
  components: {
    /* eslint-disable vue/no-unused-components */
    ReportOptionAdwordsCampaign: () => import("../../Option/Adwords/Campaign"),
    ReportOptionAdwordsKeyword: () => import("../../Option/Adwords/Keyword"),
    ReportOptionAdwordsPeriod: () => import("../../Option/Adwords/Period"),
    ReportOptionAdwordsTable: () => import("../../Option/Adwords/Table"),
    ReportOptionAnalyticsSiteImpact: () =>
      import("../../Option/Analytics/Site/Impact"),
    ReportOptionCampaignOverview: () =>
      import("../../Option/Campaign/Overview"),
    ReportOptionCampaignPeriod: () => import("../../Option/Campaign/Period"),
    ReportOptionCampaignTotal: () => import("../../Option/Campaign/Total"),
    ReportOptionCreativeComparison: () =>
      import("../../Option/Creative/Comparison"),
    ReportOptionCreativeCountry: () => import("../../Option/Creative/Country"),
    ReportOptionCountryComparison: () =>
      import("../../Option/Country/Comparison"),
    ReportOptionCountryTop: () => import("../../Option/Country/Top"),
    ReportOptionCover: () => import("../../Option/Cover"),
    ReportOptionCreativeDynamicGraph: () =>
      import("../../Option/Creative/Dynamic/Graph"),
    ReportOptionCreativePerformance: () =>
      import("../../Option/Creative/Performance"),
    ReportOptionCreativePerformanceOvertime: () =>
      import("../../Option/Creative/Performance/Overtime"),
    ReportOptionFloodlightTable: () => import("../../Option/Floodlight/Table"),
    ReportOptionGaGoal: () => import("../../Option/Ga/Goal"),
    ReportOptionGaLandingBookingOpportunity: () =>
      import("../../Option/Ga/Landing/Booking/Opportunity"),
    ReportOptionGaLandingBookingBounce: () =>
      import("../../Option/Ga/Landing/Booking/Bounce"),
    ReportOptionGaSource: () => import("../../Option/Ga/Source"),
    ReportOptionGaTraffic: () => import("../../Option/Ga/Traffic"),
    ReportOptionLeadforensicsPageBusiness: () =>
      import("../../Option/Leadforensics/Page/Business"),
    ReportOptionLeadforensicsPageBusinessTable: () =>
      import("../../Option/Leadforensics/Page/Business/Table"),
    ReportOptionPlacementType: () => import("../../Option/Placement/Type"),
    ReportOptionPlacementTaut: () => import("../../Option/Placement/Taut"),
    ReportOptionPresentationBullet: () =>
      import("../../Option/Presentation/Bullet"),
    ReportOptionPresentationGallery: () =>
      import("../../Option/Presentation/Gallery"),
    ReportOptionPresentationLinkedinCampaign: () =>
      import("../../Option/Presentation/Linkedin/Campaign"),
    ReportOptionPresentationSiteTop: () =>
      import("../../Option/Presentation/Site/Top"),
    ReportOptionSearchCampaign: () => import("../../Option/Search/Campaign"),
    ReportOptionSearchKeyword: () => import("../../Option/Search/Keyword"),
    ReportOptionSearchPeriod: () => import("../../Option/Search/Period"),
    ReportOptionSiteSpend: () => import("../../Option/Site/Spend"),
    ReportOptionVoteRange: () => import("../../Option/Vote/Range")
  },
  /* eslint-disable-next-line vue/require-prop-types */
  props: ["slide", "loadOptions"],
  data: function() {
    return {
      options: null,
      slideName: ""
    };
  },
  watch: {
    options() {
      this.send();
    },
    slide: {
      handler: "setSlide",
      immediate: true
    },
    slideName: {
      handler: "send",
      immediate: true
    }
  },
  mounted() {
    this.slideName = this.slide.title;
  },
  methods: {
    send: function() {
      if (this.options !== null && this.slideName !== "") {
        this.$emit("options", {
          title: this.slideName,
          options: this.options
        });
      }
    },
    getSlideOptions() {
      return _.cloneDeep(this.slide.options);
    },
    setSlide() {
      this.options = this.slideHasOptions() ? null : false;
      this.slideName = this.slide.title;
    },
    slideHasOptions: function() {
      return (
        typeof this.$options.components[
          `-report-option-${this.slide.component}`.replace(/-([a-z])/g, g =>
            g[1].toUpperCase()
          )
        ] !== "undefined"
      );
    }
  }
};
</script>
