import Router from "@/router";

window._ = require("lodash");

import Vue from "vue";
window.Vuex = require("vuex");
import router from "./router";

import * as Sentry from "@sentry/vue";
import * as Integrations from "@sentry/integrations";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://9126a43e8cc443faa1098a1ee890a0e4@o137032.ingest.sentry.io/1245636",
    environment: process.env.NODE_ENV === "development" ? 'local' : 'production',
    debug: process.env.ENVIRONMENT !== 'production',
    integrations(integrations) {
      integrations.push(
        new Integrations.Vue({ Vue, attachProps: true, console: true })
      );
      return integrations;
    }
  });
} else {
  Vue.config.errorHandler = (err, vm, info) => {
    Vue.util.warn(`Error in ${info}: "${err.toString()}"`, vm);
  };
}

// Loaders
Vue.component(
  "loader-fullpage",
  require("./components/Loader/Fullpage").default
);
Vue.component("loader-small", require("./components/Loader/Small").default);

// Page Elements
Vue.component("page-header", require("./components/Page/Header").default);

// Report system
Vue.component(
  "report-display-builder",
  require("./components/Report/Display/Builder").default
);
Vue.component(
  "report-display-builder-edit",
  require("./components/Report/Display/Builder/Edit").default
);
Vue.component(
  "report-display-builder-new",
  require("./components/Report/Display/Builder/New").default
);
Vue.component(
  "report-display-builder-save",
  require("./components/Report/Display/Builder/Save").default
);
Vue.component(
  "report-display-setup",
  require("./components/Report/Display/Setup").default
);
Vue.component(
  "report-display-setup-alert",
  require("./components/Report/Display/Setup/Alert").default
);

import { store } from "./vue-store";

import draggable from "vuedraggable";
Vue.component("draggable", draggable);

import VueResource from "vue-resource";
Vue.use(VueResource);

import FlagIcon from "vue-flag-icon";
Vue.use(FlagIcon);

import vSelectPage from "@fundamentalmedia/v-selectpage/dist/v-selectpage";
Vue.use(vSelectPage, { language: "en" });

import Notifications from "vue-notification";
Vue.use(Notifications);

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import actionLog from "./logger/action";
router.afterEach(() => {
  actionLog("page");
});

import feature from "./vue-feature";
Vue.component("feature", feature);

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
Vue.component("VueCropper", VueCropper);

import pluralize from "vue2-filters/src/other/pluralize";
Vue.use(pluralize);

import Fundavue from '@fundamentalmedia/fundavue';
const fundavueOptions = {
  theme: "sPortal",
}
Vue.use(Fundavue, fundavueOptions);

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { fAllowed } from './core/mixin/fAllowed';
import moment from "moment";

var numeral = require("numeral");

/* TODO SWITCH TO GLOBAL PROPERTIES IN VUE */

Vue.prototype.$formatDate = function(date, format) {
    return moment(date).format(format)
}
Vue.prototype.$numeral = function(value, format) {
    return numeral(value).format(format)
}
Vue.prototype.$momentFromNow = function(value) {
    return moment(value).fromNow()
}


Vue.mixin({
  methods: {
    fAllowed: fAllowed
  },
});

new Vue({
  vuetify,
  el: "#app",
  data: {
    components: [],
  },
  router,
  store: store,
  mounted: function() {
    this.components = Vue.options.components;
  },
  render: h => h(App)
});
