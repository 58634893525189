import Vue from "vue";
import _ from "lodash";
import Api from "../connect/api";

const doubleclickPlacementMatches = {
  namespaced: true,
  state: {
    matches: {}
  },
  getters: {
    match: state => (id, planFilter, sortOrder) => {
      if (planFilter.hasOwnProperty("value")) planFilter = planFilter.value;
      if (sortOrder.hasOwnProperty("value")) sortOrder = sortOrder.value;
      if (state.matches[id] !== undefined) {
        let matches = [];

        if (planFilter !== "" && planFilter !== undefined) {
          _.forEach(state.matches[id], function(match) {
            if (match.planUniqueNumber === planFilter) {
              matches.push(match);
            }
          });

          if (matches.length < 1) matches = false;
        } else {
          matches = state.matches[id];
        }

        if (sortOrder === "date") {
          matches = _.orderBy(
            matches,
            ["dateEventEnd", "costToClient"],
            ["desc", "desc"]
          );
        } else {
          matches = _.orderBy(
            matches,
            ["costToClient", "dateEventEnd"],
            ["desc", "desc"]
          );
        }

        return matches;
      } else {
        return null;
      }
    }
  },
  mutations: {
    match(state, data) {
      Vue.set(state.matches, data.siteId, data.bookings);
    }
  },
  actions: {
    loadMatches(context, params) {
      return new Promise(() => {
        let data = {
          advId: params.advId,
          siteId: params.siteId
        };
        Api.post(
          "doubleclick/matching/booking",
          data,
          response => {
            context.commit("match", { ...data, bookings: response });
          },
          () => {
            context.commit("match", {
              ...data,
              bookings: false
            });
          }
        );
      });
    }
  }
};

const doubleclickPlacementMatching = {
  namespaced: true,
  state: {
    advId: null,
    advName: null,
    sites: {}
  },
  getters: {
    advId: state => {
      return state.advId;
    },
    advName: state => {
      return state.advName;
    },
    sites: state => {
      return state.sites;
    }
  },
  mutations: {
    advId(state, advId) {
      state.advId = advId;
    },
    advName(state, advName) {
      state.advName = advName;
    },
    sites(state, sites) {
      state.sites = sites;
    }
  },
  actions: {
    refreshList(context, params) {
      return new Promise(() => {
        context.commit("sites", {});
        Api.post(
          "doubleclick/matching/placement",
          {
            advId: params.advId,
            placementIds: params.placementsIds.split(",")
          },
          response => {
            context.commit("advId", response.advId);
            context.commit("advName", response.advName);
            context.commit("sites", response.sites);
            params.success();
          },
          () => {
            params.success();
          }
        );
      });
    }
  }
};

const doubleclickMediaPlans = {
  namespaced: true,
  state: {
    plans: {}
  },
  getters: {
    plan: state => id => {
      if (state.plans[id] !== undefined) {
        return state.plans[id];
      } else {
        return null;
      }
    }
  },
  mutations: {
    plan(state, data) {
      Vue.set(state.plans, data.siteId, data.plans);
    }
  },
  actions: {
    loadPlans(context, params) {
      return new Promise(() => {
        Api.post(
          "doubleclick/matching/plan",
          {
            advId: params.advId,
            siteId: params.siteId
          },
          response => {
            context.commit("plan", {
              siteId: params.siteId,
              plans: response
            });
          },
          () => {
            context.commit("plan", { siteId: params.siteId, plans: false });
          }
        );
      });
    }
  }
};

const doubleclickPlacement = {
  namespaced: true,
  modules: {
    matching: doubleclickPlacementMatching,
    matches: doubleclickPlacementMatches
  }
};

const doubleclickMedia = {
  namespaced: true,
  modules: {
    plan: doubleclickMediaPlans
  }
};

export default {
  namespaced: true,
  modules: {
    placement: doubleclickPlacement,
    media: doubleclickMedia
  }
};
