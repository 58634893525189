<template>
  <nav>
    <div
      v-for="crumb in breadcrumbs"
      :key="crumb"
      class="breadcrumb"
    >
      <span>{{ crumb }}</span>
      <img src="../../../assets/icons/breadcrumb_arrow_right.svg">
    </div>
    <div
      v-for="(link, linkIndex) in links"
      :key="linkIndex"
      :class="{ link: true, active: isActive(linkIndex, link) }"
      @click="action(linkIndex, link)"
    >
      {{ link.title }}
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    breadcrumb: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      current: null
    };
  },
  computed: {
    breadcrumbs() {
      return this.breadcrumb.breadcrumb || [];
    },
    links() {
      return this.breadcrumb.links || [];
    }
  },
  methods: {
    action(index, link) {
      if (link.route) {
        this.$router.push(link.route);
      } else {
        link.action();
        this.current = index;
      }
    },
    isActive(index, link) {
      return this.current ? index === this.current : link.default;
    }
  }
};
</script>

<style scoped lang="sass">
@import "../../../scss/fundamental-variables.scss"
nav
  height: 64px
  background-color: $colorLightest
  border-bottom: solid 1px $colorMediumLight
  width: 100%
  padding-left: 55px
  .breadcrumb
    height: 65px
    display: inline-flex
    align-items: center
    text-transform: uppercase
    line-height: 64px
    color: $colorMediumDark
    padding-right: 20px
  .link
    display: inline-block
    text-transform: uppercase
    line-height: 64px
    height: 61px
    color: $colorDark
    cursor: pointer
    font-weight: bold
    padding: 0 5px
    margin-right: 30px
    &:hover, &.active
      border-bottom: solid 3px $colorImpact
</style>
