import Api from "../../../connect/api";
export function rawExport(
  startDate,
  endDate,
  advertiserId,
  campaignId,
  placementId,
  benchStartDate,
  benchEndDate,
  benchClientType,
  benchCtrLimit,
  benchImpressionLimit
) {
  return new Promise(function(resolve, reject) {
    Api.post(
      "doubleclick/reporting/booking/full",
      {
        startDate: startDate,
        endDate: endDate,
        advId: advertiserId,
        campaignId: campaignId,
        placementId: placementId
      },
      response => {
        let siteId = [];
        for (let i = 0; i < response.length; i++) {
          siteId.push(response[i].siteId);
        }
        Api.post(
          "doubleclick/benchmark/site",
          {
            startDate: benchStartDate,
            endDate: benchEndDate,
            siteId: siteId,
            clientType: benchClientType,
            ctrLimit: benchCtrLimit,
            impressionLimit: benchImpressionLimit
          },
          response2 => {
            let columns = [
              "bookingUniqueNumber",
              "siteName",
              "placementName",
              "creativeName",
              "size",
              "bookingStartDate",
              "bookingEndDate",
              "opportunityType",
              "placementSpend",
              "percentComplete",
              "impressions",
              "clicks",
              "cpc",
              "ctr",
              "viewability",
              "conversions"
            ];
            let csv = [];
            let headings = columns.slice(0);
            headings.push("benchmark");
            csv.push(headings);
            for (let i = 0; i < response.length; i++) {
              let csvRow = [];
              for (let j = 0; j < columns.length; j++) {
                csvRow.push(
                  typeof response[i][columns[j]] === "undefined"
                    ? ""
                    : response[i][columns[j]]
                );
              }
              csvRow.push(
                typeof response2[response[i].siteId] === "undefined"
                  ? ""
                  : response2[response[i].siteId]
              );
              csv.push(csvRow);
            }
            let csvContent = "data:text/csv;charset=utf-8,";
            for (let i = 0; i < csv.length; i++) {
              csvContent += '"' + csv[i].join('","') + '"\r\n';
            }
            resolve(csvContent);
          },
          () => {
            reject("fail");
          }
        );
      },
      () => {
        reject("fail");
      }
    );
  });
}
