import {store} from "@/vue-store";

export const fAllowed = (access) => {
  const accessList = !Array.isArray(access) ? [access] : access;
  const andHasAccess = [];

  for (const access of accessList) {
    const checkOrAccess = access.split(' || ');
    const orHasAccess = [];

    for (const access of checkOrAccess) {
      const [section, permission] = access.split("/");
      orHasAccess.push(!!(section && permission && store?.getters?.["di/getAllowed"]?.[section]?.[permission]));
    }

    andHasAccess.push(orHasAccess.includes(true));
  }

  return andHasAccess.length && !andHasAccess.includes(false);
}