<template>
  <page-config-button-item
    v-if="!!label"

    button-type="text"
    :icon="icon"
    :label="label"

    :depressed="depressed"
    :dark="dark"
    :light="light"
    :background-color="backgroundColor"
    :text-color="textColor"

    :disabled="disabled"
    :loading="loading"

    :height="height"
    :width="width"

    :x-small="xSmall"
    :small="small"
    :large="large"
    :x-large="xLarge"
    @clicked="$emit('clicked')"
  />
</template>

<script>
import PageConfigButtonItem from "@/components/Page/Config/Button/Item";

export default {
  name: "PageConfigButtonText",
  components: {PageConfigButtonItem},
  props: {
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    },
    depressed: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: [Number, String],
      default: ''
    },
    width: {
      type: [Number, String],
      default: ''
    },
  }
}
</script>

<style scoped>

</style>