import Vue from "vue";
import CoreApi from "../core/connect/api";
import {getLoginRoute} from '@/core/domain';

const countApiCallStart = () => {
  if (window.ajaxAll) window.ajaxAll++;
  else window.ajaxAll = 1;
};

const countApiCallComplete = () => {
  if (window.ajaxComplete) window.ajaxComplete++;
  else window.ajaxComplete = 1;
};

const authFail = status => {
  countApiCallComplete();
  if (status === 403) {
    Vue.notify({
      title: "Permission denied",
      text: "Please contact support for additional access.",
      type: "error"
    });
  } else if (status === 401) {
    window.location = getLoginRoute();
  }
};

const error = (url, payload, callbackFail, callbackError, data) => {
  countApiCallComplete();
  if (callbackError === true && typeof callbackFail === "function") {
    callbackFail(data);
  } else if (typeof callbackError === "function") {
    callbackError(data);
  } else {
    console.warn('API Error', url, payload, data);
    Vue.notify({
      title: "API call error",
      text:
        "Try refreshing your browser and if the problem persists contact support.",
      type: "error"
    });
  }
};

const fail = (url, payload, callbackFail, callbackError, data) => {
  countApiCallComplete();
  if (typeof callbackFail === "function") {
    callbackFail(data);
  } else if (typeof callbackError === "function") {
    callbackError(data);
  } else {
    console.warn('API Fail', url, payload, data);
    Vue.notify({
      title: "API call fail error",
      text:
        "Try refreshing your browser and if the problem persists contact support.",
      type: "error"
    });
  }
};

const success = (callbackSuccess, data) => {
  countApiCallComplete();
  if (typeof callbackSuccess === "function") callbackSuccess(data);
};

export default {
  delete: (url, payload, callbackSuccess, callbackFail, callbackError) => {
    countApiCallStart();
    CoreApi.delete(
      url,
      payload,
      data => success(callbackSuccess, data),
      data => fail(url, payload, callbackFail, callbackError, data),
      data => error(url, payload, callbackFail, callbackError, data),
      status => authFail(status)
    );
  },
  get: (url, callbackSuccess, callbackFail, callbackError) => {
    countApiCallStart();
    CoreApi.get(
      url,
      data => success(callbackSuccess, data),
      data => fail(url, {}, callbackFail, callbackError, data),
      data => error(url, {}, callbackFail, callbackError, data),
      status => authFail(status)
    );
  },
  post: (url, payload, callbackSuccess, callbackFail, callbackError) => {
    countApiCallStart();
    CoreApi.post(
      url,
      payload,
      data => success(callbackSuccess, data),
      data => fail(url, payload, callbackFail, callbackError, data),
      data => error(url, payload, callbackFail, callbackError, data),
      status => authFail(status)
    );
  },
  put: (url, payload, callbackSuccess, callbackFail, callbackError) => {
    countApiCallStart();
    CoreApi.put(
      url,
      payload,
      data => success(callbackSuccess, data),
      data => fail(url, payload, callbackFail, callbackError, data),
      data => error(url, payload, callbackFail, callbackError, data),
      status => authFail(status)
    );
  },
  upload: (url, payload, callbackSuccess, callbackFail, callbackError) => {
    countApiCallStart();
    CoreApi.upload(
      url,
      payload,
      data => success(callbackSuccess, data),
      data => fail(url, payload, callbackFail, callbackError, data),
      data => error(url, payload, callbackFail, callbackError, data),
      status => authFail(status)
    );
  },
};
