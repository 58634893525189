const templateList = [
  {
    code: "standard",
    id: "T0001",
    name: "Standard Template",
    description:
      "A selection of the main DoubleClick slides to use as a default",
    slides: [
      {
        id: "TS0001",
        component: "cover",
        title: "Cover Page",
        options: {
          general_coverImage:
            "https://gcs.fundamentalintelligence.com/di/Cover_new.png",
        },
      },
      {
        id: "TS0002",
        component: "campaign-total",
        title: "Campaign Overview",
      },
      {
        id: "TS0012",
        component: "campaign-overview",
        title: "Campaign Overview NEW",
      },
      {
        id: "TS0003",
        component: "campaign-total-comparison",
        title: "Campaign Comparison AA",
      },
      {
        id: "TS0004",
        component: "campaign-status",
        title: "Campaign Status",
      },
      {
        id: "TS0005",
        component: "campaign-status-dbm-campaign",
        title: "Campaigns FMX Status",
      },
      {
        id: "TS0006",
        component: "placement-type",
        title: "Placements by Size",
        options: {
          general_slidegroup: "size",
        },
      },
      {
        id: "TS0007",
        component: "campaign-impression-delivery",
        title: "All Time Delivery By Site",
      },
      {
        id: "TS0008",
        component: "creative-performance",
        title: "Creative Performance",
        options: {
          general_slidegroup: "site",
        },
      },
      {
        id: "TS0009",
        component: "creative-site-comparison",
        title: "Creative Site Comparison",
      },
      {
        id: "TS00010",
        component: "creative-comparison",
        title: "Creative Comparison",
      },
      {
        id: "TS0011",
        component: "thankyou",
        title: "Thank You",
        position: "end",
      },
    ],
  },
  {
    code: "blank",
    id: "T0002",
    name: "Blank Template",
    description: "A blank canvas on which to craft a report or presentation",
    slides: [],
  },
];

module.exports = { templateList };
