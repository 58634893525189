const slideList = [
  {
    category: "Analytics",
    slides: [
      {
        component: "ga-channel",
        title: "Google Analytics Channels",
        description: "Show channel pie chart for sessions and bounce rate"
      },
      {
        component: "ga-country",
        title: "Google Analytics Countries",
        description: "Show country map for GA sessions"
      },
      {
        component: "ga-device",
        title: "Google Analytics Devices",
        description: "Show device pie chart for sessions and bounce rate"
      },
      {
        component: "ga-goal",
        title: "Google Analytics Goals",
        description: "Show source, medium and content table for GA goals"
      },
      {
        component: "ga-landing-booking",
        title: "Google Analytics Landing Page",
        description: "Show Google Analytics bounce rate by landing page"
      },
      {
        component: "ga-landing-booking-bounce",
        title: "Google Analytics Landing Page Booking Bounce Rate",
        description:
          "Show Google Analytics landing pages by booking bounce rate"
      },
      {
        component: "ga-landing-booking-opportunity",
        title: "Google Analytics Landing Page by Opportunity",
        description:
          "Show Google Analytics landing pages by booking opportunity"
      },
      {
        component: "ga-traffic",
        title: "Google Analytics Traffic",
        description: "Show traffic over time and general metrics for GA"
      },
      {
        component: "ga-source",
        title: "Google Analytics Source",
        description: "Show source, medium and content table for GA"
      },
      {
        component: "leadforensics-page-business",
        title: "Firmographic Business",
        description: "Breakdown of advert views by business"
      },
      {
        component: "leadforensics-page-business-table",
        title: "Firmographic Business Table",
        description: "Full table of advert views by business"
      },
      {
        component: "analytics-site-impact",
        title: "Site Impact",
        description: "See the impact of spend on site users"
      }
    ]
  },
  {
    category: "Basics",
    slides: [
      {
        component: "cover",
        title: "Cover Page",
        description: "First page for all reports"
      },
      {
        component: "glossary",
        title: "Glossary of terms",
        description: "Explanation of technical terms used in the report"
      },
      {
        component: "thankyou",
        title: "Thank You",
        description: "Last page for all reports"
      }
    ]
  },
  {
    category: "Campaign Summary",
    slides: [
      {
        component: "campaign-period",
        title: "Campaign Over Time",
        description: "Impressions, CTR, viewability and clicks by campaign"
      },
      {
        component: "campaign-status",
        title: "Campaign Status",
        description:
          "Impressions, clicks and CTRs by site for all selected campaigns"
      },
      {
        component: "campaign-total",
        title: "Campaign Overview",
        description: "Snapshot of topline data"
      },
      {
        component: "campaign-status-dbm-campaign",
        title: "Campaigns FMX Status",
        description: "FMX impressions, clicks and CTRs by site"
      },
      {
        component: "campaign-status-campaign",
        title: "Campaigns Status",
        description: "Impressions, clicks and CTRs by site per campaign"
      },
      {
        component: "campaign-insight",
        title: "Daily total impressions and click rate"
      },
      {
        component: "campaign-overview",
        title: "New Campaign Overview",
        description: "Campaign overview and previous period comparison"
      },
      {
        component: "campaign-viewability",
        title: "Campaign Viewability",
        description: "TODO: COMPLETE THIS DESCRIPTION"
      }
    ]
  },
  {
    category: "Creative Analysis",
    slides: [
      {
        component: "creative-country",
        title: "Creative by Country",
        description:
          "Show a graph an optional table of creative delivery broken down with a slide per country"
      },
      {
        component: "creative-comparison",
        title: "Creative Comparison",
        description:
          "Screengrab plus impressions, clicks and CTR compared by creative"
      },
      {
        component: "creative-performance",
        title: "Creative Performance",
        description:
          "Screengrab plus impressions, clicks and CTR per individual creative"
      },
      {
        component: "creative-performance-overtime",
        title: "Creative Performance Over Time",
        description: "Measuring creative fatigue over three periods"
      },
      {
        component: "creative-regression-comparison",
        title: "Creative Regression",
        description: "Measuring creative fatigue over time"
      },
      {
        component: "creative-site-comparison",
        title: "Creative Site Comparison",
        description:
          "Screengrab plus impressions and CTR for each creative on each site"
      },
      {
        component: "creative-dynamic-creative",
        title: "Dynamic Creative Comparison",
        description: "Performance of dynamic elements by different creatives"
      },
      {
        component: "creative-dynamic-graph",
        title: "Dynamic Creative Custom",
        description: "Custom parameter breakdown of dynamic creatives"
      },
      {
        component: "creative-dynamic-headline",
        title: "Dynamic Creative Headline",
        description:
          "Impressions, clicks and CTRs and Headline value per dynamic element."
      },
      {
        component: "creative-dynamic-table",
        title: "Dynamic Creative Table",
        description: "Impressions, clicks and CTRs per dynamic element."
      },
      {
        component: "creative-group",
        title: "Creative Grouping",
        description:
          "TODO: ADD DESCRIPTION"
      },
    ]
  },
  {
    category: "Comparison",
    slides: [
      {
        component: "comparison-cost",
        title: "Comparison Cost",
        description: "TODO: Complete this description."
      }
    ]
  },
  {
    category: "Delivery Analysis",
    slides: [
      {
        component: "country-comparison",
        title: "Country Comparison",
        description: "Impressions and CTR by country"
      },
      {
        component: "country-top",
        title: "Country Summary",
        description: "Impressions, CTR and Sessions for top countries"
      },
      {
        component: "country-viewability",
        title: "Country Viewability",
        description: "Impression delivery and viewability by country"
      },
      {
        component: "campaign-impression-delivery-breakdown",
        title: "All Time Delivery By Placement"
      },
      {
        component: "campaign-impression-delivery",
        title: "All Time Delivery By Site"
      },
      {
        component: "floodlight-table",
        title: "Floodlight Table",
        description:
          "Floodlight activity broken down by activity and site, with optional grouping."
      },
      {
        component: "placement-type",
        title: "Placement",
        description: "Impressions, CTR and viewability by placement"
      },
      {
        component: "site-period",
        title: "Site Over Time",
        description: "Total Impressions and CTR by site"
      },
      {
        component: "site-spend",
        title: "Site Spend",
        description: "CTR, CPC and CPM by site"
      },
      {
        component: "placement-taut",
        title: "Taut Placement",
        description: "Pull the campaign taut.  See what is underperforming"
      }
    ]
  },
  {
    category: "Optimisation",
    mode: "v2",
    slides: [
      {
        component: "optimisation-performance",
        title: "Performance",
        description:
          "Add a page of decisions taken to optimise the campaign and their outcome respectively"
      }
    ]
  },
  {
    category: "Presentation Comments",
    mode: "presentation",
    slides: [
      {
        component: "presentation-bullet",
        title: "Bullet Points",
        description: "Add a page or half page of bullet point comments"
      },
      // {
      //   component: "presentation-full",
      //   title: "Full Page Comments",
      //   description: "Add a full page of comments using the custom editor"
      // },
      {
        component: "presentation-gallery",
        title: "Gallery",
        description: "Add a page of images with optional titles"
      },
      {
        component: "presentation-linkedin-campaign",
        title: "LinkedIn Campaign",
        description:
          "A snapshot of top LinkedIn campaigns with bullet points to the left"
      },
      {
        component: "presentation-site-top",
        title: "Site Snapshot",
        description: "A snapshot of top sites with bullet points to the left"
      }
    ]
  },
  {
    category: "Search",
    slides: [
      {
        component: "adwords-campaign",
        title: "Adwords Campaign",
        description: "Show AdWords performance per campaign"
      },
      {
        component: "adwords-keyword",
        title: "Adwords Keyword",
        description: "Show the top keywords from AdWords"
      },
      {
        component: "adwords-period",
        title: "Adwords Over Time",
        description: "Show AdWords performance over past periods"
      },
      {
        component: "adwords-table",
        title: "Adwords Table",
        description: "Show raw AdWords performance table"
      },
      {
        component: "search-campaign",
        title: "DC Search Campaign",
        description: "Show Double Click Search performance per campaign"
      },
      {
        component: "search-keyword",
        title: "DC Search Keyword",
        description: "Show the top keywords from Double Click Search"
      },
      {
        component: "search-period",
        title: "DC Search Over Time",
        description: "Show Double Click Search performance over past periods"
      }
    ]
  },
  {
    category: "Video",
    mode: "v2",
    slides: [
      {
        component: "video-completion",
        title: "Video Completion",
        description: "TOOO: Write Description for this one"
      },
      {
        component: "video-cost",
        title: "Video Cost",
        description: "TOOO: Write Description for this one"
      }
    ]
  },
  {
    category: "Vote",
    slides: [
      {
        component: "vote-range",
        title: "Vote Questions by Range",
        description:
          "Show the answers to up to 4 questions broken down by their range"
      }
    ]
  }
];

module.exports = { slideList };
