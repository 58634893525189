<template>
  <div :class="pageClass">
    <div
      class="pageNo"
      @click="$emit(addMode ? 'add' : 'view')"
    >
      {{ index >= 0 ? index + 1 : null }}.
    </div>
    <div
      :class="pageExcludedClass"
      @click="$emit(addMode ? 'add' : 'view')"
    >
      <span
        v-if="title.length > 50"
        :title="title"
      >{{ title.substring(0, 50) }}...</span>
      <span v-else>{{ title }}</span>
    </div>
    <div v-if="!access.createReport" />
    <div
      v-else-if="template"
      class="pageEdit"
      title="Include or exclude the slide"
      @click="$emit('excludeTemplateToggle')"
    >
      <i :class="pageExcludedIcon" />
    </div>
    <div
      v-else-if="addMode"
      class="pageEdit"
      title="Add slide"
      @click="$emit('add')"
    >
      <i class="far fa-plus-square" />
    </div>
    <div
      v-else
      class="pageEdit"
      title="Edit options or delete slide"
      @click="$emit('edit')"
    >
      <i class="far fa-edit" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportDisplayBuilderPage",
  /* eslint-disable vue/require-prop-types */
  props: [
    "id",
    "index",
    "showAdd",
    "current",
    "editing",
    "title",
    "template",
    "exclude",
    "addMode",
    "access"
  ],
  /* eslint-enable vue/require-prop-types */
  computed: {
    pageClass: function() {
      return {
        pageAdd: this.addMode && this.showAdd,
        pageEditing: this.id === this.editing && !this.showAdd,
        pageSelected:
          this.id === this.current && !this.showAdd && !this.editing,
        page: true
      };
    },
    pageExcludedClass() {
      return {
        pageName: true,
        [this.exclude ? "pageExcluded" : "pageIncluded"]: true
      };
    },
    pageExcludedIcon() {
      return {
        far: true,
        [this.exclude ? "fa-square" : "fa-check-square"]: true
      };
    }
  }
};
</script>

<style scoped lang="sass">
@import "../../../../scss/fundamental-variables.scss"

div.page
  font-size: 1.2em
  cursor: pointer
  display: grid
  grid-template-columns: 10% auto 10%
  div
    padding: 5px
    border-bottom: solid 1px $colorLight
  &:hover, &.pageSelected, &.pageAdd
    div
      background-color: $colorLight
  &.pageEditing
    div
      background-color: $colorMediumLight
    &:hover
      div
        background-color: $colorMediumLight
  div.pageNo
    padding-left: 10px !important
  div.pageName
    flex-grow: 1
    &.pageExcluded
      text-decoration: line-through
</style>
