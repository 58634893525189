<template>
  <onehelp-menu-simple
    v-bind="$props"
    :header-icons="headerIcons"
    :has-search="homepage && !!homepage.length"
    :container-style="homepage && homepage.length ? {maxHeight: '790px'} : {maxHeight: '462px'}"
    :style-height="{minHeight: '240px'}"
    v-on="$listeners"
  >
    <loader-fullpage
      v-if="homepage === null"
      style="height: 550px"
    />
    <div
      v-else-if="homepage.length"
      class="sContent"
    >
      <div
        v-for="item in homepage"
        :key="`item${item.id}`"
        class="sItem"
      >
        <div class="sArticleTitle">
          <h3>{{ item.reference }}</h3>
        </div>
        <div class="sArticleLink">
          <span
            @click="$emit('routeChange', 
                          item.type === 'article' ? 
                            { name: 'article', id: item.id } : 
                            {name: 'list', reference: item.reference, type: item.type, id: item.id})"
          >Get started</span>
        </div>
      </div>
      <div class="sAllArticleLink">
        <span @click="$emit('routeChange', 'list')">Browse all help articles</span>
      </div>
    </div>
    <div
      v-else
      class="sNoContent"
    >
      <h1>More help coming soon</h1>
      <p>We will be adding a range of articles to help you here in the near future, in the meantime please use the links below to get in touch with us so we can help you right now.</p>
    </div>
  </onehelp-menu-simple>
</template>

<script>
import Api from "@/connect/api";
import OnehelpMenuSimple from "@/components/Onehelp/Menu/Simple";

export default {
  name: "OnehelpMenuHomepage",
  components: {OnehelpMenuSimple},
  props: {
    articleList: {
      type: Array,
      default: null
    },
    headerIcons: {
      type: Array,
      required: true
    },
    helpMenu: {
      type: Boolean,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      homepage: null,
    }
  },
  mounted() {
    Api.get(
      `onehelp/homepage/${this.projectId}`,
      (homepage) => {
        this.homepage = homepage;
      },
      () => {
        this.homepage = []
      }
    );
  }
}
</script>

<style scoped lang="scss">

.sContent{
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  height: 550px;
  padding: 22px 30px;

  @media (max-height: 840px) {
    height: 416px;
  }


  .sAllArticleLink{

    span{
      cursor: pointer;
      text-decoration: underline;
      color: #0D0D0D;
      font-family: Roboto;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }

  .sItem{
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    width: 360px;
    height: 110px;
    border-radius: 6px;
    background-color: #F1F1F1;

    .sArticleTitle{
      margin: 10px 0;
    }

    h3{
      width: 320px;
      margin: 0 20px;
      color: #343434;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 28px;
    }

    .sArticleLink{
      width: 320px;
      margin: 0 20px;

      span{
        cursor: pointer;
        text-decoration: underline;
        color: #343434;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
  }
}
.sNoContent {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 40px;
  h1, p {
    text-align: center;
  }
}
</style>