<template>
  <div v-if="alerts !== false">
    <div
      v-if="alerts === null"
      class="loadingContainer"
    >
      <loader-fullpage
        height="50"
        message="Checking for alerts..."
      />
    </div>
    <div v-else>
      <v-alert
        v-for="(alert, index) in alerts"
        :key="index"
        :type="alert.type"
      >
        <div class="alertContain">
          <div class="alertMessage">
            {{ alert.message }}
          </div>
          <v-btn
            v-if="alert.action === 'vue'"
            class="alertButton"
            elevation="8"
            :color="buttonColor(alert.type)"
            large
            @click="vueAlert(alert.vueParam, alert.vueData)"
          >
            {{ alert.buttonValue }}
          </v-btn>
          <v-btn
            v-if="alert.action === 'url'"
            class="alertButton"
            elevation="8"
            :color="buttonColor(alert.type)"
            large
            @click="window.location = alert.url"
          >
            {{ alert.buttonValue }}
          </v-btn>
        </div>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import Api from "../../../../connect/api";

export default {
  data() {
    return {
      alerts: null
    };
  },
  computed: {
    ...mapState("report", [
      "advertiserId",
      "campaignIds",
      "placementIds",
      "startDate",
      "endDate"
    ])
  },
  mounted() {
    Api.post(
      "doubleclick/matching/alert",
      {
        advId: this.advertiserId,
        campaignIds: this.campaignIds.split(","),
        placementIds: this.placementIds.split(","),
        startDate: this.startDate,
        endDate: this.endDate
      },
      response => {
        this.alerts = response;
      },
      () => {
        this.alerts = false;
      }
    );
  },
  methods: {
    ...mapMutations("report", ["setAlertData"]),
    buttonColor: function(alertType) {
      switch (alertType) {
        case "info":
          return "primary";
        case "error":
          return "red";
        case "success":
          return "green";
        case "warning":
          return "orange";
        default:
          return "default";
      }
    },
    vueAlert: function(param, data) {
      this.setAlertData({ param: param, value: data });
      this.$emit("vueAlert", param);
    }
  }
};
</script>

<style lang="sass">
.ui-alert--type-info .ui-alert__body
    background-color: #e7f2fe
.ui-alert--type-error .ui-alert__body
    background-color: #fee9e7
.ui-alert--type-success .ui-alert__body
    background-color: #ebf5ea
.ui-alert--type-warning .ui-alert__body
    background-color: #fff3e5
</style>
<style scoped lang="sass">
.loadingContainer
    margin-bottom: 40px
.alertContain
    display: flex
.alertMessage
    flex-grow: 1
    padding-right: 10px
</style>
