<template>
  <onehelp-menu-simple
    v-bind="$props"
    :header-icons="headerIcons"
    :back-route="backRoute"
    has-search
    v-on="$listeners"
  >
    <loader-fullpage
      v-if="list === null"
      style="height: 640px"
    />
    <div
      v-else
      class="sContent"
    >
      <div class="sContentTitle">
        <h2>{{ subTitle }}</h2>
      </div>
      <div class="sListWrapper">
        <div
          v-for="item in filteredList"
          :key="`item${item.id}`"
          class="sItem"
        >
          <div
            class="sArticleTitle"
            @click="$emit('routeChange', category ? {name: 'article', id: item.id, category: category} : { name: 'article', id: item.id })"
          >
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </onehelp-menu-simple>
</template>

<script>
import Api from "@/connect/api";
import OnehelpMenuSimple from "@/components/Onehelp/Menu/Simple";

export default {
  name: "OnehelpMenuList",
  components: {OnehelpMenuSimple},
  props: {
    articleList: {
      type: Array,
      default: null
    },
    category: {
      type: Object,
      default: null
    },
    headerIcons: {
      type: Array,
      required: true
    },
    helpMenu: {
      type: Boolean,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      list: null,
      backRoute: {
        text: 'Help Home',
        route: 'home'
      },
      search: '',
      subTitle: this.category ? this.category.reference : 'All articles',
    }
  },
  computed: {
    filteredList() {
      if (!this.list) {
        return [];
      }
      if (!this.search.trim()) {
        return this.list;
      }
      const searchPattern = new RegExp(
        this.search.trim().replace(/\s\s+/g, " ").split(" ").map(s => `(?=.*\\b${s})`).join("") +
        `.*`, "i");
      return this.list.filter(i => !!i.title.match(searchPattern));
    },
  },
  mounted() {
    if(this.category){
      Api.get(
        `onehelp/category/${this.category.id}}`,
        (categoryList) => {
          this.list = categoryList.articleList.map(i => {
            return {...i, id: i.articleId}
          })
            .filter(a => a.status === "live")
        },
        () => {
          this.list = []
        }
      );
    } else {
      this.list = this.articleList
    }
  }
}
</script>

<style scoped lang="scss">

.sContent{
  max-height: 708px;
  padding: 12px 30px 22px;

  .sContentTitle{
    padding-bottom: 8px;
    padding-top: 24px;
    color: #343434;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
  }

  .sListWrapper{
    max-height: 474px;
    margin-top: 16px;
    width: 380px;
    overflow-y: auto;

    @media (max-height: 840px) {
      max-height: 290px;
    }
  }

  .sItem{
    width: 360px;
    border-bottom: 1px #E3E3E3 solid;

    .sArticleTitle {
      cursor: pointer;
      margin-top: 24px;
      margin-bottom: 12px;

      p {
        width: 320px;
        margin: 0 20px;
        color: #343434;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }
}
</style>