const feature = require("vue-feature-toggle");

feature.visibility("reportDownload", "v1", rule => {
  // const { userId } = rule.data;
  // return userId !== 31;
  return true;
});
feature.visibility("reportDownload", "v2", rule => {
  // const { userId } = rule.data;
  // return userId === 31;
  return false;
});

module.exports = feature;
