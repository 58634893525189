import _ from "lodash";
import router from "../router";
import Api from "../connect/api";
import Vue from "vue";

const getDefaultState = () => {
  return {
    advertiserId: null,
    advertiserClientData: null,
    advertiserDetails: null,
    alertData: {},
    benchmarkEndDate: null,
    benchmarkImpressions: null,
    benchmarkCtr: null,
    benchmarkStartDate: null,
    benchmarkTypeDate: null,
    campaignAll: false,
    campaignIds: null,
    campaignDetails: null,
    chartList: [],
    endDate: null,
    mode: null,
    name: null,
    placementAll: false,
    placementIds: null,
    placementDetails: null,
    reportId: null,
    reportImpressionsBy: null,
    setupComplete: false,
    firstSetupComplete: false,
    startDate: null,
    template: null,
    typeDate: null
  };
};

// initial state
const state = getDefaultState();

const getters = {
  clientLogoUrl: function() {
    if (state.advertiserClientData)
      return state.advertiserClientData.clientLogo;
  },
  clientName: function() {
    if (state.advertiserClientData)
      return state.advertiserClientData.clientName;
  },
  clientOfficeId: function() {
    if (state.advertiserClientData)
      return state.advertiserClientData.clientOfficeId;
  },
  clientUuid: function() {
    if (state.advertiserClientData)
      return state.advertiserClientData.clientUuid;
  },
  campaignName: state => campaignId => {
    if (state.campaignDetails) {
      let campaign = _.find(state.campaignDetails, { value: campaignId * 1 });
      return typeof campaign === "undefined"
        ? "Unknown " + campaignId
        : campaign.label;
    }
  },
  clientType: function() {
    if (state.advertiserClientData)
      return state.advertiserClientData.clientType;
  },
  dateFormat() {
    if (state.advertiserDetails) {
      let advertiser = _.find(state.advertiserDetails, {
        value: state.advertiserId * 1
      });
      return typeof advertiser === "undefined"
        ? "DD/MM/YYYY"
        : advertiser.label.indexOf("FMUSA") > -1
        ? "MM/DD/YYYY"
        : "DD/MM/YYYY";
    } else return "DD/MM/YYYY";
  },
  getMode() {
    return state.mode ? state.mode : "reporting";
  }
};

const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  addChart({ commit }, chart) {
    commit("addChart", chart);
  },
  setBenchmarkDate({ commit }, date) {
    commit("setBenchmarkDate", date);
  },
  setDate({ commit, dispatch }, date) {
    commit("setDate", date);
    commit("setAdvertiserClientData", null);
    if (state.startDate !== null && state.endDate !== null) {
      commit("setAdvertiserDetails", []);
      Api.post(
        "doubleclick/adv/list",
        {
          status: "APPROVED",
          startDate: state.startDate,
          endDate: state.endDate
        },
        response => {
          if (date.advertiserId) dispatch("setAdvertiser", date);
          commit("setAdvertiserDetails", response);
        },
        () => {
          if (date.advertiserId) dispatch("setAdvertiser", date);
        }
      );
    }
  },
  setAdvertiser({ commit, dispatch }, advertiser) {
    if (advertiser.advertiserId === this.advertiserId) return true;

    commit("setAdvertiser", advertiser.advertiserId);
    commit("setCampaignAll", false);
    commit("setPlacementAll", false);
    if (state.advertiserId) {
      commit("setCampaignDetails", []);
      commit("setAdvertiserClientData", null);

      Api.post(
        "doubleclick/campaign/list",
        {
          advId: [state.advertiserId],
          startDate: state.startDate,
          endDate: state.endDate
        },
        response => {
          commit("setCampaignDetails", response);

          if (advertiser.campaignAll === "true") {
            commit("setCampaignAll", true);
            advertiser.campaignIds = _.map(response, "value").join();
          }
          if (advertiser.campaignIds) dispatch("setCampaign", advertiser);
        },
        () => {
          if (advertiser.campaignAll === "true" || advertiser.campaignIds) {
            Vue.notify({
              type: "error",
              title: "Campaign load failure",
              text: "Failed to find campaigns for your report to load."
            });
            router.push("/report/load");
          }
        }
      );

      Api.post(
        "intelligence/adv/get",
        {
          advId: state.advertiserId
        },
        response => {
          commit("setAdvertiserClientData", response);
        }
      );
    }
  },
  setCampaign({ commit, dispatch }, campaign) {
    if (campaign.campaignIds === this.campaignIds) return true;

    commit("setCampaign", campaign.campaignIds);
    commit("setPlacementAll", false);
    commit("setPlacementDetails", []);
    if (campaign.campaignIds) {
      Api.post(
        "doubleclick/placement/list",
        {
          advId: [state.advertiserId],
          startDate: state.startDate,
          endDate: state.endDate,
          campaignId: campaign.campaignIds.split(",")
        },
        response => {
          commit("setPlacementDetails", response);

          if (campaign.placementAll === "true") {
            commit("setPlacementAll", true);
            campaign.placementIds = _.map(response, "value").join();
          }
          if (campaign.placementIds)
            dispatch("setPlacement", campaign.placementIds);

          if (campaign.autoComplete) campaign.autoComplete(state);
        },
        () => {
          if (campaign.placementAll === "true" || campaign.placementIds) {
            Vue.notify({
              type: "error",
              title: "Placement load failure",
              text: "Failed to find placements for your report to load."
            });
            router.push("/report/load");
          }
        }
      );
    }
  },
  setPlacement({ commit }, placementIds) {
    if (placementIds === this.placementIds) return true;

    commit("setPlacement", placementIds);
  },
  markSetupComplete({ commit }, value) {
    if (value === true) {
      commit("setupComplete", true);
      commit("firstSetupComplete", true);
    } else {
      commit("setupComplete", false);
    }
  }
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  addChart(state, chart) {
    state.chartList.push(chart);
  },
  setAdvertiserDetails(state, advertiserDetails) {
    state.advertiserDetails = advertiserDetails;
  },
  setAdvertiserClientData(state, advertiserClientData) {
    state.advertiserClientData = advertiserClientData;
  },
  setBenchmarkImpressions(state, benchmarkImpressions) {
    if (benchmarkImpressions < 1000) benchmarkImpressions = 1000;
    state.benchmarkImpressions = benchmarkImpressions;
  },
  setBenchmarkCtr(state, benchmarkCtr) {
    state.benchmarkCtr = benchmarkCtr;
  },
  setCampaignAll(state, bool) {
    state.campaignAll = bool;
  },
  setCampaignDetails(state, campaignDetails) {
    state.campaignDetails = campaignDetails;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setName(state, name) {
    state.name = name;
  },
  setPlacementAll(state, bool) {
    state.placementAll = bool;
  },
  setPlacementDetails(state, placementDetails) {
    state.placementDetails = placementDetails;
  },
  setReportId(state, reportId) {
    state.reportId = reportId;
  },
  setBenchmarkDate(state, date) {
    state.benchmarkStartDate = date.start;
    state.benchmarkEndDate = date.end;
    state.benchmarkTypeDate = date.value;
  },
  setAlertData(state, data) {
    state.alertData[data.param] = data.value;
  },
  setDate(state, date) {
    state.startDate = date.start;
    state.endDate = date.end;
    state.typeDate = date.value;
    state.advertiserId = null;
    state.advertiserDetails = null;
    state.campaignIds = null;
    state.campaignDetails = null;
    state.placementIds = null;
    state.placementDetails = null;
  },
  setAdvertiser(state, advertiserId) {
    state.advertiserId = advertiserId;
    state.campaignIds = null;
    state.campaignDetails = null;
    state.placementIds = null;
    state.placementDetails = null;
  },
  setCampaign(state, campaignIds) {
    state.campaignIds = campaignIds;
    state.placementIds = null;
    state.placementDetails = null;
  },
  setPlacement(state, placementIds) {
    state.placementIds = placementIds;
  },
  setReportImpressionsBy(state, reportImpressionsBy) {
    state.reportImpressionsBy = reportImpressionsBy;
  },
  setTemplate(state, template) {
    state.template = template;
  },
  setupComplete(state, value) {
    state.setupComplete = value;
  },
  firstSetupComplete(state, value) {
    state.firstSetupComplete = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
