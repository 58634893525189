const Api = require("../connect/api");
import Vue from "vue";

import {
  daysBetween,
  addDays,
  formatISODate,
} from "@fundamentalmedia/fundahelper/Date";

const getDefaultState = () => {
  return {
    configureFromPage: null,
    dateRange: null,
    drillDownBy: "",
    drilldownTabStat: 'all',
    hasVideo: false,
    overviewList: {},
    showConversionInsight: false,
    selectedOptimisationLogs: [],
    tabStat: 'all',
    sideSheet: null,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  getConfigureFromPage: (state) => (aaUuid) => {
    return (state && state.configureFromPage && state.configureFromPage.toUpperCase() || '').includes(aaUuid && aaUuid.toUpperCase() || '')
      ? state.configureFromPage
      : `/outcome/analytics/digital/${aaUuid}`;
  },
  getDateRange: (state) => {
    return state.dateRange;
  },
  // Get the same amount of time previously, so if date range is 1 month this will be the previous
  // month, if it's 3 months it will be the 3 months before that
  getDateRangePreviousPeriod: (state) => {
    const days                = daysBetween(state.dateRange.start, state.dateRange.end),
      endPreviousPeriod   = addDays(state.dateRange.start, -1),
      startPreviousPeriod = addDays(endPreviousPeriod, -1 * days);
    return {
      start: formatISODate(startPreviousPeriod),
      end: formatISODate(endPreviousPeriod),
    };
  },

  getOverview: (state) => (aaUuid) => {
    return state.overviewList[aaUuid];
  },
  getDrillDownBy: (state) => {
    return state.drillDownBy;
  },
  getDrilldownTabStat: (state) => {
    return state.drilldownTabStat;
  },
  getHasVideo: (state) => {
    return state.hasVideo;
  },
  getShowConversionInsight: (state) => {
    return state.showConversionInsight;
  },
  getSelectedOptimisationLogs: (state) => {
    return state.selectedOptimisationLogs;
  },
  getTabStat: (state) => {
    return state.tabStat;
  },
  getSideSheet: (state) => {
    return state.sideSheet;
  }
};

const actions = {
  lookupOverview({commit, state}, aaUuid) {
    if (typeof state.overviewList[aaUuid] !== "undefined") return;
    commit("setOverview", {aaUuid, value: null});
    Api.default.get(
        `client/aggregator/reporting/activity/overview/${aaUuid}`,
        (value) => {
          commit("setOverview", {aaUuid, value});
        },
        () => {
          commit("setOverview", {aaUuid, value: false});
        },
    );
  },
  setConfigureFromPage({commit}, page) {
    commit("setConfigureFromPage", page);
  },
  setDateRange({commit}, dateRange) {
    commit("setDateRange", dateRange);
  },
  setDrillDownBy({commit}, drilldownBy) {
    commit("setDrillDownBy", drilldownBy);
  },
  setHasVideo({commit}, hasVideo) {
    commit("setHasVideo", hasVideo);
  },
  setOverview({ commit }, aaUuid, value) {
    commit("setOverview", { aaUuid, value });
  },
  setTabStat({ commit }, tabStat) {
    commit("setTabStat", tabStat);
  },
  toggleConversionInsight({ commit }) {
    commit("toggleConversionInsight");
  },
  setSelectedOptimisationLogs({ commit }, selectedOptimisationLogs) {
    commit("setSelectedOptimisationLogs", selectedOptimisationLogs);
  },
  setSideSheet({ commit }, sideSheet) {
    commit("setSideSheet", sideSheet);
  }
}
const mutations = {
  setConfigureFromPage(state, page) {
    state.configureFromPage = page;
  },
  setDateRange(state, dateRange) {
    state.dateRange = dateRange;
  },
  setOverview(state, overview) {
    Vue.set(state.overviewList, overview.aaUuid, overview.value);
  },
  setDrillDownBy(state, drilldownBy) {
    state.drillDownBy = drilldownBy;
  },
  setHasVideo(state, hasVideo) {
    state.hasVideo = hasVideo;
  },
  setTabStat(state, tabStat) {
    state.tabStat = tabStat;
  },
  toggleConversionInsight(state) {
    state.showConversionInsight = !state.showConversionInsight;
  },
  setSelectedOptimisationLogs(state, selectedOptimisationLogs) {
    state.selectedOptimisationLogs = selectedOptimisationLogs;
  },
  setSideSheet(state, sideSheet) {
    state.sideSheet = sideSheet;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
