<template>
  <div class="menu">
    <div
      class="close"
      @click="$emit('close')"
    >
      <i class="fal fa-times" />
      <span>CLOSE MENU</span>
    </div>
    <loader-fullpage v-if="navSection === null" />
    <page-nav-section
      v-for="(nav, navIndex) in navSection"
      v-else
      :key="navIndex"
      :title="nav.title"
      :links="nav.links"
    />
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {mapGetters} from "vuex";
import Api from "../../../connect/api";
import PageNavSection from "./Nav/Section";

export default {
  name: 'PageHeaderNav',
  components: {
    PageNavSection
  },
  data: function () {
    return {
      navSection: null
    };
  },
  computed: {
    ...mapGetters("di", ["getId"])
  },
  watch: {
    getId: {
      handler: "fetchUserAccess",
      immediate: true
    }
  },
  methods: {
    createReport() {
    },
    buildNav() {
      const sections = [];

      const dashboardLinks = [];
      if (this.fAllowed('dashboard/addDashboard')) {
        dashboardLinks.push({
          help: "Create new client dashboard",
          route: "/dashboard/create",
          title: "Create Dashboard"
        });
      }
      if (this.fAllowed('dashboard/listDashboard')) {
        dashboardLinks.push({
          help: "View & create client dashboards",
          route: "/dashboard/list",
          title: "Dashboard List"
        });
      }
      if (dashboardLinks.length > 0) {
        sections.push({
          title: "Dashboard",
          links: dashboardLinks
        });
      }

      const reportLinks = [];
      if (this.fAllowed('reporting/createReport')) {
        reportLinks.push({
          help: "Create a new report",
          action: () => {
            this.$router.push(
                this.$route.name === "create-report"
                    ? {
                      name: "create-report",
                      params: {id: "reset"}
                    }
                    : {name: "create-report"}
            );
          },
          title: "Create Report"
        });
        reportLinks.push({
          help: "Create a new report",
          action: () => {
            this.$router.push({name: "report-build"});
          },
          title: "AA Reporter"
        });
      }
      if (this.fAllowed('reporting/viewReports')) {
        reportLinks.push({
          help: "Open a saved report",
          route: "/report/load",
          title: "Load Report"
        });
      }
      if (reportLinks.length > 0) {
        sections.push({
          title: "Report",
          links: reportLinks
        });
      }

      const manageLinks = [];
      if(this.fAllowed('client/documentSharing')){
        manageLinks.push({
          help: "View Activity Aggregator issues",
          route: "/document-sharing",
          title: "Document Sharing"
        });
      }
      if (this.fAllowed('troubleshooter/aggregator')) {
        manageLinks.push({
          help: "View Activity Aggregator issues",
          route: "/troubleshooter/aggregator",
          title: "AA Troubleshooter"
        });
      }
      if (this.fAllowed('client/aggregatorSetup') || this.fAllowed('client/aggregatorView')) {
        manageLinks.push({
          help: "Configure and optimise Activity Aggregator's",
          route: "/client/aggregator/list",
          title: "Outcome Analytics"
        });
      }

      // toDo Geo - better title/help
      if (this.fAllowed('client/sponsoredContentManage')) {
        manageLinks.push({
          help: "Manage sponsored content",
          route: "/sponsored/content",
          title: "Sponsored Content",
        });
      }

      let oneHelpConfigMenu = []
      if (this.fAllowed('fundamental/onehelpManage')) {
        oneHelpConfigMenu.push({
          text: "Homepage",
          action: () => this.$router.push({
            name: 'onehelp-homepage',
            params: {projectId: this.$route.params.projectId}
          })
        });
      }
      if (this.fAllowed('fundamental/onehelpManage')) {
        oneHelpConfigMenu.push({
          text: "Categories",
          action: () => this.$router.push({
            name: 'onehelp-category',
            params: {projectId: this.$route.params.projectId}
          })
        });
      }
      if (this.fAllowed('fundamental/onehelpManage')) {
        oneHelpConfigMenu.push({
          text: "Articles",
          action: () => this.$router.push({
            name: 'onehelp-article',
            params: {projectId: this.$route.params.projectId}
          })
        });
      }

      if (oneHelpConfigMenu.length > 0) {
        manageLinks.push({
          help: "One Help",
          title: "One Help",
          configMenu: oneHelpConfigMenu
        });
      }

      const amxConfigMenu = []
      if (this.fAllowed('targeting/contentManage') || this.fAllowed('targeting/ipManage')) {
        amxConfigMenu.push({
          text: "Semantix, Firmo, Overlay",
          action: () => this.$router.push({
            name: 'amx-targeting',
          })
        });
      }
      if (this.fAllowed('targeting/contentManage')) {
        amxConfigMenu.push({
          text: "Section Targeting",
          action: () => this.$router.push({
            name: 'amx-section-targeting',
          })
        });
      }

      if (this.fAllowed('targeting/contentManage') || this.fAllowed("targeting/ipManage")) {
        manageLinks.push({
          help: "AMX Targeting",
          title: "AMX Targeting",
          configMenu: amxConfigMenu
        });
      }

      if (manageLinks.length > 0) {
        sections.push({
          title: "Manage",
          links: manageLinks
        });
      }

      const adminLinks = [];
      if (this.fAllowed('user/list')) {
        adminLinks.push({
          help: "View and manage user accounts",
          route: "/user/admin",
          title: "User Administration"
        });
      }
      if (this.fAllowed('log/viewer')) {
        adminLinks.push({
          help: "View and filter user access logs",
          route: "/log/view",
          title: "View Logs"
        });
      }
      if (this.fAllowed('client/link')) {
        adminLinks.push({
          help: "Link Client to external provider accounts (e.g. GA, LinkedIn)",
          route: "/client/link",
          title: "Link Client Accounts"
        });
      }
      if (this.fAllowed('cache/clean || cache/manage')) {
        links: adminLinks
          .push({
            help: "Control page",
            route: "/control/page",
            title: "Control page"
          });
      }
      if (adminLinks.length > 0) {
        sections.push({
          title: "Administration",
          links: adminLinks
        });
      }

      return sections;
    },
    fetchUserAccess() {
      if (this.getId) {
        this.navSection = this.buildNav();
      }
    }
  }
};
</script>

<style scoped lang="sass">
@import "../../../scss/fundamental-variables.scss"
.menu
  width: 276px
  height: 100vh
  position: absolute
  top: 0
  z-index: 999
  overflow-x: hidden
  overflow-y: auto
  background: white
  box-shadow: 0px 3px 6px $colorLight
  color: $colorDark

  &::-webkit-scrollbar
    background-color: transparent
    width: 10px

  &::-webkit-scrollbar-track
    background-color: transparent

  &::-webkit-scrollbar-thumb
    border-radius: 20px
    border: 5px solid $colorMediumLight
    height: 10px

  .close
    height: 100px
    display: flex
    align-items: center
    cursor: pointer

    i
      padding: 0 20px 0 44px
      color: $colorLight
      font-size: 1.5em

  h3
    height: 50px
    line-height: 50px
    margin: 0
    padding-left: 42px
    background-color: $colorLightest
    font-size: 1em
</style>
