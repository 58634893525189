const resetState = () => {
  return {
    plaftorm: null,
  };
};


const getDefaultState = () => {
  const stored = localStorage.getItem('blogPlatform');
  return {
    plaftorm: stored
  };
};

// initial state
const state = getDefaultState();

const getters = {
  getPlatform() {
    return state.plaftorm;
  },
};

const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  setPlatform({ commit }, plaftorm) {
    commit("setPlatform", plaftorm);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, resetState());
  },
  setPlatform(state, platform) {
    localStorage.setItem('blogPlatform', platform);
    state.plaftorm = platform;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
