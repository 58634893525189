import Vue from "vue";
import Router from "vue-router";
import {store} from "./vue-store.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {name: "home", path: "/", component: () => import("./views/Home.vue")},
    // {
    //   path: "/",
    //   name: "home",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ "./views/Home.vue")
    // },
    {
      name: "amx-section-targeting",
      path: "/amx/sectiontargeting",
      component: () => import("./views/Amx/SectionTargeting.vue"),
      meta: {
        allowed: ["targeting/contentManage"],
      },
    },
    {
      name: "amx-targeting",
      path: "/amx/targeting/:clientUuid?",
      component: () => import("./views/Amx/Targeting.vue"),
      meta: {
        allowed: ["targeting/contentManage || targeting/ipManage"],
      },
    },
    {
      name: "document-sharing",
      path: "/document-sharing/:clientUuid?",
      component: () => import("./views/Document/Sharing.vue"),
      meta: {
        allowed: ["client/documentSharing"],
      },
      props: {type: "list"}
    },
    {
      name: "amx-targeting-content",
      path: "/amx/targeting/content/:clientUuid?",
      component: () => import("./views/Amx/Targeting/Content.vue"),
      meta: {
        allowed: "targeting/contentManage",
      },
    },
    {
      name: "amx-targeting-content-details",
      path: "/amx/targeting/content/:clientUuid/:targetingProfileContentId",
      component: () => import("./views/Amx/Targeting/Content/Detail.vue"),
      meta: {
        allowed: "targeting/contentManage",
      },
    },
    {
      name: "amx-targeting-ip",
      path: "/amx/targeting/ip/:clientUuid?",
      component: () => import("./views/Amx/Targeting/Ip.vue"),
      // meta: {
      //   allowed: "targeting/ipManage"
      // }
    },
    {
      name: "amx-targeting-ip-details",
      path: "/amx/targeting/ip/:clientUuid/:targetingProfileIpId",
      component: () => import("./views/Amx/Targeting/Ip/Detail.vue"),
      // meta: {
      //   allowed: "targeting/contentManage"
      // }
    },
    {
      name: "amx-targeting-firmo-details",
      path: "/amx/targeting/firmo/:clientUuid/:targetingProfileFirmoId",
      component: () => import("./views/Amx/Targeting/Firmo/Detail.vue"),
      // meta: {
      //   allowed: "targeting/contentManage"
      // }
    },
    {
      name: "blog-post",
      path: "/blog/post/:action",
      component: () => import("./views/Blog/Post/Edit.vue"),
    },
    {
      name: "blog-post-uuid",
      path: "/blog/post/:action/:uuid",
      component: () => import("./views/Blog/Post/Edit.vue"),
    },
    {
      name: "blog-overview",
      path: "/blog/:action",
      component: () => import("./views/Blog/Overview.vue"),
    },
    {
      name: 'control-page',
      path: "/control/page",
      component: () => import('./views/Control/Page'),
      meta: {
        allowed: "cache/clean || cache/manage"
      }
    },
    {
      name: "dashboard-template-list",
      path: "/dashboard/template/list",
      component: () => import("./views/Dashboard/Template/List.vue"),
    },
    {
      name: "dashboard-template-edit",
      path: "/dashboard/template/edit/:templateId",
      component: () => import("./views/Dashboard/Template/Edit.vue"),
    },
    {
      name: "dashboard-template-edit-page",
      path: "/dashboard/template/edit/page/:templateId/:pageId",
      component: () => import("./views/Dashboard/Template/Edit/Page.vue"),
    },
    {
      name: "dashboard-create",
      path: "/dashboard/create/:page?",
      component: () => import("./views/Dashboard.vue"),
    },
    {
      name: "dashboard-list",
      path: "/dashboard/list",
      component: () => import("./views/Dashboard/List.vue"),
      props: {type: "all"},
    },
    {
      name: "dashboard-list-stared",
      path: "/dashboard/list/stared",
      component: () => import("./views/Dashboard/List.vue"),
      props: {type: "stared"},
    },
    {
      name: "dashboard-list-shared",
      path: "/dashboard/list/shared",
      component: () => import("./views/Dashboard/List.vue"),
      props: {type: "shared"},
    },
    {
      name: "dashboard",
      path: "/dashboard/:uuid/:page?",
      component: () => import("./views/Dashboard.vue"),
    },
    {
      name: "auth-token-linkedin",
      path: "/auth/token/linkedin",
      component: () => import("./views/Auth/Token/Linkedin.vue"),
    },
    {
      name: "client-aggregator-configure",
      path: "/client/aggregator/configure/:id/:section?",
      component: () => import("./views/Client/Aggregator/Configure.vue"),
    },
    {
      name: "key-event",
      path: "/key/event",
      component: () => import("./views/Key/Event"),
    },
    {
      name: "client-aggregator-list",
      path: "/client/aggregator/list/:clientUuid?",
      component: () => import("./views/Client/Aggregator/List.vue"),
    },
    {
      name: "client-booking-link",
      path: "/client/booking/link/:clientUuid?",
      component: () => import("./views/Client/Booking/Link.vue"),
    },
    {
      name: "client-configure",
      path: "/client/configure/:clientUuidSection?",
      component: () => import("./views/Client/Configure.vue"),
      meta: {
        allowed: "client/adminSettingView",
      },
    },
    { //TODO cannot be removed, used in multiple places
      name: "client-flow",
      path: "/client/flow/:clientUuid?",
      component: () => import("./views/Client/Flow.vue"),
      meta: {
        allowed: "client/flowView",
      },
    },
    { //TODO cannot be removed, used in multiple places
      name: "client-flow-details",
      path: "/client/flow/:clientUuid/:flowId",
      component: () => import("./views/Client/Flow/Detail"),
      meta: {
        allowed: "client/flowView",
      },
    },
    {
      name: "client-link",
      path: "/client/link",
      component: () => import("./views/Client/Link.vue"),
    },
    {
      name: "client-subscription-create",
      path: "/client/subscription/create",
      component: () => import("./views/Client/Subscription/CreateCompany.vue"),
    },
    {
      name: "client-subscription-access-tools",
      path: "/client/subscription/accessTools/:clientUuid",
      component: () => import("./views/Client/Subscription/AccessTools.vue"),
    },
    {
      name: "client-subscription-manage",
      path: "/client/subscription/manage",
      component: () => import("./views/Client/Subscription/Manage.vue"),
    },
    {
      name: "client-subscription-order-history",
      path: "/client/subscription/order/history/:clientUuid",
      component: () => import("./views/Client/Subscription/OrderHistory.vue"),
    },
    {
      name: "log-view",
      path: "/log/view",
      component: () => import("./views/Log/View.vue"),
    },
    {path: "/outcome/analytics/:id", redirect: {name: "outcome-digital"}},
    {
      name: "outcome-digital",
      path: "/outcome/analytics/digital/:id",
      component: () => import("@/views/Outcome/Analytics/Digital.vue"),
    },
    {
      name: "outcome-search",
      path: "/outcome/analytics/search/:section/:id",
      component: () => import("@/views/Outcome/Analytics/Search.vue"),
    },
    {
      name: "outcome-social",
      path: "/outcome/analytics/social/:id",
      component: () => import("./views/Outcome/Analytics/Social.vue"),
    },
    {
      name: "outcome-social-network",
      path: "/outcome/analytics/social/:network/:id",
      component: () => import("./views/Outcome/Analytics/Social/Network.vue"),
    },
    {
      name: "create-report",
      path: "/reports/display/:id?",
      component: () => import("./components/Report/Display.vue"),
    },
    { //TODO cannot be removed, used in Troubleshooter/Creative
      name: "creative-unlinked",
      path: "/creative/unlinked/:clientUuid?/:creativeId?",
      component: () => import("./views/Creative/Set/Unlinked.vue"),
    },
    { //TODO cannot be removed, used in analytics/alpha when creating a new collection
      name: "client-attribution-campaign",
      path: "/client/attribution/campaign/:clientUuid?",
      component: () => import("./views/Client/Attribution/Campaign.vue"),
      meta: {
        allowed: "client/attributionCampaignView",
      },
    },
    { //TODO cannot be removed, used in analytics/alpha when creating a new collection
      name: "client-attribution-campaign-detail",
      path: "/client/attribution/campaign/:clientUuid/:campaignId",
      component: () => import("./views/Client/Attribution/Campaign/Detail.vue"),
      meta: {
        allowed: "client/attributionCampaignView",
      },
    },
    {
      name: "client-file-upload",
      path: "/client/file/upload",
      component: () => import("./views/Client/File/Upload.vue"),
    },
    {
      name: "client-overview",
      path: "/client/overview/:clientUuid?",
      component: () => import("./views/Client/Overview.vue"),
    },
    {
      name:"intell-log-viewer",
      path: "/intell/log/viewer",
      component: () => import("./views/Intell/Log/Viewer.vue"),
    },
    {
      name: "onehelp-article",
      path: "/onehelp/article/:projectId?",
      component: () => import("./views/Onehelp/Article.vue"),
      meta: {
        allowed: "fundamental/onehelpManage",
      },
    },
    {
      name: "onehelp-article-detail",
      path: "/onehelp/article/:projectId/:articleId",
      component: () => import("./views/Onehelp/Article/Detail.vue"),
      meta: {
        allowed: "fundamental/onehelpManage",
      },
    },
    {
      name: "onehelp-category",
      path: "/onehelp/category/:projectId?",
      component: () => import("./views/Onehelp/Category.vue"),
      meta: {
        allowed: "fundamental/onehelpManage",
      },
    },
    {
      name: "onehelp-category-detail",
      path: "/onehelp/category/:projectId/:categoryId",
      component: () => import("./views/Onehelp/Category/Detail.vue"),
      meta: {
        allowed: "fundamental/onehelpManage",
      },
    },
    {
      name: "onehelp-homepage",
      path: "/onehelp/homepage/:projectId?",
      component: () => import("./views/Onehelp/Homepage.vue"),
      meta: {
        allowed: "fundamental/onehelpManage",
      },
    },
    {
      name: "load-report",
      path: "/reports/load/",
      component: () => import("./views/Report/Load.vue"),
    },
    {
      name: "report-build",
      path: "/report/build/:id?",
      component: () => import("./views/Report/Build.vue"),
    },
    {
      name: "report-build-aa",
      path: "/report/build/aa/:aaUuid?/:range?",
      component: () => import("./views/Report/Build.vue"),
    },
    {
      name: "report-load",
      path: "/report/load/",
      component: () => import("./views/Report/Load.vue"),
    },
    {
      name: "sponsored-content",
      path: "/sponsored/content/:clientUuid?",
      component: () => import("./views/Sponsored/Content.vue"),
      meta: {
        allowed: "client/sponsoredContentManage",
      },
    },
    {
      name: "sponsored-content-details",
      path: "/sponsored/content/:clientUuid/:sponsoredDocumentId",
      component: () => import("./views/Sponsored/Content/Detail.vue"),
      meta: {
        allowed: "client/sponsoredContentManage",
      },
    },
    {
      name: "troubleshooter-aggregator",
      path: "/troubleshooter/aggregator/:aaUuid?",
      component: () => import("./views/Troubleshooter/Aggregator.vue"),
    },
    {
      name: "terms",
      path: "/terms",
      component: () => import("./views/Terms.vue"),
    },
    {
      name: "user-detail-own",
      path: "/user/detail",
      component: () => import("./views/User/Detail.vue"),
    },
    {
      name: "user-detail",
      path: "/user/detail/:id",
      component: () => import("./views/User/Detail.vue"),
      meta: {
        allowed: "user/viewOther",
      },
    },
    {
      name: "user-role",
      path: "/user/role/:id",
      component: () => import("./views/User/Role.vue"),
      meta: {
        allowed: "user/roleAdmin",
      },
    },
    {
      name: "user-permission-group",
      path: "/user/permission/group/:code",
      component: () => import("./views/User/Permission/Group.vue"),
      meta: {
        allowed: "user/roleAdmin",
      },
    },
    {
      name: "user-permission",
      path: "/user/permission/:groupCode/:code",
      component: () => import("./views/User/Permission.vue"),
      meta: {
        allowed: "user/roleAdmin",
      },
    },
    {
      name: "user-terms",
      path: "/user/terms/:id",
      component: () => import("./views/User/Terms.vue"),
      meta: {
        allowed: "user/termsAdmin",
      },
    },
    {
      name: "user-admin",
      path: "/user/admin/:section?",
      component: () => import("./views/User/Admin.vue"),
      meta: {
        allowed: "user/list",
      },
    },
    {
      name: "dev",
      path: "/dev",
      component: () => import("./views/Dev/Dev.vue"),
      beforeEnter: (to, from, next) => {
        if (process.env.NODE_ENV === "development") next();
      },
      children: [
        {
          path: "marcus",
          component: () => import("./views/Dev/Marcus.vue"),
        },
        {
          path: "andrew",
          component: () => import("./views/Dev/Andrew.vue"),
        },
        {
          path: "amin",
          component: () => import("./views/Dev/Amin.vue"),
        },
        {
          path: "george",
          component: () => import("./views/Dev/George.vue"),
        },
      ],
    },
    {
      name: "error-404",
      path: "*",
      component: () => import("./views/Error/404.vue"),
    },
    {
      name: "temp-fb",
      path: "/temp/fb",
      component: () => import("./views/Temp/Fb.vue"),
    },
  ],
});

router.onError(error => {
  if (/loading chunk chunk-[0-9a-z]{6,12} failed./i.test(error.message)) {
    window.location.reload();
  }
});

router.beforeEach((to, from, next) => {

  // When the user goes between AA configure and OA remember the OA screen and send them back if the same AA
  if (to.name === "client-aggregator-configure") {
    if(from.name === "client-aggregator-list" || from.name === "report-build-aa" || from.name === "troubleshooter-aggregator") {
      store.dispatch("oa/setConfigureFromPage", `/outcome/analytics/digital/${from.params.clientUuid}`);
    } else {
      store.dispatch("oa/setConfigureFromPage", from.path);
    }
  }
  if (from.name === "client-aggregator-configure" && to.path.substring(0, 19) ===
    "/outcome/analytics/" && to.params?.id) {
    if (to.path !== store.getters["oa/getConfigureFromPage"](to.params.id)) {
      return next({
        path: store.getters["oa/getConfigureFromPage"](to.params.id),
        replace: true,
      });
    }
  }
  next();
});

// Handle some of Vue routers design decisions
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {

    // If the user clicks a link to go to the same page honour that by reloading the page
    if (err.name === "NavigationDuplicated") {
      window.location.reload();

    // If we redirect the user don't send a Sentry error
    } else if (Router.isNavigationFailure(err) && err.message.substring(0, 26) === "Redirected when going from") {
      console.info(err.message)
      return err

    } else {
      // rethrow error
      return Promise.reject(err);
    }
  })
}

export default router;
