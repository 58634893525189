<template>
  <v-btn
    v-if="!!buttonType"
    :fab="buttonType === 'icon'"
    :text="buttonType === 'text'"

    :depressed="depressed"
    :disabled="disabled"
    :loading="loading"

    :min-height="height"
    :min-width="width"

    :dark="!disabled && dark"
    :light="!disabled && light"
    :color="getBackgroundColor()"
    class="sPageConfigButtonItem"
    :class="{
      sXSmallSize: xSmall,
      sSmallSize: small,
      sLargeSize: large,
      sXLargeSize: xLarge
    }"
    :style="`${getTextColor()}`"
    @click="$emit('clicked')"
  >
    <v-icon
      v-if="!!icon"
    >
      {{ icon }}
    </v-icon>
    <span>{{ label }}</span>
  </v-btn>
</template>

<script>
export default {
  name: "PageConfigButtonItem",
  props: {
    buttonType: {
      type: String,
      validator: function (value) {
        // The value must match one of these strings
        return ['default', 'icon', 'text'].indexOf(value) !== -1
      },
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    },
    depressed: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: [Number, String],
      default: ''
    },
    width: {
      type: [Number, String],
      default: ''
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    getTextColor() {
      if (this.dark || this.light) return

      let defaultTextColor = '';
      switch (this.buttonType) {
        case 'icon':
          defaultTextColor = '#2962FF';
          break;
        case 'default':
          defaultTextColor = '#FFF';
          break;
        case 'text':
          defaultTextColor = '#000';
      }
      defaultTextColor = this.textColor || defaultTextColor;

      return `color:${defaultTextColor};`;
    },
    getBackgroundColor() {
      if (this.dark || this.light) return

      let defaultBackgroundColor = '';
      switch (this.buttonType) {
        case 'icon':
          defaultBackgroundColor = '#F5F5F7';
          break;
        case 'default':
          defaultBackgroundColor = '#D9272D';
          break;
        case 'text':
          return this.getTextColor();
      }

      return this.backgroundColor || defaultBackgroundColor;
    }
  }
}
</script>

<style scoped lang="scss">
.sPageConfigButtonItem {
  height: 36px;
  font-weight: normal;
  font-size: 14px;
  text-transform: inherit;
  letter-spacing: 0;

  .v-icon {
    height: 20px;
    font-size: 20px;
    width: 20px;
  }

  &.v-btn--fab {
    width: 36px;
  }

  &.v-btn--text .v-btn__content span {
    text-decoration: underline;
  }

  &.sXSmallSize {
    height: 28px;
    font-size: 10px;

    &.v-btn--fab {
      width: 28px;
    }

    .v-icon {
      height: 16px;
      font-size: 16px;
      width: 16px;
    }
  }

  &.sSmallSize {
    height: 32px;
    font-size: 12px;

    &.v-btn--fab {
      width: 32px;
    }

    .v-icon {
      height: 18px;
      font-size: 18px;
      width: 18px;
    }
  }

  &.sLargeSize {
    height: 40px;
    font-size: 16px;

    &.v-btn--fab {
      width: 40px;
    }

    .v-icon {
      height: 22px;
      font-size: 22px;
      width: 22px;
    }
  }

  &.sXLargeSize {
    height: 44px;
    font-size: 18px;

    &.v-btn--fab {
      width: 44px;
    }

    .v-icon {
      height: 24px;
      font-size: 24px;
      width: 24px;
    }
  }

}
</style>