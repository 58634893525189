export const getLoginRoute = (saveCurrentLocation = true, path = '') => {
    const domain = location.hostname.replace(/^https?\:\/\//,""); //removes http/s
    const returnPath = saveCurrentLocation ? `?returnPath=${encodeURIComponent(location.href)}` : '';

    switch (domain){
        case 'portal.local':
            return `http://login.portal.local:8069/${path}${returnPath}`;
        case 'alpha-portal.fundamentalmedia.com':
            return `https://login-alpha.fundamentalmedia.com/${path}${returnPath}`;
        case 'beta-portal.fundamentalmedia.com':
            return `https://login-beta.fundamentalmedia.com/${path}${returnPath}`;
        case 'demo-portal.fundamentalmedia.com':
            return `https://login-demo.fundamentalmedia.com/${path}${returnPath}`;
        case 'release-portal.fundamentalmedia.com':
            return`https://login-release.fundamentalmedia.com/${path}${returnPath}`;
        case 'staging-portal.fundamentalmedia.com':
            return `https://login-staging.fundamentalmedia.com/${path}${returnPath}`;
        case 'portal.fundamentalmedia.com':
        default:
            return `https://login.fundamentalmedia.com/${path}${returnPath}`;
    }
}

export default {
    getLoginRoute
}