import Api from "../connect/api";
export default function(action, data) {
  if (action === "page") {
    Api.post(
      "logger/page",
      {
        url: window.location.href,
        userAgent: navigator.userAgent
      },
      () => {},
      () => {},
      () => {}
    );
  } else {
    Api.post(
      "logger/action",
      {
        reference: action,
        url: window.location.href,
        data: data
      },
      () => {},
      () => {},
      () => {}
    );
  }
}
