import CoreApi from "../../connect/api";
import {unique} from '@fundamentalmedia/fundahelper/Array';

const fetchAa = filters => {
  return CoreApi.postPromise("client/aggregator/issues", data(filters), {
    allowFail: true,
  });
};

const fetchAmx = filters => {
  return CoreApi.postPromise("client/aggregator/amx", data(filters), {
    allowFail: true,
  });
};

const fetchBooking = filters => {
  return CoreApi.postPromise("doubleclick/booking/issues", data(filters), {
    allowFail: true,
  });
};

const fetchCreativeSetUnlinked = (filters) => {
  return CoreApi.postPromise(
    "creative/set/unlinked",
    data(filters, {additional: {recentlyServedDays: 14}}),
    {allowFail: true},
  );
};

const fetchFMXIO = (filters, live) => {
  return CoreApi.postPromise(
    "doubleclick/bidmanager/insertionorder/active",
    data(filters, {fmx: true, additional: {live}}),
    {allowFail: true},
  );
};

const fetchFMXLI = (filters, live) => {
  return CoreApi.postPromise(
    "doubleclick/bidmanager/lineitem/active",
    data(filters, {fmx: true, additional: {live}}),
    {allowFail: true},
  );
};

const fetchPlacementUrlInvalid = filters => {
  return CoreApi.postPromise("doubleclick/placement/url/invalid", data(filters), {
    allowFail: true,
  });
};

const fetchAmxBudget = filters => {
  return CoreApi.postPromise("amx/budget", data(filters), {
    allowFail: true,
  });
};

const fetchAmxPacing = filters => {
  return CoreApi.postPromise("amx/pacing", data(filters), {
    allowFail: true,
  });
};

const fetchAmxTargeting = filters => {
  return CoreApi.postPromise("amx/targeting", data(filters), {
    allowFail: true,
  });
};

const data = (filters, options = {}) => {
  const {additional, fmx} = options;
  const data              = {...additional || {}};
  if (fmx) {
    data.status = [];
    if (filters.fmxStatus === "active") {
      data.status.push("active");
    }
    if (filters.fmxStatus === "paused") {
      data.status.push("paused");
    }
  }
  if (filters.aggregator.length > 0) data.aaId = filters.aggregator;
  if (filters.booking.length > 0) data.bookingUniqueNumber = filters.booking;
  if (filters.client.length > 0) data.clientUuid = filters.client;
  if (filters.clientOffice.length > 0) data.officeId = filters.clientOffice;
  if (filters.opportunityType?.length > 0) data.opportunityType = filters.opportunityType;
  return data;
};

export class CoreTroubleshooterAggregatorData {
  constructor(filters) {
    this.booking    = [];
    this.filters    = filters;
    this.fmx        = {
      io: false,
      li: false,
      upcomingIo: false,
      upcomingLi: false,
    };
    this.placement  = {urlInvalid: []};
    this.creative   = {setUnlinked: []};
    this.connection = null;
    this.aa = null;
    this.opportunityType = [];
    this.amx = null;
    this.amxPacing = null;
    this.amxTargeting = null;
    this.amxBudget = null;
  }

  async fetchData(access) {
    return new Promise(async(resolve) => {
      await Promise.all([
        fetchBooking(this.filters),
        fetchFMXIO(this.filters, this.filters.fmxStatus === "active" ? "live" : "all"),
        fetchFMXLI(this.filters, this.filters.fmxStatus === "active" ? "live" : "all"),
        fetchFMXIO(this.filters, "upcoming"),
        fetchFMXLI(this.filters, "upcoming"),
        fetchPlacementUrlInvalid(this.filters),
        fetchCreativeSetUnlinked(this.filters),
        fetchAa(this.filters),
        fetchAmx(this.filters),
        fetchAmxBudget(this.filters),
        fetchAmxPacing(this.filters),
        fetchAmxTargeting(this.filters)
      ]).then(data => {
        this.booking              = data[0] || false;
        this.fmx.io               = data[1] || false;
        this.fmx.li               = data[2] || false;
        this.fmx.upcomingIo       = data[3] || false;
        this.fmx.upcomingLi       = data[4] || false;
        this.placement.urlInvalid = data[5] || false;
        this.creative.setUnlinked = data[6] || [];
        this.aa = data[7] || [];
        this.amx = data[8] || [];
        this.amxBudget = data[9] || [];
        this.amxPacing = data[10] || [];
        this.amxTargeting = data[11] || [];
        if (!(access && access.troubleshooter && access.troubleshooter.insights)) {
          return resolve();
        }
        CoreApi.post(
          `client/link/connection`,
          {
            bookingUniqueNumber: unique([
              ...(this.booking ? this.booking.list : []).map(r => r.bookingUniqueNumber),
            ]),
            creativeId: unique([
              ...(this.creative.setUnlinked || []).map(r => r.id),
            ]),
            placementId: unique([
              ...(this.placement.urlInvalid || []).map(r => r.placementId),
              ...(this.booking ? this.booking.outOfRange : []).map(r => r.placementId),
              ...(this.booking ? this.booking.trafficLastYear : []).map(r => r.placementId),
              ...(this.booking ? this.booking.trafficType : []).map(r => r.placementId),
              ...(this.booking ? this.booking.unmatchedPlacement : []).map(r => r.placementId),
            ]),
            insertionOrderId: unique([
              ...(this.fmx.io || []).map(r => r.insertionOrderId),
              ...(this.fmx.upcomingIo || []).map(r => r.insertionOrderId),
            ]),
            lineItemId: unique([
              ...(this.fmx.li || []).map(r => r.line_item_id),
              ...(this.fmx.upcomingLi || []).map(r => r.line_item_id),
            ]),
            aaUuid: unique(this.aa.map(r => r.uuid)),
            campaignId: unique([
              ...(this.amxBudget || []).map(r => r.campaignId),
              ...(this.amxPacing.campaigns || []).map(r => r.campaignId),
            ]),
            amxAlternativeId: unique(this.amx.map(r => r.amxAlternativeId)),
            amxLineItemId: unique([
              ...(this.amxPacing.lineItems || []).map(r => r.amxLineItemId),
              ...(this.amxPacing.liBudgetSetting || []).map(r => r.amxLineItemId),
              ...(this.amxTargeting.lineItemTargeting || []).map(r => r.amxLineItemId),
            ]),
          },
          (connection) => {
            this.connection = connection;
            resolve();
          },
          () => {
            this.connection = false;
            resolve();
          },
        );
      });
    });
  }
}
