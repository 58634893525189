<template>
  <onehelp-menu-simple
    v-bind="$props"
    :header-icons="headerIcons"
    :back-route="backRoute"
    has-search
    v-on="$listeners"
  >
    <loader-fullpage
      v-if="article === null"
      style="height: 572px"
    />
    <div
      v-else
      class="sContent"
    >
      <div class="sContentTitle">
        <h2>{{ article.title }}</h2>
      </div>
      <div class="sItem">
        <!-- eslint-disable -->
        <div
          v-if="article.type === 'content'"
          v-html="article.contentHtml"
        />
        <!-- eslint-enable -->
        <div v-else-if="article.type === 'link'">
          <span>Article link - </span>
          <a
            :href="article.link"
            target="_blank"
          >
            {{ article.link }}
          </a>
          <i class="fal fa-external-link" />
        </div>
      </div>
    </div>
  </onehelp-menu-simple>
</template>

<script>
import Api from "@/connect/api";
import OnehelpMenuSimple from "@/components/Onehelp/Menu/Simple";

export default {
  name: "OnehelpMenuDetail",
  components: {OnehelpMenuSimple},
  props: {
    articleId: {
      type: String,
      required: true
    },
    articleList: {
      type: Array,
      default: null
    },
    category: {
      type: Object,
      default: null
    },
    headerIcons: {
      type: Array,
      required: true
    },
    helpMenu: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      backRoute: this.category ? {
        text: this.category.reference,
        route: {name: 'list', reference: this.category.reference, type: this.category.type, id: this.category.id}
      } : {
        text: 'All articles',
        route: 'list'
      },
      article: null,
    }
  },
  mounted() {
    Api.get(
      `onehelp/article/${this.articleId}`,
      article => {
        this.article = article;
      },
      () => {
        this.$emit('routeChange', 'list')
      },
    );
  },
}
</script>

<style scoped lang="scss">

.sContent{
  max-height: 640px;
  padding: 12px 30px 22px;

  .sContentTitle{
    padding-bottom: 8px;
    padding-top: 24px;
    color: #343434;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
  }

  .sItem{
    max-height: 446px;
    margin-top: 12px;
    overflow-y: auto;

    @media (max-height: 840px) {
      max-height: 250px;
    }

    span{
      color: #5B5B5B;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
}
</style>