<template>
  <div>
    <!--    todo: ask about this style-->
    <!--  <div class="pt-3 mt-1">-->
    <!-- ex card -->
    <v-menu
      v-model="menu"
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <div :class="disabled ? 'sDisabled' : ''">
          <div
            class="d-flex justify-space-between sInput-Wrapper sUnderline align-center mb-2"
            :class="[(filled ? 'sFilled px-3' : ''), (outlined ? 'sOutlined' : '')]"
            v-on="triggerEvents(on)"
          >
            <div
              class="d-flex font-size-18 sLabel align-content-center"
              :class="[(selectedCount > 0 || propCheckboxSelected ? 'sDarker-Label' : ''), (outlined ? 'pl-3' : '')]"
            >
              {{ label }}
            </div>
            <div class="justify-space-between d-flex">
              <div
                v-if="selectedCount > 0 && !propCheckboxSelected"
                class="pa-4 d-inline-flex slabel"
              >
                <span class="font-size-14">{{ selectedCount }} selected </span>
              </div>
              <checkbox
                v-if="hasCheckboxLabel"
                :label="checkboxLabel"
                :class="
                  selectedCount > 0 || propCheckboxSelected
                    ? 'sDarker-Label'
                    : ''
                "
                class="sAlign-center d-inline-flex sLabel mr-4"
                :checked="propCheckboxSelected"
                @change="toggleCustomOption"
              />
              <div
                v-if="loading"
                class="loading-spinner"
                :class="propCheckboxSelected || disabled ? 'sDisabled' : ''"
              />
              <div
                v-else
                class="blue-text font-size-14 sAlign-center d-inline-flex align-center sButton"
                :class="propCheckboxSelected || disabled ? 'sDisabled' : ''"
              >
                <v-icon
                  :class="propCheckboxSelected || disabled ? 'sDisabled' : ''"
                >
                  mdi-menu-down
                </v-icon>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="sBg-white">
        <div class="d-flex justify-space-between sDivider align-center">
          <div class="d-flex flex-grow-1 sBg-grey sPa-8 sBg-search">
            <i class="far fa-search grey-text sPa-8 d-flex align-center" />
            <input
              ref="search"
              v-model="search"
              placeholder="Search Dashboards"
              class="sPa-8 d-flex flex-grow-1 "
            >
          </div>
          <div class="sJustify-center sAlign-center grey-text sPa-8">
            <span
              class="sPr-10 sButton"
              @click="selectAll"
            >
              <i class="fas fa-check-square blue-text sPa-8" />
              {{ hasSearch ? "Select searched" : "Select all" }}
            </span>
            <span
              class="sPr-10 sButton"
              @click="selectNone"
            >
              <i class="far fa-square grey-text sPa-8" />
              {{ hasSearch ? "Deselect searched" : "Select none" }}
            </span>
          </div>
        </div>
        <div class="sPanel">
          <h4
            v-if="displayItemsCount === 0"
            class="grey-text"
          >
            No results found for your search criteria.
          </h4>
          <div
            v-for="item of displayItems"
            :key="item[itemValue]"
          >
            <checkbox
              :id="item[itemValue]"
              :label="itemLabelText(item)"
              :title="item[itemText]"
              :value="item[itemValue]"
              :checked="isChecked(item[itemValue])"
              class="mt-2 font-size-14"
              @change="handleSelect($event, item[itemValue])"
            />
          </div>
        </div>
        <v-divider />
        <div class="d-flex justify-space-between sPa-24">
          <span
            class="sButton sPa-12 grey-text"
            @click="selected = []"
          >CLEAR ALL ({{ selectedCount }})
          </span>
          <div>
            <span
              class="sButton sPa-12 blue-text"
              @click="cancel"
            >CANCEL
            </span>
            <span
              class="sButton bg-blue br-5 sPa-10 white-text"
              @click="menu = false"
            >APPLY FILTERS
            </span>
          </div>
        </div>
      </div>
    </v-menu>
    <div class="sText-Details" />
  </div>
</template>

<script>
import Checkbox from "../../components/Form/Checkbox";
import {uniqueKey} from '@fundamentalmedia/fundahelper/Array';

export default {
  name: "FormMultiSelect",
  components: { Checkbox },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    },
    itemText: {
      type: String,
      default: "text"
    },
    itemValue: {
      type: String,
      default: "value"
    },
    label: {
      type: String,
      default: "Multi Select"
    },
    maxLength: {
      type: Number,
      default: 50
    },
    checkboxLabel: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Boolean, Array],
      default: null
    }
  },
  data() {
    return {
      propCheckboxSelected: false,
      panel: false,
      displayItems: [],
      search: "",
      selected: [],
      previouslySelected: [],
      menu: false
    };
  },
  computed: {
    displayItemsCount() {
      return this.displayItems ? this.displayItems.length : 0;
    },
    hasCheckboxLabel() {
      return this.checkboxLabel && this.checkboxLabel.length > 0;
    },
    hasSearch() {
      return this.search && this.search.length > 0;
    },
    selectedCount() {
      return this.selected ? this.selected.length : 0;
    }
  },
  watch: {
    items: {
      handler: "itemSearch",
      immediate: true
    },
    search: {
      handler: "itemSearch"
    },
    value: {
      handler: "setValue",
      immediate: true
    },
    menu(val) {
      if (val === true) {
        this.previouslySelected = this.selected;
        this.search = "";
        setTimeout(() => {
          this.$refs.search.focus();
        }, 100);
      } else {
        this.$emit("input", this.selected);
      }
    }
  },
  methods: {
    itemLabelText(item) {
      if (!item && this.loading) return "Loading...";
      if (!item) return "Unknown value";
      if (
        this.maxLength &&
        item[this.itemText] &&
        item[this.itemText].length > this.maxLength
      ) {
        return item[this.itemText].substring(0, this.maxLength - 2) + "...";
      }
      return item[this.itemText];
    },
    itemSearch() {
      const items = uniqueKey(this.items, this.itemValue);
      if (!this.search || this.search === "") {
        this.displayItems = items;
      } else {
        const value = this.search.toLowerCase();
        this.displayItems = items.filter(o => {
          return o[this.itemText].toLowerCase().includes(value);
        });
      }
    },
    selectAll() {
      let items = [];
      if (this.hasSearch) {
        if (this.displayItemsCount === 0) return;
        items = this.displayItems;
        for (const item of this.displayItems) {
          if (this.selected.indexOf(item[this.itemValue]) < 0)
            this.selected.push(item[this.itemValue]);
        }
        return;
      }
      items = this.items;
      this.selected = items.map(i => i[this.itemValue]);
    },
    selectNone() {
      if (this.hasSearch) {
        const deselect = this.displayItems.map(o => o[this.itemValue]);
        this.selected = this.selected.filter(o => {
          return deselect.indexOf(o) < 0;
        });
      } else {
        this.selected = [];
      }
    },
    isChecked(value) {
      return this.selected.indexOf(value) > -1;
    },
    handleSelect(checked, value) {
      let index = this.selected.indexOf(value);
      if (checked && index < 0) {
        this.selected = [...this.selected, value];
      } else if (!checked && index > -1) {
        this.selected = this.selected.filter(s => s !== value);
      }
    },
    toggleCustomOption(value) {
      if (value) {
        this.propCheckboxSelected = true;
        this.$emit("input", true);
        this.menu = false;
      } else {
        this.propCheckboxSelected = false;
        this.$emit("input", this.selected);
      }
    },
    cancel() {
      this.menu = false;
      this.selected = this.previouslySelected;
    },
    setValue() {
      if (this.value === true && this.hasCheckboxLabel)
        this.propCheckboxSelected = true;
      else if (Array.isArray(this.value)) this.selected = this.value;
    },
    triggerEvents(val) {
      if (!this.propCheckboxSelected) return val;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/fundamental-variables.scss";
.sJustify-end {
  justify-content: flex-end;
}
.sAlign-center {
  align-items: center;
}
.sJustify-center {
  justify-content: center;
}
.sPa-24 {
  padding: 24px;
}
.sPa-12 {
  padding: 12px;
}
.sPa-10 {
  padding: 10px;
}
.sPa-8 {
  padding: 8px;
}
.sPr-10 {
  padding-right: 10px;
}
.font-size-14 {
  font-size: 14px;
}
.sBg-white {
  background-color: white;
}
.sFlex-grow {
  flex-grow: 2;
}
.sDivider {
  border-bottom: 1px solid lightgrey;
}
.grey-text {
  color: grey;
}
.blue-text {
  color: $colorBlue;
}
.white-text {
  color: white;
}
.bg-blue {
  background-color: $colorBlue;
}
.sButton {
  font-weight: bold;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
}
.sApplyButton {
  padding: 10px 16px;
}
/* always present */
.expand-enter-active,
.expand-leave-active {
  /*transform: scaleY(1);*/
  /*transform-origin: top;*/
  transition: all 0.2s ease;
  overflow: hidden;
}
/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter,
.expand-leave-to {
  /*transform: scaleY(0);*/
  padding: 0 10px;
  opacity: 0;
}
.sFilled {
  cursor: pointer;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.06);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
  transition-delay: 0s;
}
.sFilled:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
.sInput-Wrapper {
  cursor: pointer;
  min-height: 56px;
}
.sPanel {
  padding: 48px 64px;
  max-height: 500px;
  overflow: auto;
}
.sText-Details {
  min-height: 14px;
}
.sLabel {
  color: rgba(0, 0, 0, 0.6);
}
.sDarker-Label {
  color: rgba(0, 0, 0, 0.87);
}
.sUnderline {
  border-bottom: 1px solid grey;
  border-bottom-style: inset;
}
.sOutlined {
  border: 1px solid grey;
  border-radius: 3px;
  border-bottom-style: inset;
}
.sDisabled {
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
.letter-spacing {
  letter-spacing: 1.25px;
}
.br-5 {
  border-radius: 5px;
}
.loading-spinner {
  display: inline-block;
  width: 64px;
  height: 50px;
}
.loading-spinner:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 20px;
  border: 4px solid;
  border-color: lightgrey lightgrey lightgrey transparent;
  animation: loading-spinner 0.9s linear infinite;
}
.sBg-search {
  background-color: #eff1f4;
}
@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
