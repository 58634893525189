<template>
  <div>
    <h3
      :class="{ closed: !showLinks }"
      @click="showLinks = !showLinks"
    >
      <span>{{ title }}</span>
      <i
        v-if="showLinks"
        class="far fa-chevron-up"
      />
      <i
        v-else
        class="far fa-chevron-down"
      />
    </h3>
    <ul v-if="showLinks">
      <li
        v-for="(link, linkIndex) in links"
        :key="linkIndex"
        :title="link.help"
      >
        <div
          v-if="!link.configMenu"

          class="sLinkTitle"

          @click="() => goto(link)"
        >
          {{ link.title }}
        </div>
        <div
          v-else
        >
          <v-menu
            v-if="link.configMenu"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="sLinkTitle cursorPointer"

                v-bind="attrs"
                v-on="on"
              >
                <span>{{ link.title }}</span>
                <v-icon>fal fa-angle-right</v-icon>
              </div>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, i) in link.configMenu"
                :key="i"

                class="sMenuListItem"

                @click.prevent.stop="goto(item)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.text"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showLinks: true,
      selectedItem: null
    };
  },
  methods: {
    goto(link) {
      if (link.action) link.action();
      else this.$router.push(link.route);
      this.$emit("close");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../../scss/fundamental-variables.scss";

h3 {
  height: 50px;
  line-height: 50px;
  margin: 0;
  padding-left: 42px;
  background-color: $colorLightest;
  font-size: 1em;
  display: grid;
  grid-template-columns: 192px 41px;
  cursor: pointer;

  &.closed {
    border-bottom: solid 1px $colorLighter;
  }

  i {
    line-height: 50px;
    font-size: 1.2em;
    color: $colorBlue;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    background-color: $colorAlmostWhite;
    height: 50px;
    width: 100%;
    line-height: 50px;
    font-size: 0.9em;
    cursor: pointer;
    border-bottom: solid 1px $colorLightest;

    .sLinkTitle {
      padding-left: 55px;
      display: flex;
      justify-content: space-between;

      i {
        margin-right: 20px;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: $colorBlue;

      i {
        color: $colorBlue;
      }
    }
  }
}

.sMenuListItem {
  &.v-list-item:hover {
    color: $colorBlue !important;

    i {
      color: $colorBlue !important;
    }
  }
}
</style>
