<template>
  <div class="mainContainer">
    <loader-fullpage v-if="loading" />
    <div
      v-show="!loading"
      class="sRow"
    >
      <div class="col col1">
        <div class="mainSetup">
          <div class="clearAfter">
            <div class="logoLeft">
              <h4>Setup your report</h4>
              <v-select
                v-model="dateRange"
                label="Date Range"
                placeholder="Select some dates"
                :items="dateOptions"
                item-text="label"
                :return-object="true"
              >
                <template
                  slot="selection"
                  slot-scope="{ item }"
                >
                  {{ item.label }}
                </template>
                <template
                  slot="item"
                  slot-scope="{ item }"
                >
                  <div>
                    {{ item.label }}
                    <div v-if="item.value !== 'cr'">
                      {{ item.start.format(dateFormat) }} -
                      {{ item.end.format(dateFormat) }}
                    </div>
                  </div>
                </template>
              </v-select>

              <transition name="slide-down">
                <div
                  v-if="dateRange && dateRange.value === 'cr'"
                  class="customDate"
                >
                  <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    :return-value.sync="customDateRange"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="customDateRangeNice"
                        label="Custom Date Range"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="customDateRange"
                      range
                      :min="new Date(2017, 0, 1).toISOString()"
                      :max="new Date().toISOString()"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(customDateRange)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </transition>
            </div>
            <div class="logoRight">
              <img
                v-if="clientLogoUrl"
                :src="clientLogoUrl"
              >
            </div>
          </div>

          <transition name="slide-down">
            <v-autocomplete
              v-if="advertiserDetails"
              v-model="advertiser"
              label="Advertiser"
              :items="advertiserDetails"
              :disabled="advertiserDetails.length < 1"
              item-text="label"
              placeholder="Select an advertiser"
              :return-object="true"
              clearable
            />
          </transition>

          <transition name="slide-down">
            <div
              v-if="campaignDetails"
              class="selectAllBox"
            >
              <div class="selectAllSelect">
                <form-multi-select
                  v-model="campaign"
                  :items="campaignDetails"
                  :disabled="campaignDetails.length < 1"
                  label="Campaign"
                  item-text="label"
                  item-value="value"
                  :max-length="150"
                  checkbox-label="All & Future"
                />
              </div>
            </div>
          </transition>
          <transition name="slide-down">
            <div
              v-if="placementDetails"
              class="selectAllBox"
            >
              <div class="selectAllSelect">
                <form-multi-select
                  v-model="placement"
                  :items="placementDetails"
                  :disabled="placementDetails.length < 1"
                  label="Placement"
                  item-text="label"
                  item-value="value"
                  :max-length="250"
                  checkbox-label="All & Future"
                />
              </div>
            </div>
          </transition>
          <transition name="slide-down">
            <v-text-field
              v-if="complete"
              v-model="name"
              label="Report Name"
            />
          </transition>
          <transition name="slide-down">
            <div
              v-if="complete"
              style="padding: 27px 0; position: relative; height: 30px;"
            >
              <div style="position: absolute; bottom: 0;">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      size="medium"
                      type="secondary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Options&nbsp;
                      <i
                        v-if="optionsLoading"
                        class="fas fa-spinner"
                      />
                      <i
                        v-else
                        class="far fa-caret-square-down"
                      />
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in [{ id: 'raw', label: 'Download Raw Report' }]"
                      :key="index"
                      class="option-item"
                    >
                      <v-list-item-title @click="optionsChoice(item)">
                        {{ item.label }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div style="position: absolute; bottom: 0; right: 0">
                <v-btn
                  color="red"
                  elevation="9"
                  large
                  :loading="settingUp"
                  @click="setup()"
                >
                  Continue
                </v-btn>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="col col2">
        <div class="mainSetup">
          <h4>Advanced Settings</h4>

          <v-select
            v-model="mode"
            label="Select Range"
            :items="modeList"
            item-text="label"
            :return-object="true"
          >
            <template
              slot="item"
              slot-scope="{ item }"
            >
              <b>{{ item.label }}: </b> {{ item.description }}
            </template>
          </v-select>

          <v-select
            v-model="template"
            label="Template"
            :items="getTemplateList()"
            item-text="label"
            :return-object="true"
          >
            <template
              slot="item"
              slot-scope="{ item }"
            >
              <b>{{ item.label }}: </b> {{ item.description }}
            </template>
          </v-select>

          <v-select
            v-model="benchmarkRange"
            label="Benchmark Date Range"
            placeholder="Select some dates"
            :items="benchmarkOptions"
            item-text="label"
            :return-object="true"
          >
            <template
              slot="selection"
              slot-scope="{ item }"
            >
              {{ item.label }}
            </template>
            <template
              slot="item"
              slot-scope="{ item }"
            >
              <div>
                {{ item.label }}
                <div v-if="item.value !== 'cr'">
                  {{ item.start.format(dateFormat) }} -
                  {{ item.end.format(dateFormat) }}
                </div>
              </div>
            </template>
          </v-select>

          <v-text-field
            v-model="benchmarkImpression"
            label="Benchmark Impressions"
            type="number"
            :min="1000"
            :max="100000"
            :step="'1000'"
            :persistent-hint="true"
            hint="Exclude any placement, creative, etc from the benchmark with fewer than this many impressions"
          />

          <v-text-field
            v-model="benchmarkClickRate"
            label="Benchmark CTR"
            type="number"
            :min="0.1"
            :max="1"
            :step="'0.1'"
            :persistent-hint="true"
            hint="Exclude any placement, creative, etc from the benchmark with greater than this CTR"
          />

          <v-select
            v-model="reportImpressionsBy"
            label="Report Impressions By"
            :items="reportImpressionsByOptions"
            item-text="label"
            :return-object="true"
          />
        </div>
      </div>
    </div>
    <div class="sRow">
      <report-display-setup-alert
        v-if="complete && access.matchBookings"
        @vueAlert="$emit('vueAlert', $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import _ from "lodash";
import moment from "moment";
import { templateList } from "../../../data/report/templates";
import { rawExport } from "../../../process/report/download/raw";
import actionLog from "../../../logger/action";
import FormMultiSelect from "@/components/Form/MultiSelect";

const getDefaultReportSetupData = {
  advertiser: null,
  allCampaign: false,
  allPlacement: false,
  benchmarkClickRate: 0.5,
  benchmarkImpression: 10000,
  benchmarkOptions: [],
  benchmarkRange: [],
  campaign: [],
  customDateRange: [],
  dateRange: [],
  dateOptions: [],
  loading: false,
  logoSrc: null,
  mode: "",
  modeList: [
    {
      value: "presentation",
      label: "Presentation Mode",
      description:
        "For use when building a presentation, template pages can be altered and removed, and comments can be added."
    },
    {
      value: "report",
      label: "Reporting Mode",
      description:
        "For use when saving a report to run later, template pages cannot be altered or removed and comments cannot be added."
    }
  ],
  name: "Fundamental Report",
  placement: [],
  reportRange: null,
  reportImpressionsBy: "",
  settingUp: false,
  template: false,
  watchDisable: false
};

export default {
  components: {FormMultiSelect},
  /* eslint-disable-next-line vue/require-prop-types */
  props: ["loadAdvertiserId", "loadOptions", "loadName", "access"],
  data: function() {
    let data = {
      ...getDefaultReportSetupData,
      optionsLoading: false,
      reportImpressionsByOptions: [
        {
          value: "total",
          label: "Total DoubleClick Impressions"
        },
        {
          value: "viewable",
          label: "Limited to DoubleCLick Viewable Impressions"
        }
      ]
    };
    data.mode = data.modeList[0];
    return data;
  },
  computed: {
    ...mapState("report", [
      "advertiserDetails",
      "campaignDetails",
      "placementDetails",
      "advertiserId",
      "campaignIds",
      "placementIds",
      "setupComplete",
      "endDate",
      "startDate",
      "benchmarkStartDate",
      "benchmarkEndDate",
      "benchmarkCtr",
      "benchmarkImpressions"
    ]),
    ...mapGetters("report", ["clientLogoUrl", "clientName", "clientType"]),
    ...mapGetters("di", ["dateFormat"]),
    complete: function() {
      if (!this.dateRange) return false;
      if (this.dateRange.value === "cr" && this.customDateRange.length < 2)
        return false;
      if (!this.advertiserId) return false;
      if (!this.campaignIds) return false;
      if (!this.placementIds) return false;
      return true;
    },
    customDateRangeNice() {
      if (this.customDateRange.length < 2) return "";
      const startDate = moment(this.customDateRange[0]);
      const endDate = moment(this.customDateRange[1]);
      return (
        startDate.format(this.dateFormat) +
        " - " +
        endDate.format(this.dateFormat)
      );
    }
  },
  watch: {
    advertiser: function(newValue, oldValue) {
      if (!oldValue && !newValue) return;
      if (this.watchDisable) return true;
      this.campaign = [];
      this.placement = [];
      this.allCampaign = false;
      this.allPlacement = false;
      this.setAdvertiser({ advertiserId: this.advertiser && this.advertiser.value });
    },
    benchmarkClickRate: {
      handler: "setBenchmarkCtr",
      immediate: true
    },
    benchmarkImpression: {
      handler: "setBenchmarkImpressions",
      immediate: true
    },
    benchmarkRange: function() {
      this.updateBenchmarkDate();
    },
    campaign: function(newValue, oldValue) {
      if (Array.isArray(oldValue) && !oldValue.length && Array.isArray(newValue) && !newValue.length) return;
      if (this.watchDisable) return true;
      let returnedCampaign = []
      if(this.campaign === true || this.campaign === false){
        returnedCampaign = this.campaign ? _.map(this.campaignDetails, "value").join(",") : null
        if(this.campaign === false){
          this.placement = [];
          this.allPlacement = false;
        }
        this.setAllCampaign()
      }else{
        this.placement = [];
        this.allPlacement = false;
        returnedCampaign = this.campaign[0] && this.campaign[0]['value'] ? _.map(this.campaign, "value").join(","): this.campaign.join(",")
      }
      this.setCampaign({ campaignIds: returnedCampaign});
    },
    customDateRange: function() {
      if (this.watchDisable) return true;
      this.updateDate();
    },
    dateRange: function() {
      if (this.watchDisable) return true;
      this.updateDate();
    },
    loadOptions: {
      handler: function() {
        if (!this.loadOptions) return;
        let $this = this;
        let setting = {
          advertiserId: this.loadAdvertiserId,
          campaignAll: this.loadOptions.campaignAll,
          campaignIds: Array.isArray(this.loadOptions.campaignIds)
            ? this.loadOptions.campaignIds.join()
            : null,
          placementAll: this.loadOptions.placementAll,
          placementIds: Array.isArray(this.loadOptions.placementIds)
            ? this.loadOptions.placementIds.join()
            : null,
          value: this.loadOptions.typeDate,
          autoComplete: function() {
            $this.watchDisableSet(true);
            $this.dateRange = _.find($this.dateOptions, {
              value: $this.loadOptions.typeDate
            });
            if ($this.loadOptions.typeDate === "cr") {
              $this.customDateRange = [
                $this.loadOptions.customStartDate,
                $this.loadOptions.customEndDate
              ];
            }
            $this.advertiser = _.find($this.advertiserDetails, {
              value: $this.loadAdvertiserId
            });
            if ($this.loadOptions.campaignAll === "true") {
              $this.allCampaign = true;
              $this.campaign = $this.campaignDetails;
            } else {
              let campaigns = [];
              _.forEach($this.loadOptions.campaignIds, function(campaignId) {
                let campaign = _.find($this.campaignDetails, {
                  value: campaignId * 1
                });
                if (campaign) campaigns.push(campaign);
              });
              $this.campaign = campaigns;
            }
            if ($this.loadOptions.placementAll === "true") {
              $this.allPlacement = true;
              $this.placement = $this.placementDetails;
            } else {
              let placements = [];
              _.forEach($this.loadOptions.placementIds, function(placementId) {
                let placement = _.find($this.placementDetails, {
                  value: placementId * 1
                });
                if (placement) placements.push(placement);
              });
              $this.placement = placements;
            }
            $this.name = $this.loadName;
            if ($this.loadOptions.mode) {
              $this.mode = _.find($this.modeList, {
                value: $this.loadOptions.mode
              });
            }
            $this.benchmarkRange = _.find($this.dateOptions, {
              value: $this.loadOptions.benchmarkTypeDate
            });
            $this.benchmarkImpression = $this.loadOptions.benchmarkImpressions;
            $this.benchmarkClickRate = $this.loadOptions.benchmarkCtr;
            if ($this.loadOptions.reportImpressionsBy) {
              $this.reportImpressionsBy = _.find(
                $this.reportImpressionsByOptions,
                { value: $this.loadOptions.reportImpressionsBy }
              );
            }
            $this.markSetupComplete(true);
            setTimeout(function() {
              $this.watchDisableSet(false);
            }, 1000);
          }
        };
        if (this.loadOptions.typeDate === "cr") {
          setting.start = this.loadOptions.customStartDate;
          setting.end = this.loadOptions.customEndDate;
        } else {
          let dateRange = _.find(this.dateOptions, {
            value: this.loadOptions.typeDate
          });
          setting.start = dateRange.start.format("YYYY-MM-DD");
          setting.end = dateRange.end.format("YYYY-MM-DD");
        }
        this.setDate(setting);

        if (this.loadOptions.benchmarkTypeDate === "cr") {
          this.setBenchmarkDate({
            start: this.loadOptions.benchmarkCustomStartDate,
            end: this.loadOptions.benchmarkCustomEndDate,
            value: "cr"
          });
        } else {
          let dateRange = _.find(this.dateOptions, {
            value: this.loadOptions.benchmarkTypeDate
          });
          this.setBenchmarkDate({
            start: dateRange.start.format("YYYY-MM-DD"),
            end: dateRange.end.format("YYYY-MM-DD"),
            value: dateRange && dateRange.value
          });
        }
        this.setBenchmarkCtr(this.loadOptions.benchmarkCtr);
        this.setBenchmarkImpressions(this.loadOptions.benchmarkImpressions);

        let template = _.find(templateList, { id: this.loadOptions.template });
        if (template) {
          this.template = { label: template.name, value: template.id };
          this.setTemplate(template);
        }
      },
      immediate: true
    },
    mode: {
      handler: function() {
        this.setMode(this.mode && this.mode.value);
      },
      immediate: true
    },
    name: {
      handler: "setName",
      immediate: true
    },
    placement: function(newValue, oldValue) {
      if (Array.isArray(oldValue) && !oldValue.length && Array.isArray(newValue) && !newValue.length) return;
      if (this.watchDisable) return true;
      let returnedPlacement
      if(this.placement === true || this.placement === false){
        returnedPlacement = this.placement ? _.map(this.placementDetails, "value").join(",") : null
        this.setAllPlacement()
      }else{
        returnedPlacement = this.placement[0] && this.placement[0]['value'] ? _.map(this.placement, "value").join(","): this.placement.join(",")
      }
      this.setPlacement(returnedPlacement);
    },
    reportImpressionsBy() {
      this.setReportImpressionsBy(this.reportImpressionsBy && this.reportImpressionsBy.value);
    },
    setupComplete: function() {
      this.settingUp = false;
    }
  },
  mounted: function() {
    if (!this.reportImpressionsBy)
      this.reportImpressionsBy = this.reportImpressionsByOptions[0];
    this.updateBenchmarkDate();
  },
  created: function() {
    if (this.dateOptions.length === 0) {
      this.dateOptions.push({
        value: "wtd",
        label: "Week to Date",
        start: moment().day(1),
        end: moment()
      });
      this.dateOptions.push({
        value: "tm",
        label: "This Month",
        start: moment().date(1),
        end: moment()
      });
      this.dateOptions.push({
        value: "lm",
        label: "Last Month",
        start: moment()
          .subtract(1, "months")
          .date(1),
        end: moment()
          .subtract(1, "months")
          .endOf("month")
      });
      this.dateOptions.push({
        value: "l3m",
        label: "Last 3 Months",
        start: moment()
          .subtract(3, "months")
          .date(1),
        end: moment()
          .subtract(1, "months")
          .endOf("month")
      });
      this.dateOptions.push({
        value: "l6m",
        label: "Last 6 Months",
        start: moment()
          .subtract(6, "months")
          .date(1),
        end: moment()
          .subtract(1, "months")
          .endOf("month")
      });
      this.dateOptions.push({
        value: "ytd",
        label: "Quarter to Date",
        start: moment()
          .quarter(moment().quarter())
          .startOf("quarter"),
        end: moment()
      });
      this.dateOptions.push({
        value: "ytd",
        label: "Year to Date",
        start: moment()
          .month(0)
          .date(1),
        end: moment()
      });
      this.dateOptions.push({
        value: "ly",
        label: "Last Year",
        start: moment()
          .subtract(1, "year")
          .month(0)
          .date(1),
        end: moment()
          .subtract(1, "year")
          .month(11)
          .endOf("month")
      });
      this.dateOptions.push({
        value: "cr",
        label: "Custom Range"
      });

      this.benchmarkRange = _.find(this.dateOptions, { value: "l6m" });

      this.benchmarkOptions.push(_.find(this.dateOptions, { value: "lm" }));
      this.benchmarkOptions.push(_.find(this.dateOptions, { value: "l3m" }));
      this.benchmarkOptions.push(_.find(this.dateOptions, { value: "l6m" }));
      this.benchmarkOptions.push(_.find(this.dateOptions, { value: "ly" }));
    }
  },
  methods: {
    ...mapActions("report", [
      "setDate",
      "setBenchmarkDate",
      "setAdvertiser",
      "setCampaign",
      "setPlacement",
      "markSetupComplete"
    ]),
    ...mapMutations("report", [
      "setBenchmarkImpressions",
      "setBenchmarkCtr",
      "setCampaignAll",
      "setPlacementAll",
      "setMode",
      "setName",
      "setReportImpressionsBy",
      "setTemplate"
    ]),
    optionsChoice(choice) {
      let $this = this;
      switch (choice.id) {
        case "raw":
          this.optionsLoading = true;
          rawExport(
            this.startDate,
            this.endDate,
            this.advertiserId,
            this.campaignIds.split(","),
            this.placementIds.split(","),
            this.benchmarkStartDate,
            this.benchmarkEndDate,
            this.clientType,
            this.benchmarkClickRate,
            this.benchmarkImpression
          ).then(
            raw => {
              let link = document.createElement("a");
              link.setAttribute("href", encodeURI(raw));
              link.setAttribute("download", $this.clientName + ".csv");
              document.body.appendChild(link);
              link.click();
              this.optionsLoading = false;
            },
            error => {
              $this.$emit("message", {
                message: "Failed download request: " + error
              });
              this.optionsLoading = false;
            }
          );
      }
    },
    setAllCampaign: function() {
      if (this.allCampaign === false) {
        this.allCampaign = "sticky";
        this.setCampaignAll(true);
      } else {
        this.allCampaign = false;
        this.setCampaignAll(false);
      }
    },
    setAllPlacement: function() {
      if (this.allPlacement === false) {
        this.allPlacement = true;
        this.setPlacementAll(true);
      } else {
        this.allPlacement = false;
        this.setPlacementAll(false);
      }
    },
    setup: function() {
      if (this.benchmarkImpression < 1000) this.benchmarkImpression = 1000;
      else if (this.benchmarkImpression > 100000)
        this.benchmarkImpression = 100000;

      if (this.benchmarkClickRate < 0.1) this.benchmarkClickRate = 0.1;
      else if (this.benchmarkClickRate > 1) this.benchmarkClickRate = 1;

      let template = _.find(templateList, { id: this.template && this.template.value });
      this.setTemplate(typeof template === "object" ? template : null);

      this.settingUp = true;
      this.markSetupComplete(true);
      actionLog("report-setup", {
        startDate: this.startDate,
        endDate: this.endDate,
        advId: this.advertiserId,
        campaignIds: this.campaignIds,
        placementIds: this.placementIds,
        benchmarkStartDate: this.benchmarkStartDate,
        benchmarkEndDate: this.benchmarkEndDate,
        benchmarkCtr: this.benchmarkCtr,
        benchmarkImpressions: this.benchmarkImpressions,
        reportImpressionsBy: this.reportImpressionsBy
      });
    },
    getTemplateList: function() {
      let templates = [];
      _.forEach(templateList, function(template) {
        templates.push({
          value: template.id,
          label: template.name,
          description: template.description
        });
      });
      if (this.template === false) this.template = templates[0];
      return templates;
    },
    updateDate: function() {
      this.advertiser = [];
      this.placement = [];
      this.campaign = [];
      let $this = this;
      let $allCampaign = this.allCampaign;
      let $allPlacement = this.allPlacement;
      let setting = {
        advertiserId: this.advertiserId,
        campaignAll: this.allCampaign,
        campaignIds: this.campaignIds ? this.campaignIds : null,
        placementAll: this.allPlacement,
        placementIds: this.placementIds ? this.placementIds : null,
        autoComplete: function() {
          $this.watchDisableSet(true);
          $this.advertiser = _.find($this.advertiserDetails, {
            value: $this.advertiserId * 1
          });
          if ($allCampaign !== false) {
            $this.allCampaign = true;
            $this.campaign = $this.campaignDetails;
          } else if ($this.campaignIds) {
            let campaigns = [];
            _.forEach($this.campaignIds, function(campaignId) {
              let campaign = _.find($this.campaignDetails, {
                value: campaignId * 1
              });
              if (campaign) campaigns.push(campaign);
            });
            $this.campaign = campaigns;
          }
          if ($allPlacement !== false) {
            $this.allPlacement = true;
            $this.placement = $this.placementDetails;
          } else if ($this.placementIds) {
            let placements = [];
            _.forEach($this.placementIds, function(placementId) {
              let placement = _.find($this.placementDetails, {
                value: placementId * 1
              });
              if (placement) placements.push(placement);
            });
            $this.placement = placements;
          }
          setTimeout(function() {
            $this.watchDisableSet(false);
          }, 100);
        }
      };
      if (
        this.dateRange &&
        this.dateRange.value === "cr" &&
        this.customDateRange.length === 2
      ) {
        setting.start = this.customDateRange[0];
        setting.end = this.customDateRange[1];
        setting.value = this.dateRange.value;
      } else if (this.dateRange && this.dateRange.value !== "cr") {
        setting.start = this.dateRange.start.format("YYYY-MM-DD");
        setting.end = this.dateRange.end.format("YYYY-MM-DD");
        setting.value = this.dateRange && this.dateRange.value;
      } else {
        this.setDate({ start: null, end: null, value: null });
        return true;
      }
      this.setDate(setting);
    },
    updateBenchmarkDate: function() {
      if (
        typeof this.benchmarkRange.start === "object" &&
        typeof this.benchmarkRange.end === "object"
      ) {
        this.setBenchmarkDate({
          start: this.benchmarkRange.start.format("YYYY-MM-DD"),
          end: this.benchmarkRange.end.format("YYYY-MM-DD"),
          value: this.benchmarkRange && this.benchmarkRange.value
        });
      } else {
        this.benchmarkRange = _.find(this.dateOptions, { value: "l6m" });
      }
    },
    watchDisableSet: function(bool) {
      this.watchDisable = bool;
    }
  }
};
</script>

<style type="text/css" lang="sass">
@import "../../../scss/fundamental-variables.scss"

.customDate
  input
    width: 90%
    padding-left: 25px
    height: 2.2em
    font-size: 1em
  i
    top: 13px !important
</style>

<style scoped lang="sass">
@import "../../../scss/fundamental-variables.scss"

.sRow:before, .group:after
  content: ""
  display: table

.sRow:after
  clear: both

.sRow
  clear: both
  padding: 0
  margin: 0 auto
  width: 100%
  zoom: 1

.col
  display: block
  float: left
  margin: 1% 0 1% 1.6%

.col:first-child
  margin-left: 0

.col1
  width: 49.2%

.col2
  width: 49.2%

@media only screen and (min-width: 1200px)
  .sRow
    width: 70%

@media only screen and (max-width: 750px)
  .col
    margin: 1% 0 1% 0
  .col1, .col2
    width: 100%

h4
  margin: 0 0 10px
  font-family: $fontFamily
  font-size: 1.3em

.clearAfter:after
  display: block
  content: ""
  clear: both

.mainContainer
  padding: 20px

.mainSetup
  background-color: white
  border: solid 1px $colorLight
  padding: 10px
  h4
    color: $colorMediumDark

.logoLeft
  width: 50%
  float: left

.logoRight
  width: 50%
  float: left
  img
    max-height: 100px
    max-width: 220px
    float: right

.customDate
  padding-bottom: 15px
  position: relative
  i
    position: absolute
    z-index: 1
    top: 7px
    left: 7px
    color: $colorMediumDark

.selectAllBox
  position: relative

.selectAllSelect
  display: inline-block
  width: 100%

.toggle
  display: inline-block
  position: absolute
  right: 0
  bottom: 13px
  width: 82px
  height: 39px
  border: 3px solid #f9f9f9
  .btn
    display: block
    position: absolute
    z-index: 4
    top: -2px
    left: -1px
    width: 37px
    height: 37px
    background: #F5F5F5
    border: 1px solid #e8e8e8
    box-shadow: 0 2px 4px rgba(100, 100, 100, 0.1)
    transition: left .25s ease-in-out
  .texts
    position: absolute
    top: 9px
    z-index: 2
    width: 100%
    font-family: Arial, sans-serif
    font-weight: bold
    color: #fff
    font-size: 14px
    &:before
      content: "All"
      position: absolute
      left: 12px
    &:after
      content: "All"
      position: absolute
      right: 12px
  .bg
    display: block
    background: $colorLightest
    position: absolute
    z-index: 0
    top: 0
    left: 0
    width: 100%
    height: 100%
    box-shadow: inset 0 0px 2px 1px rgba(0, 0, 0, 0.3)
    transition: background .25s ease-in
  .bg-on
    background: $colorImpact
  .btn-on
    left: 44px

::v-deep .option-item:hover
  background-color: #F44336
  cursor: pointer
</style>
