<template>
  <onehelp-menu-simple
    v-bind="$props"
    :header-icons="headerIcons"
    :back-route="backRoute"
    :container-style="formType ? {maxHeight: '902px'} : {maxHeight: '862px'} "
    has-search
    v-on="$listeners"
  >
    <div class="sContent">
      <div
        v-if="formType"
        class="sSwitch"
      >
        <div
          class="sFeedback"
          :class="formType === 'feedback' ? 'sSelected' : null"
          @click="formType = 'feedback'"
        >
          <span>Feedback</span>
        </div>
        <div
          class="sFeature"
          :class="formType === 'new-feature' ? 'sSelected' : null"
          @click="formType = 'new-feature'"
        >
          <span>New feature</span>
        </div>
      </div>
      <div class="sContentTitle">
        <h2>{{ subTitle }}</h2>
      </div>
      <div>
        <v-autocomplete
          v-model="productField"
          class="pt-6"
          autofocus
          :items="formProducts"
          item-text="name"
          item-value="id"
          :label="productLabel"
          outlined
        />
        <v-autocomplete
          v-model="areaField"
          :disabled="!productField"
          :items="filteredArea ? filteredArea.areas : []"
          item-text="name"
          item-value="id"
          label="Which area?"
          outlined
        />
        <v-textarea
          v-model="message"
          outlined
          height="187"
          label="Describe the issue in detail"
        />
        <v-card
          flat
          class="sAttachmentCard"
        >
          <v-file-input
            v-model="fileInput"
            class="py-0 mx-2"
            placeholder="Pick a document"
            prepend-icon="mdi-attachment"
            multiple
            show-size
            hint="Screenshots or documents"
            persistent-hint
            label="Add Attachments"
          />
        </v-card>
        <div class="sActions">
          <page-config-button-text
            label="Cancel"
            @clicked="$emit('routeChange', 'home')"
          />
          <page-config-button-default
            label="Send"
            :disabled="!productField || !message"
            :loading="loading"
            dark
            class="mx-5"
            @clicked="sendForm"
          />
        </div>
      </div>
    </div>
  </onehelp-menu-simple>
</template>

<script>
import Api from "@/connect/api";
import OnehelpMenuSimple from "@/components/Onehelp/Menu/Simple";
import PageConfigButtonDefault from "@/components/Page/Config/Button/Default";
import PageConfigButtonText from "@/components/Page/Config/Button/Text";

export default {
  name: "OnehelpMenuFrom",
  components: {PageConfigButtonText, PageConfigButtonDefault, OnehelpMenuSimple},
  props: {
    formProducts: {
      type: Array,
      required: true
    },
    headerIcons:{
      type: Array,
      required: true
    },
    helpMenu: {
      type: Boolean,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      areaField: null,
      backRoute: {
        text: 'Help Home',
        route: 'home'
      },
      fileInput: null,
      message: null,
      documentExtension: null,
      documentData: [],
      formType: null,
      loading: false,
      subTitle: null,
      productField: null,
      productLabel: null
    }
  },
  computed: {
    filteredArea() {
      return this.formProducts.filter(p => p.id === this.productField)[0]
    }
  },
  watch: {
    '$route': {
      handler: 'routeProductFilter'
    },
    formType: {
      handler: 'switchFormType'
    }
  },
  created() {
    this.routeProductFilter()
    this.switchFormType(this.type)
  },
  methods: {
    sendForm() {
      this.loading = true

      const data = {
        projectId: this.projectId,
        product: (this.formProducts.find(p => p.id === this.productField) || {}).name || 'None',
        product_area: this.areaField,
        type: this.type === 'help' ? 'support' : this.formType,
        message: this.message,
        attachmentList: this.fileInput,
      };

      Api.upload(
        `onehelp/contact`,
        data,
        () => {
          if(this.type === 'help') {
            this.$emit('routeChange', {
              name: 'submissionSuccess',
              successItem: {
                title: 'Message sent',
                text: 'We have received your help request. Someone from our product team will be in touch with you soon.'
              }
            })
          } else if(this.formType === 'feedback') {
            this.$emit('routeChange', {
              name: 'submissionSuccess',
              successItem: {
                title: 'Feedback sent',
                text: 'We have received your feedback. Our team will look into it.'
              }
            })
          } else if(this.formType === 'new-feature') {
            this.$emit('routeChange', {
              name: 'submissionSuccess',
              successItem: {
                title: 'New feature requested',
                text: 'We have received your feature request. Someone from our product team will be looking into it soon.'
              }
            })
          }
        }
      );
      this.loading = false
    },
    switchFormType(typeProp) {
      let type
      if(this.formType) {
        type = this.formType
      } else if(typeProp) {
        type = typeProp
      }
      switch(type) {
        case 'feedback':
          this.formType = 'feedback'
          this.subTitle = 'Give feedback'
          this.productLabel = 'Which product are you giving feedback for?'
          this.areaField = null
          this.message = null
          break;
        case 'new-feature':
          this.formType = 'new-feature'
          this.subTitle = 'Suggest a new feature'
          this.productLabel = 'Which product are you suggesting new feature for?'
          this.areaField = null
          this.message = null
          break;
        case 'help':
          this.subTitle = 'Ask for help'
          this.productLabel = 'Which product are you seeking help for?'
          break;
      }
    },
    routeProductFilter() {
      let activePath
      this.formProducts.map(product => {
        if(product.paths.filter(path => this.$route.fullPath.includes(`${path}`)).length) {
          activePath = product
        }
      })
      if(activePath) {
        this.productField = activePath.id
      }
    },
  }
}
</script>

<style scoped lang="scss">

.sContent{
  max-height: 840px;
  padding: 24px 30px 22px;

  .sSwitch{
    height: 40px;
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .sSelected{
      background-color: #F5F5F5;
    }

    .sFeedback{
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 180px;
      border: 1px solid #E9E9E9;
    }
    .sFeature{
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 180px;
      border-bottom: 1px solid #E9E9E9;
      border-right: 1px solid #E9E9E9;
      border-top: 1px solid #E9E9E9;
    }

    span{
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
    }
  }

  .sContentTitle{
    padding-bottom: 8px;
    padding-top: 24px;
    color: #343434;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
  }

  .sAttachmentCard {
    display: flex;
    align-items: center;
    margin-top: 12px;
    height: 120px;
    background-color: #F5F5F7;
  }

  .sActions{
    display: flex;
    padding-top: 24px;
    justify-content: space-between;
  }
}
</style>